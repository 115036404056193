
import './modernizr.js'; // 'npm run modernizr' to create this file
 
import '../node_modules/jquery-slimscroll/jquery.slimscroll.js';

import '../node_modules/codemirror/lib/codemirror.js';
import '../node_modules/codemirror/mode/javascript/javascript.js';
import '../node_modules/codemirror/mode/xml/xml.js';
import '../node_modules/codemirror/mode/htmlmixed/htmlmixed.js';
import '../node_modules/codemirror/mode/css/css.js';
