import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

@Injectable()
export class ImIncidentcauseService {

  constructor(private http: HttpClient) { }

  getIMIncidentCause() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-cause/');
  }
}
