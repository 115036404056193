import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FacilityService } from '@services/admin/facility.service';
import { MessageType } from '@app.enum';
import { Notify } from '@utility/utility.notify';
import { TranslateService } from '@ngx-translate/core';
const _clone = (d) => JSON.parse(JSON.stringify(d));

 @Component({
    moduleId: module.id,
    selector: 'facility-select-multiple',
    templateUrl: 'facility-select-multiple.component.html',
    styleUrls: ['facility-select-multiple.component.scss']
})
export class FacilitySelectMultipleComponent {
    // Variable declaration

    // Form Group
    @Input("customFormGroup")
    formGroup: FormGroup;

    // FOrm Control Name
    @Input("customFormControlName")
    formControlName: string = "FacilityMasterList";

    // Required validation
    @Input()
    required: boolean;

    // bootsrap grid css class
    @Input()
    cssClass: string = 'col-md-12';

    @Input()
    level: number = -1;

    @Input()
    locationId: number = 0;

    // List
    structures = [];

    // activity state
    loading: boolean = false;
    isFormLoaded: boolean = false;

    constructor(public fb: FormBuilder,
        public _facility: FacilityService,
        private translate: TranslateService) {
        this.isFormLoaded = false;
        this.getStructureAndData();
    }

    // Get structures
    getStructureAndData() {
        this.loading = true;
        this._facility.getStructureAndData("normal", this.locationId).subscribe(
            data => {
                this.loading = false;
                if (data.MessageType == MessageType.Success) {
                    this.structures = _clone(data.Result.Structure);
                    if (!!this.structures && this.structures.length > 0) {
                        this.SetSelectedList(this.formGroup.controls[this.formControlName].value, this.structures.length - 1);
                        this.SetFormControls();
                    }
                }
            },
            err => {
                this.loading = false;
                Notify.ApiError(err, this.translate);
            },
            () => {
                this.loading = false;
            });
    }

    // Set Form Controls
    SetFormControls() {
        var controls = {};
        for (let index = 0; index < this.structures.length; index++) {

            // Set Default selected data in child levels
            if (!this.structures[index]["SelectedMasters"]) {
                this.structures[index]["SelectedMasters"] = this.structures[index]["Masters"];
            }

            // Add controls by level in form group
            var selectedList = this.structures[index]["SelectedList"];
            controls['Level' + index] = this.required ? [selectedList, Validators.required] : [selectedList];
        }

        this.SetFormControlValueFromLastLevel();

        // Create Form gorup for all levels
        const facilityMasterControl = this.fb.group(controls);
        if (!!this.formGroup.controls.FacilityMasterControl) {
            this.formGroup.controls["FacilityMasterControl"] = facilityMasterControl;
        } else {
            this.formGroup.addControl("FacilityMasterControl", facilityMasterControl);
        }
        this.isFormLoaded = true;
    }

    // Set Form Control Value
    SetFormControlValue(selectedList) {
        var _val = {};
        _val[this.formControlName] = selectedList;
        this.formGroup.patchValue(_val);
    }

    // On Select Master data
    OnSelectMaster(items, i, type, isDeselect) {
        // create array if items is single object
        if (type == 'single') {
            items = [items];
        }

        // Set Selected List in current structure
        for (let index = 0; index < items.length; index++) {
            // Check index in already selected list
            var currentIndex = _.findIndex(this.structures[i]["SelectedList"], function (data) {
                return data["FacilityMasterId"] == items[index]["FacilityMasterId"];
            });

            // Add or remove item in current selected list
            if (currentIndex >= 0 && isDeselect) {
                // Remove from list
                this.structures[i]["SelectedList"].splice(currentIndex, 1);
            } else if (currentIndex < 0 && !isDeselect) {
                // Add in list
                if (!this.structures[i]["SelectedList"]) {
                    this.structures[i]["SelectedList"] = [];
                }
                this.structures[i]["SelectedList"].push(items[index]);
            }
        }

        // Set child level data
        this.SetChildLevelData(i);

        // Set Form Value by level
        for (let index = i + 1; index < this.structures.length; index++) {
            var selectedList = this.structures[index]["SelectedList"];
            if (!!selectedList && selectedList.length > 0) {
                selectedList = [];
            }
            var val = {};
            val["Level" + index] = this.structures[index]["SelectedList"];
            (this.formGroup.controls["FacilityMasterControl"] as FormGroup).patchValue(val);
        }

        this.SetFormControlValueFromLastLevel();
    }

    SetFormControlValueFromLastLevel() {
        // Set Form Value
        var functionLevelStructure = this.structures[this.structures.length - 1];
        if (!!functionLevelStructure) {
            var selectedList = [];
            if (!!functionLevelStructure["SelectedList"] && functionLevelStructure["SelectedList"].length > 0) {
                // Set Selected data from last level
                selectedList = functionLevelStructure["SelectedList"];
            } else {
                // Last level is unselected; Set default data as selected
                selectedList = functionLevelStructure["SelectedMasters"];
            }

            // Set from value
            this.SetFormControlValue(selectedList);
        }
    }

    // Set Selected List
    SetSelectedList(selectedList, index) {
        if (!!selectedList && selectedList.length > 0) {

            // Set Selected list in current level
            this.structures[index]["SelectedList"] = this.structures[index]["Masters"].filter((x) => {
                var objIndex = _.findIndex(selectedList, function (data) {
                    return data["FacilityMasterId"] == x["FacilityMasterId"];
                });
                return objIndex >= 0;
            });

            // Set selected items for parent level based on current level
            if (index > 0) {
                var selectedListForParent = this.structures[index - 1]["Masters"].filter((x) => {
                    var objIndex = _.findIndex(this.structures[index]["SelectedList"], function (data) {
                        return data["ParentId"] == x["FacilityMasterId"];
                    });
                    return objIndex >= 0;
                });
                this.SetSelectedList(selectedListForParent, index - 1);
            }
        }

        // Set child level data
        this.SetChildLevelData(0);
    }

    // Set child level data
    SetChildLevelData(startIndex) {
        // Set dropdown items in each level based on selected items
        for (let index = startIndex; index < this.structures.length; index++) {
            var list = this.structures[index]["SelectedList"];
            if (!!list && list.length > 0) {
                var selectedList = this.structures[index]["SelectedList"];
                if (!!selectedList && selectedList.length > 0) {
                    var selectedMasters = this.structures[index]["SelectedMasters"];
                    list = selectedList.filter((x) => {
                        var objIndex = _.findIndex(selectedMasters, function (data) {
                            return data["FacilityMasterId"] == x["FacilityMasterId"];
                        });
                        return objIndex >= 0;
                    });
                    this.structures[index]["SelectedList"] = list;
                }
            }

            if (index < this.structures.length - 1) {

                if (!list || list.length == 0) {
                    list = this.structures[index]["SelectedMasters"];
                }

                if (!list || list.length == 0) {
                    list = this.structures[index]["Masters"];
                }

                this.structures[index + 1]["SelectedMasters"] = this.structures[index + 1]["Masters"].filter((x) => {
                    var objIndex = _.findIndex(list, function (data) {
                        return data["FacilityMasterId"] == x["ParentId"];
                    });
                    return objIndex >= 0;
                });

                var aaa = this.structures[index + 1]["SelectedMasters"];
            }
        }
    }
}
