export class Pagination {
    CurrentPage: number;
    PageSize: number;
    Total: number;
    OrderBy: string;
    Descending: boolean;
    Active?: boolean;
    IsUser?: boolean;

    public GetUri(params: string[] = []) {
        let uri: string = '';

        if (typeof (this.CurrentPage) !== "undefined") {
            params.push('CurrentPage=' + this.CurrentPage);
        }

        if (typeof (this.PageSize) !== "undefined") {
            params.push('PageSize=' + this.PageSize);
        }

        if (typeof (this.OrderBy) !== "undefined") {
            params.push('OrderBy=' + this.OrderBy);
        }

        if (typeof (this.Descending) !== "undefined") {
            params.push('Descending=' + this.Descending);
        }

        if (typeof (this.Active) !== "undefined") {
            params.push('Active=' + this.Active);
        }

        if (typeof (this.IsUser) !== "undefined") {
            params.push('IsUser=' + this.Active);
        }

        if (params.length > 0) {
            uri = '?' + params.join('&');
        }

        return uri;
    }
}