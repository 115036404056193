export class Encryption {

    private static key = CryptoJS.enc.Utf8.parse('4001138835360774');
    private static iv = CryptoJS.enc.Utf8.parse('4660978679285925');// Generated at https://www.random.org/strings

    constructor() { }

    // Encrypt string
    public static Encrypt(data) {
        var encrypted_string = '';

        try {
            var encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), this.key, {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            encrypted_string = encryptedData.toString();

        } catch (error) {
        }

        return encrypted_string;
    }

    // decrypt string
    public static Decrypt(data) {
        var decrypted_string = '';

        try {
            var bytes = CryptoJS.AES.decrypt(data, this.key, {
                keySize: 128 / 8,
                iv: this.iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            decrypted_string = bytes.toString(CryptoJS.enc.Utf8);

        } catch (error) {
        }

        return decrypted_string;
    }

    public static SetQueryParam(data: any) {
        return encodeURIComponent(Encryption.Encrypt(JSON.stringify(data))); 
    }

    public static GetQueryParam(query: string) {
        let data = null;
        var jString = this.Decrypt(decodeURIComponent(query.replace(/\s/g, '+')));
        try {
            data = JSON.parse(jString);
        } catch (e) {
            data = jString;
        }
        return data;
    }
}