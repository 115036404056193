import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { SettingsService } from '@core/settings/settings.service';
import { SharedService } from '@services/shared.service';
import { Application } from '@utility/utility.application';
import { filter, pairwise } from 'rxjs/operators';
declare var $: any;

 @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
     currentUrl: any;
     previousUrl: any;

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.layout.isFixed; };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.layout.isCollapsed; };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.layout.isBoxed; };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.layout.useFullLayout; };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.layout.hiddenFooter; };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.layout.horizontal; };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.layout.isFloat; };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.layout.offsidebarOpen; };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.layout.asideToggled; };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.layout.isCollapsedText; };

    constructor(public settings: SettingsService, public _router: Router,private _shared:SharedService) {
        this.setLogoutInAllTab();
        if (_$config.enableScreenLock) {
            this.setIdleScreen();
        }
    }

    // Event Listener to check idle state
    @HostListener('mousemove', ['$event'])
    @HostListener('wheel', ['$event'])
    @HostListener('scroll', ['$event'])
    @HostListener('window:click', ['$event'])
    @HostListener('window:contextmenu', ['$event'])
    @HostListener('window:keydown', ['$event'])
    @HostListener('window:keypress', ['$event'])
    @HostListener('window:keyup', ['$event'])
    @HostListener('window:keyup', ['$event'])
    onEvent(event) {
        this.setTabs();
    }

    setTabs(){
        var session = Application.GetSession();
        if(!!session){
            var idle = sessionStorage.getItem('idle-' + _$config.tabid);
            if (idle != "-1") {
                if(Application.Get("app-locked")== "true" && _$config.enableScreenLock){
                    sessionStorage.setItem('idle-' + _$config.tabid, "-1");
                    window.location.hash = "#/lock";
                } else {
                    sessionStorage.setItem('idle-' + _$config.tabid, "0");
                }
            } else if (idle == "-1") {
                window.location.hash = "#/lock";
            }
        } else {
            sessionStorage.clear();
        }
    }

    // Initialization
    ngOnInit() {
        $(document).on('click', '[href="#"]', e => e.preventDefault());
        // for previous url
        this._router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this._shared.setPreviousUrl(this.previousUrl);
          });
     }

 


    // Set idle screen
    private setIdleScreen() {
        this.setTabs();
        setInterval(this.increment, 1000);
    }

    // Increment idle time
    private increment() {
        var session = Application.GetSession();
        var idle = sessionStorage.getItem('idle-' + _$config.tabid);
        if (idle != "-1" && !!session) {
            idle = !!idle ? idle : '0';
            var countdown = parseInt(idle);
            countdown++;
            sessionStorage.setItem('idle-' + _$config.tabid, countdown + "");
            if (countdown > 300) { // 300 seconds = 60*5 = 5 minutes
                if (_$config.enableScreenLock) {
                    Application.Set("app-locked", "true");
                    sessionStorage.setItem('unlock-to-' + _$config.tabid, window.location.hash.replace('#/', ''));
                    window.location.hash = "#/lock";
                }
            }
        }
    }

    // Set logout in all tab
    private setLogoutInAllTab() {
        window.addEventListener('storage', (event) => {
            if (event.storageArea == localStorage && event.key == 'access_token') {
                let access_token = Application.Get('access_token');
                if (access_token == undefined) {
                    sessionStorage.setItem(_$config.tabid, window.location.hash.replace('#/', ''));
                    this._router.navigate(['login']);
                } else {
                    var hash = sessionStorage.getItem(_$config.tabid);
                    if (!!hash && hash.indexOf('lock') >= 0) {
                        hash = sessionStorage.getItem('unlock-to-' + _$config.tabid);
                    }
                    if (!!hash) {
                        var navigation = hash.split('/');
                        Application.Remove("redirect");
                        // this._router.navigate(navigation);
                        this._router.navigate(['login']);
                    }
                }

            }
        }, false);
    }
}
