import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Country Service
@Injectable()
export class CountryService {

  constructor(private http: HttpClient) { }

  // Get Country list  
  getCountry(page: Pagination, showState: boolean = false) {

    let params: string[] = [
      'showState=' + showState
    ];

    let uri: string = page.GetUri(params);
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'country' + uri);
  }

  // Get Country by id  
  getCountryById(countryId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'country/' + countryId);
  }

  // Get state 
  getState(countryId: number, page: Pagination) {
    let uri: string = page.GetUri();
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'country/' + countryId + '/state' + uri);
  }

  // Get state by id
  getStateById(countryId: number, stateId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'country/' + countryId + '/state/' + stateId);
  }
}