import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
    transform(text: string, search: string, highlightClass: string = 'text-info'): string {
        if (!!text) {
            var pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
            pattern = pattern.split(' ').filter((t) => {
                return t.length > 0;
            }).join('|');
            var regex = new RegExp(pattern, 'gi');

            return search ? text.replace(regex, (match) => `<span class=" ` + highlightClass + `">${match}</span>`) : text;
        } else {
            return text;
        }
    }
}