import { Encryption } from './utility.encryption';
import { AppSession } from '@interface/interface.app-session';

const _clone = (d) => JSON.parse(JSON.stringify(d));
export class Application {

  constructor() { }

  // Set value in local storage
  public static Set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  // get value from local storage
  public static Get(key: string) {
    return localStorage.getItem(key);
  }

  // Remove local storage
  public static Remove(key: string) {
    localStorage.removeItem(key);
  }

  // Clear local storage
  public static RemoveAll() {
    return localStorage.clear();
  }

  // Set session
  public static SetSession(token: string, session: any) {
    this.Set("access_token", token);
    this.Set("session", session);

    let sessionJson = Encryption.Decrypt(session);
    let clientSession = JSON.parse(sessionJson);
    this.Set("ClientRoleId", clientSession.currentUser.ClientRoleId);
    this.Set("LocationId", clientSession.currentUser.LocationId);
    this.Set("TimezoneId", clientSession.currentUser.TimezoneId);
  }

  public static GetUserId(session:String):number {
    let sessionJson = Encryption.Decrypt(session);
    let data =  JSON.parse(sessionJson);
    return data.currentUser.UserId;
  }

  public static GetClientId(session:String):number {
    let sessionJson = Encryption.Decrypt(session);
    let data =  JSON.parse(sessionJson);
    return data.currentUser.ClientId;
  }

  // Get session
  public static GetSession<AppSession>() {
    let appSession: AppSession;
    let access_token = this.Get("access_token");
    let session = this.Get("session");

    if (!!session && !!access_token) {
      let sessionJson = Encryption.Decrypt(session);
      let clientSession = JSON.parse(sessionJson);

      let _session: any = clientSession.currentUser;
      _session.Roles = clientSession.clientRoles;
      _session.FunctionLevel = clientSession.functionLevel;
      _session.Locations = clientSession.clientLocations;
      _session.CompanyLogo = clientSession.companyLogo;
      _session.EmployeeLogo = clientSession.employeeLogo;

      let _clientRoleIdString = this.Get("ClientRoleId");
      if (!!_clientRoleIdString) {
        _session.ClientRoleId = parseInt(_clientRoleIdString);
      }

      let _locationIdString = this.Get("LocationId");
      if (!!_locationIdString) {
        _session.LocationId = parseInt(_locationIdString);
      }

      let _timezoneIdIdString = this.Get("TimezoneId");
      if (!!_timezoneIdIdString) {
        _session.TimezoneId = parseInt(_timezoneIdIdString);
      }

      appSession = _clone(_session);
    }

    return appSession;
  }

  // Get Structure Level Name
  public static GetStructureLevelName(): string {
    let structureLevelName = "";
    const session: AppSession = this.GetSession();
    if (!!session && !!session.FunctionLevel) {
      structureLevelName = session.FunctionLevel.StructureName;
    }
    return structureLevelName;
  }

  // Get Current Location
  public static GetCurrentLocationName(): string {
    let locationName = "";
    const session: AppSession = this.GetSession();

    if (!!session) {
      const locations: Array<any> = session.Locations;
      if (!!locations && locations.length > 0) {
        const selectedLocation = locations.filter(x => x.EnterpriseMasterId == session.LocationId)[0];
        if (!!selectedLocation)
          return selectedLocation.EnterpriseName;
      }
    }

    return locationName;
  }

  // Clear session
  public static ClearSession() {

    let sessionStorages = [
      "session",
      "access_token",
      "ClientId",
      "ClientRoleId",
      "LocationId",
      "TimezoneId",
      "mySetu-ui",
      "Accept-Language",
      "app-locked",
      "currentUser",
      "dashboardCustom"
    ];

    for (let index = 0; index < sessionStorages.length; index++) {
      this.Remove(sessionStorages[index]);
    }
  }
}
