import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IndexedDbService {
  private dbName = "myDatabase";
  private dbVersion = 1;
  private db: IDBDatabase;

  constructor() {
    this.initDatabase();
  }

  private initDatabase(): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);
    request.onerror = (event: any) => {
      console.log("Failed to open database:", event.target.errorCode);
    };
    request.onsuccess = (event: any) => {
      this.db = event.target.result;
      console.log("Database opened successfully");
    };
    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      db.createObjectStore("myObjectStore");
    };
  }

  public removeItem(key: string): Promise<void> {
    return new Promise<void>(
      (resolve, reject) => {
        const transaction = this.db.transaction("myObjectStore", "readwrite");
        const objectStore = transaction.objectStore("myObjectStore");
        const request = objectStore.delete(key);
        request.onerror = (event: any) => {
          console.log("Failed to remove data from database:", event.target.errorCode);
          reject(event.target.errorCode);
        };
        request.onsuccess = () => {
          console.log("Data removed from database successfully");
          resolve();
        };
      });
  }

  public clearStore(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction("myObjectStore", "readwrite");
      const objectStore = transaction.objectStore("myObjectStore");
      const request = objectStore.clear(); request.onerror = (event: any) => {
        console.log("Failed to clear data from database:", event.target.errorCode);
        reject(event.target.errorCode);
      };
      request.onsuccess = () => {
        console.log("Data cleared from database successfully"); resolve();
      };
    });
  }

  public setItem<T>(key: string, value: T): void {
    const transaction = this.db.transaction("myObjectStore", "readwrite");
    const objectStore = transaction.objectStore("myObjectStore");
    const request = objectStore.add(value, key);
    request.onerror = (event: any) => {
      console.log("Failed to add data to database:", event.target.errorCode);
    };

    request.onsuccess = (event) => {
      console.log("Data added to database successfully");
    };
  }

  public getItem<T>(key: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const transaction = this.db.transaction("myObjectStore", "readonly");
      const objectStore = transaction.objectStore("myObjectStore");
      const request = objectStore.get(key);

      request.onerror = (event: any) => {
        console.log(
          "Failed to retrieve data from database:",
          event.target.errorCode
        );
        reject(event.target.errorCode);
      };

      request.onsuccess = (event: any) => {
        const data = event.target.result as T;
        resolve(data);
      };
    });
  }
}
