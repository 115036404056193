import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { Application } from '@utility/utility.application';
import { SharedService } from '@services/shared.service';
import { AppSession } from '@interface/interface.app-session';
import { EmployeeService } from '@services/admin/employee.service';
import { MessageType } from '@app.enum';
 
 @Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
 
    TimezoneId = "";
     currentUser: any;
     session: AppSession;
     url: string;
   
    constructor(
        public settings: SettingsService,
        private _sharedService: SharedService,
        public employeeService: EmployeeService,
        ) {
            this._sharedService.changeTimezone.subscribe((data) => {
                this.setTimeZone();
            });
        }
 
    ngOnInit() {
        this.getSession()
        // this.currentUser = JSON.parse(atob(localStorage.getItem("currentUser")));
        this.employeeService.getProfile().subscribe(
            data => {
              if (data.MessageType == MessageType.Success) {
                this.currentUser = data.Result;
                let query = this.getIncidentParams(this.currentUser).replace('&', '?')
                this.url = 'https://www.mysetu.com/support' +  query;
                console.log(this.url)
              }
            },
            err => {
              //Notify.ApiError(err, this.translate);
            },
            () => {
            });
        console.log(this.currentUser)
        this.setTimeZone();
     
 
       
    }
 
    getSession() {
        this.session = Application.GetSession();
        console.log(this.session);
       
    }
 
    getIncidentParams(filter: any) {
        let query = '';
        const param = [];
        if (!!filter.FirstName) {
          param.push('firstname=' + filter.FirstName);
        }
   
        if (!!filter.LastName) {
          param.push('lastname=' + filter.LastName);
        }
        if (!!filter.EmailId) {
            param.push('email=' + filter.EmailId);
        }
        if (!!filter.EmailId) {
            param.push('email=' + filter.EmailId);
        }
        if (!!filter.CompanyName) {
            param.push('compName=' + filter.CompanyName);
        }
        if (!!filter.ContactNo) {
            param.push('contact=' + filter.ContactNo);
        }
        if (!!this.session.Locations[0].EnterpriseName) {
            param.push('location=' + this.session.Locations[0].EnterpriseName);
        }
        param.push('module=Administration')
        if (!!filter.Modules) {
            param.push('moduleused='+filter.Modules);
        }
 
       
        //edit end
        if (param.length > 0) {
          query = '&' + param.join('&');
        }
   
        return query;
      }
 
    setTimeZone() {
        this.TimezoneId = Application.Get("TimezoneId");
        if(!this.TimezoneId) {
            this.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
        }
    }
}
 
 