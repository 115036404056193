
import { AuthService } from "./admin/auth.service";
import { Observable } from "rxjs/Observable";
import { Observer } from "rxjs/Observer";
import { MessageType } from "@app.enum";
import { Application } from "@utility/utility.application";
import { Inject } from "@angular/core";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from "rxjs";
import { ISubscription } from "rxjs-compat/Subscription";
import { Pagination } from "@interface/interface.pagination";
import { EmployeeService } from "./admin/employee.service";

export class SharedService {

    rbac: any = {
        Checking: false,
        CanAccess: true,
        CanAdd: true,
        CanModify: true,
        CanView: true,
        CanDelete: true
    };

    // rbac: any = {
    //     Checking: false,
    //     CanAccess: false,
    //     CanAdd: false,
    //     CanModify: false,
    //     CanView: false,
    //     CanDelete: false
    // };
    // Menu
    menuChangeObserver: any;
    menuName: string;
    changeMenu: Observable<any>;

    timezoneChangeObserver: any;
    changeTimezone: Observable<any>;

    // Refresh Token
    tokenChangeObserver: any;
    reloadRequired: boolean;
    refreshToken: Observable<any>;

    // Route Change
    routeChangeObserver: any;
    moduleName: string;
    routeChanged: Observable<any>;

    // Language
    languageChangeObserver: any;
    changeLanguage: Observable<any>;

    // dashboard start
    subsSource = new BehaviorSubject<ISubscription>(undefined);
    // EmployeeList = new BehaviorSubject<ISubscription>(undefined);
    // RespEmployeeList = new BehaviorSubject<ISubscription>(undefined);
    // ContEmployeeList = new BehaviorSubject<ISubscription>(undefined);

    getSubsSource(): BehaviorSubject<ISubscription> {
        return this.subsSource;
    }

    setSubsSource(param: any) {
        this.subsSource.next(param);
    }
    // dashboard end
    // if location changed refresh sidebar start
    LocationChanged = new BehaviorSubject<ISubscription>(undefined);
    getLocationChanged(): BehaviorSubject<ISubscription> {
        return this.subsSource;
    }

    setLocationChanged(param: any) {
        this.LocationChanged.next(param);
    }
    // if location changed refresh sidebar end



    // Constructor
    constructor(@Inject(AuthService) private _auth: AuthService, public _router: Router, private translate: TranslateService, private employeeService: EmployeeService) {//
        // Menu
        this.changeMenu = new Observable((observer: Observer<any>) => {
            this.menuChangeObserver = observer;
        });

        this.changeTimezone = new Observable((observer: Observer<any>) => {
            this.timezoneChangeObserver = observer;
        });

        // Refresh Token
        this.refreshToken = new Observable((observer: Observer<any>) => {
            this.tokenChangeObserver = observer;
        });

        // Route Change
        this.routeChanged = new Observable((observer: Observer<any>) => {
            this.routeChangeObserver = observer;
        });

        // Language
        this.changeLanguage = new Observable((observer: Observer<any>) => {
            this.languageChangeObserver = observer;
        });
    }

    //for previous url
    private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public previousUrl$: Observable<string> = this.previousUrl.asObservable();
  
    setPreviousUrl(previousUrl: string) {
      this.previousUrl.next(previousUrl);
    }
    //for previous url end

    // Reset Menu
    resetMenu(menuName: string) {
        this.menuName = menuName;
        this.menuChangeObserver.next(this.menuName);
    }

    // Reset Timezone
    resetTimezone() {
        this.timezoneChangeObserver.next();
    }

    // Reset Language
    resetLanguage() {
        let strings = this.getTimeAgoStrings();
        if (!!this.languageChangeObserver) {
            this.languageChangeObserver.next(strings);
        }

        if (document.location.hash.indexOf("#/home") == 0) {
            this._router.navigate(['/', 'home', 'redirect', 'home']);
        }
    }

    getTimeAgoStrings() {
        return {
            prefixAgo: null,
            prefixFromNow: null,
            suffixAgo: this.translate.instant('ago'),
            suffixFromNow: this.translate.instant('from now'),
            seconds: this.translate.instant('less than a minute'),
            minute: this.translate.instant('about a minute'),
            minutes: this.translate.instant('%d minutes'),
            hour: this.translate.instant('about an hour'),
            hours: this.translate.instant('about %d hours'),
            day: this.translate.instant('a day'),
            days: this.translate.instant('%d days'),
            month: this.translate.instant('about a month'),
            months: this.translate.instant('%d months'),
            year: this.translate.instant('about a year'),
            years: this.translate.instant('%d years'),
            wordSeparator: ' ',
        }
    }

    // Refresh Access Token
    refreshAccessToken(reloadRequired: boolean, redirect: Array<string> = null) {
        this.reloadRequired = reloadRequired;
        this._auth.refreshToken().subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    var result = data.Result;
                    Application.SetSession(result.access_token, result.session);
                    if (!!this.tokenChangeObserver) {
                        this.tokenChangeObserver.next(this.reloadRequired);
                    }
                    sessionStorage.clear();
                    if (!!redirect && redirect.length > 0) {
                        this._router.navigate(redirect);
                    }
                }
            },
            err => {
            },
            () => {
            }
        );
    }

    // Reset Menu
    changeRoute(moduleName: string) {
        this.moduleName = moduleName;
        if (!this.routeChangeObserver) {
            this.routeChanged = new Observable((observer: Observer<any>) => {
                this.routeChangeObserver = observer;
                this.routeChangeObserver.next(this.moduleName);
            });
        } else {
            this.routeChangeObserver.next(this.moduleName);
        }

    }

    // //Employee for current location
    // GetEmployeeList() {
    //     console.log(_$config.api_endpoint)
    //     let page = new Pagination();
    //     // normal Employee
    //     this.employeeService.getEmployeeDataForCurrentLocation().subscribe(
    //         (data) => {
    //             if (data.MessageType == MessageType.Success) {
    //                 let list = data.Result.filter((e) => e.isActive !== false);
    //                 this.EmployeeList.next(list);
    //             }
    //         },
    //         (err) => {
    //         },
    //         () => { }
    //     );

    //     // responsible employee
    //     this.employeeService.getRespEmployeeDataForCurrentLocation().subscribe(
    //         (data) => {
    //             if (data.MessageType == MessageType.Success) {
    //                 let list = data.Result.filter((e) => e.isActive !== false);
    //                 this.RespEmployeeList.next(list);
    //             }
    //         },
    //         (err) => {
    //         },
    //         () => { }
    //     );


    //     // contractor employee
    //     this.employeeService.getContEmployeeDataForCurrentLocation().subscribe(
    //         (data) => {
    //             if (data.MessageType == MessageType.Success) {
    //                 let list = data.Result.filter((e) => e.isActive !== false);
    //                 this.ContEmployeeList.next(list);
    //             }
    //         },
    //         (err) => {
    //         },
    //         () => { }
    //     );

    // }
    // //Employee list end
}
