import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StructureService } from '@services/admin/structure.service';
import { MessageType } from '@app.enum';
import { Notify } from '@utility/utility.notify';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';
const _clone = (d) => JSON.parse(JSON.stringify(d));
 @Component({
    moduleId: module.id,
    selector: 'enterprise-select',
    templateUrl: 'enterprise-select.component.html',
    styleUrls: ['enterprise-select.component.scss']
})
export class EnterpriseSelectComponent {
    // Variable declaration
    @Input("customFormGroup")
    formGroup: FormGroup;

    @Input("customFormControlName")
    formControlName: string = "EnterpriseMasterId";

    @Input()
    required: boolean;

    @Input()
    cssClass: string = 'col-md-12';

    @Input()
    appendTo: string = null;

    @Input()
    level: number = -1;

    @Output()
    change = new EventEmitter();

    // List
    structures:any = [];

    // activity state
    loading: boolean = false;
    isFormLoaded: boolean = false;

    constructor(public fb: FormBuilder,
        public _enterprise: StructureService,
        private translate: TranslateService,
        private dbservice: IndexedDbService) {
        this.isFormLoaded = false;
        this.getStructureAndData();
    }

    // Get structures
    getStructureAndData() {
        this.loading = true;
        let obj =  this.dbservice.getItem('enterprisestructure').then(
            dbdata=>{
                console.log(dbdata);
                if(!dbdata){
        this._enterprise.getStructureAndData().subscribe(
            data => {
                this.loading = false;
                if (data.MessageType == MessageType.Success) {
                    this.dbservice.setItem('enterprisestructure',data.Result.Structure);
                    this.structures = _clone(data.Result.Structure);
                    this.SetSelectedIds(this.formGroup.controls[this.formControlName].value);
                    this.SetFormControls();
                }
            },
            err => {
                this.loading = false;
                Notify.ApiError(err, this.translate);
            },
            () => {
                this.loading = false;
            });
        }
        else{
            this.structures = dbdata;
            if (!!this.structures && this.structures.length > 0) {
                this.SetSelectedIds(this.formGroup.controls[this.formControlName].value);
                this.SetFormControls();
            }
            this.loading = false;
        }
    }
    ).catch(err=>{
        this.loading = false;
        console.log(err);
    })
console.log(obj);
    }

    // Set Form Controls
    SetFormControls() {
        this.isFormLoaded = false;
        var controls = {};

        for (let index = 0; index < this.structures.length; index++) {
            if (index == 0 && this.structures[index]["Masters"].length > 0) {
                this.structures[index]["SelectedId"] = this.structures[index]["Masters"][0].EnterpriseMasterId;
            }
            if (index < 2) {
                this.structures[index]["SelectedMasters"] = this.structures[index]["Masters"];
            } else if (!this.structures[index]["SelectedMasters"] || this.structures[index]["SelectedMasters"].length == 0) {
                this.structures[index]["SelectedMasters"] = [];
            }
            var selectedId = this.structures[index]["SelectedId"];
            controls['Level' + index] = this.required && (this.level < 0 || this.level > index) ? [selectedId, Validators.required] : [selectedId];

            if (selectedId > 0) {
                var _val = {};
                _val[this.formControlName] = selectedId;
                this.formGroup.patchValue(_val);
            }
        }

        const enterpriseMasterControl = this.fb.group(controls);
        if (!!this.formGroup.controls.EnterpriseMasterControl) {
            this.formGroup.controls["EnterpriseMasterControl"] = enterpriseMasterControl;
        } else {
            this.formGroup.addControl("EnterpriseMasterControl", enterpriseMasterControl);
        }
        this.isFormLoaded = true;
    }

    // On Select Master data
    OnSelectMaster(selected, i) {
        var selectedId = 0;

        // Set parent structure id
        for (let index = 0; index < i; index++) {
            if (index < i) {
                if (this.structures[index]["SelectedId"] > 0) {
                    selectedId = _clone(this.structures[index]["SelectedId"]);
                }

            }
        }

        // set current structure value
        if (!!selected && selected.EnterpriseMasterId > 0) {
            selectedId = selected.EnterpriseMasterId;
            this.structures[i]["SelectedId"] = selectedId;
            if (i < this.structures.length - 1) {
                this.structures[i + 1]["SelectedMasters"] = this.structures[i + 1]["Masters"].filter(function (x) { return x.ParentId == selectedId; });
                let _childval = {};
                _childval['Level'+(i+1)] = null;
                this.formGroup.controls.EnterpriseMasterControl.patchValue(_childval);
            }
        } else if (i < this.structures.length - 1) {
            this.structures[i]["SelectedId"] = 0;
            this.structures[i + 1]["SelectedMasters"] = [];
            let _childval = {};
            _childval['Level'+(i+1)] = null;
            this.formGroup.controls.EnterpriseMasterControl.patchValue(_childval);
        }

        // remove child structure values
        for (let index = i + 2; index < this.structures.length; index++) {
            this.structures[index]["SelectedMasters"] = [];
            let _childval = {};
            _childval['Level'+index] = null;
            this.formGroup.controls.EnterpriseMasterControl.patchValue(_childval);
        }

        // Set from value
        var _val = {};
        _val[this.formControlName] = selectedId;
        this.formGroup.patchValue(_val);

        this.change.emit(selectedId);
    }

    // On Select Master data
    OnClearSelection(i) {
        var selectedId = 0;

        // Set parent structure id
        for (let index = 0; index < i; index++) {
            if (index < i) {
                if (this.structures[index]["SelectedId"] > 0) {
                    selectedId = _clone(this.structures[index]["SelectedId"]);
                }

            }
        }

        if (i < this.structures.length - 1) {
            this.structures[i]["SelectedId"] = 0;
            this.structures[i + 1]["SelectedMasters"] = [];
            let _childval = {};
            _childval['Level'+(i+1)] = null;
            this.formGroup.controls.EnterpriseMasterControl.patchValue(_childval);
        }

        // remove child structure values
        for (let index = i + 2; index < this.structures.length; index++) {
            this.structures[index]["SelectedMasters"] = [];
            let _childval = {};
            _childval['Level'+index] = null;
            this.formGroup.controls.EnterpriseMasterControl.patchValue(_childval);
        }

        // Set from value
        var _val = {};
        _val[this.formControlName] = selectedId;
        this.formGroup.patchValue(_val);

        this.change.emit(selectedId);
    }

    // Set Selected Ids
    SetSelectedIds(selectedId) {
        if (selectedId > 0) {
            var parentId = 0;
            for (let i = this.structures.length - 1; i >= 0; i--) {

                // selected value
                var masters = this.structures[i]["Masters"].filter(function (x) { return x.EnterpriseMasterId == selectedId; });
                if (!!masters && masters.length > 0) {
                    this.structures[i]["SelectedId"] = selectedId;
                    parentId = masters[0].ParentId;
                    selectedId = _clone(parentId);
                }

                // master values
                var selectedMasters = this.structures[i]["Masters"].filter(function (x) { return parentId == 0 || x.ParentId == parentId; });
                if (!!selectedMasters && selectedMasters.length > 0) {
                    this.structures[i]["SelectedMasters"] = selectedMasters;
                } else {
                    this.structures[i]["SelectedMasters"] = [];
                }
            }
        }
    }
}
