import { Component, OnInit, OnDestroy } from '@angular/core';

const _clone = (d) => JSON.parse(JSON.stringify(d));

 @Component({
    selector: 'app-client-logo-list',
    templateUrl: './client-logo-list.component.html',
    styleUrls: ['./client-logo-list.component.scss']
})

export class ClientLogoListComponent implements OnInit, OnDestroy {

    slider;
    slider1;
    slider2;
    slider3;
    slider4;
    slider5;
    slider6;
    slider7;
    slider8;

    slider1_index = 1;
    slider2_index = 1;
    slider3_index = 1;
    slider4_index = 1;
    slider5_index = 1;
    slider6_index = 1;
    slider7_index = 1;
    slider8_index = 1;

    fadeInterval: number = 1000 * 2;
    changeInterval: number = 1000 * 60 * 0.5;

    constructor() {
    }

    ngOnInit() {
        this.setLogoAnimation();
    }

    setLogoAnimation() {

        setTimeout(x => {
            this.slider = setInterval(x => {
                let selector1 = ".slider1 img";
                //this.slider1 = setInterval(x => {
                $(selector1).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector1)[this.slider1_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider1_index++;
                if (this.slider1_index == 6) {
                    this.slider1_index = 0;
                }
                //}, this.changeInterval);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector2 = ".slider2 img";
                //this.slider2 = setInterval(x => {
                $(selector2).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector2)[this.slider2_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider2_index++;
                if (this.slider2_index == 14) {
                    this.slider2_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector3 = ".slider3 img";
                // this.slider3 = setInterval(x => {
                $(selector3).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector3)[this.slider3_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider3_index++;
                if (this.slider3_index == 6) {
                    this.slider3_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector4 = ".slider4 img";
                //this.slider4 = setInterval(x => {
                $(selector4).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector4)[this.slider4_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider4_index++;
                if (this.slider4_index == 6) {
                    this.slider4_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector5 = ".slider5 img";
                //this.slider5 = setInterval(x => {
                $(selector5).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector5)[this.slider5_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider5_index++;
                if (this.slider5_index == 6) {
                    this.slider5_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector6 = ".slider6 img";
                //this.slider6 = setInterval(x => {
                $(selector6).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector6)[this.slider6_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider6_index++;
                if (this.slider6_index == 6) {
                    this.slider6_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector7 = ".slider7 img";
                //this.slider7 = setInterval(x => {
                $(selector7).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector7)[this.slider7_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider7_index++;
                if (this.slider7_index == 5) {
                    this.slider7_index = 0;
                }
                //}, 1000 * 60 * 1);
                //}, 1000 * 60 * 1);

                //setTimeout(x => {
                let selector8 = ".slider8 img";
                //this.slider8 = setInterval(x => {
                $(selector8).fadeOut(this.fadeInterval);
                setTimeout(x => {
                    $($(selector8)[this.slider8_index]).fadeIn(this.fadeInterval);
                }, this.fadeInterval);

                this.slider8_index++;
                if (this.slider8_index == 4) {
                    this.slider8_index = 0;
                }
                //}, 10000);
            }, this.changeInterval);
        }, this.changeInterval);
    }

    ngOnDestroy() {
        document.body.classList.remove("setu-bg");
        if (!this.slider) {
            clearInterval(this.slider);
        }

        if (!this.slider1) {
            clearInterval(this.slider1);
        }
        if (!this.slider2) {
            clearInterval(this.slider2);
        }
        if (!this.slider3) {
            clearInterval(this.slider3);
        }
        if (!this.slider4) {
            clearInterval(this.slider4);
        }
        if (!this.slider5) {
            clearInterval(this.slider5);
        }
        if (!this.slider6) {
            clearInterval(this.slider6);
        }
        if (!this.slider7) {
            clearInterval(this.slider7);
        }
        if (!this.slider8) {
            clearInterval(this.slider8);
        }
    }
}
