import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { HttpModule } from '@angular/http

import { AppComponent } from './app.component';

import { CoreModule } from '@core/core.module';
import { LayoutModule } from './layout/layout.module';
import { AdminLayoutModule } from './admin-layout/admin-layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { AuthService } from '@services/admin/auth.service';
import { ApiInterceptor } from './shared/interceptor/api.interceptor ';
import { SSPSSurveyService } from '@services/admin/ssps-survey.service';
import { HHPSSurveyService } from '@services/admin/hhps-survey.service';
import { TimeagoIntl } from 'ngx-timeago';



// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const ApiInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule, 
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        AdminLayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        AuthService,
        SSPSSurveyService,
        HHPSSurveyService,
        ApiInterceptorProviders,
        TimeagoIntl
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
