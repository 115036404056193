import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'utcToLocal' })
export class UtcToLocalPipe implements PipeTransform {

  transform(date: Date): Date {
    if (!!date) {
      return moment(date + "Z").local().toDate();
    } else {
      return date;
    }
  }
}