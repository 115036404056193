import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgSelectModule } from '@ng-select/ng-select';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';
import { ForbiddenDirective } from './directives/forbidden/forbidden.directive';
import { ImagePreloadDirective } from './directives/image-preload/image-preload.directive';
import { CountdownPipe } from './pipe/pipe.countdown';
import { NumberToWordPipe } from './pipe/pipe.number-to-word';
import { UtcToLocalPipe } from './pipe/pipe.utc-to-local';

import { FilterPipe } from './pipe/pipe.filter';
import { SearchFilterPipe } from './pipe/pipe.search';
import { TrimPipe } from './pipe/pipe.trim';
import { HighlightPipe } from './pipe/pipe.highlight';
import { FileSizePipe } from './pipe/pipe.fileSize';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TimeagoModule } from 'ngx-timeago';
import { MultiselectStructureModule } from '../multiselect-structure/multiselect-structure.module';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { OnlyNumberDirective } from './directives/only-number/only-number.directive';
import { TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';
import { BodyPartService } from '@services/im/bodypart.service';
import { IncidentService } from '@services/im/incident.service';
import { IMIncidentSubmitService } from '@services/im/im-incident-submit.service';
import { NatureOfWorkService } from '@services/admin/nature-of-work.service';
import { ShiftService } from '@services/admin/shift.service';
import { ContractorService } from '@services/admin/contractor.service';
import { DesignationService } from '@services/admin/designation.service';
import { DepartmentService } from '@services/admin/department.service';
import { EmployeeService } from '@services/admin/employee.service';
import { IMEnvironmentIncidentService } from '@services/im/im-environment-incident.service';
import { AttachmentService } from '@services/admin/attachment.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ImReportService } from '@services/im/im-report.service';
import { IMIncidentTypeService } from '@services/im/im-incidenttype.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TrendComponent } from './components/trend/trend.component';
import { TrendService } from '@services/trend/trend.service';
import { FusionChartsModule } from 'angular-fusioncharts';

// Import FusionCharts library and chart modules
import * as FusionCharts from '@core/fusioncharts';
import * as Charts from '@core/fusioncharts/fusioncharts.charts';
import * as Widgets from '@core/fusioncharts/fusioncharts.widgets';
import * as FintTheme from '@core/fusioncharts/themes/fusioncharts.theme.fint';
import { IMIncidentClassificationService } from '@services/im/im-incidentclassification.service';
import { IMInvestigationService } from '@services/im/im-invesgtigation.service';
import { IMNatureOfInjuryService } from '@services/im/im-natureofinjury.service';
import { ImPsmService } from '@services/im/im-psm.service';
import { ImIncidentcauseService } from '@services/im/im-incidentcause.service';
import { BBSObservationTypeService } from '@services/bbs/bbs-observationtype.service';
import { BBSObservationCategoryService } from '@services/bbs/bbs-observationcategory.service';
import { ReportComponent } from './components/report/report.component';
FusionChartsModule.fcRoot(FusionCharts, Charts, FintTheme, Widgets)

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToasterModule,
    TimeagoModule.forRoot({
      intl: { provide: TimeagoIntl, useClass: TimeagoIntl },
      formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
    }),
    InfiniteScrollModule,

    ColorPickerModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    FilterPipeModule,
    CKEditorModule,
    MultiselectStructureModule,
    RecaptchaModule.forRoot({
      //siteKey: '6Lcb9LQZAAAAACkAtzna_eGaolOAVDcDQpCiWSXZ',//mysetu.com
      //siteKey: '6LevSrcZAAAAAEDDE7g77qztB2KvCIDEieS7Ufte',//localhost
      siteKey: _$config.recaptchaSiteKey
    }),
    NgxDatatableModule,
    FusionChartsModule,
  ],
  providers: [
    ColorsService,
    ColorPickerService,
    BodyPartService,
    IncidentService,
    IMIncidentSubmitService,
    NatureOfWorkService,
    ShiftService,
    ContractorService,
    DesignationService,
    DepartmentService,
    EmployeeService,
    IMEnvironmentIncidentService,
    AttachmentService,
    ImReportService,
    IMIncidentTypeService,
    TrendService,
    IMIncidentClassificationService,
    IMInvestigationService,
    IMNatureOfInjuryService,
    ImPsmService,
    ImIncidentcauseService,
    BBSObservationCategoryService,
    BBSObservationTypeService
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    CountdownPipe,
    NumberToWordPipe,
    UtcToLocalPipe,
    FilterPipe,
    SearchFilterPipe,
    TrimPipe,
    HighlightPipe,
    FileSizePipe,
    ForbiddenDirective,
    OnlyNumberDirective,
    ImagePreloadDirective,
    ReportComponent,
    TrendComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    CountdownPipe,
    NumberToWordPipe,
    UtcToLocalPipe,
    FilterPipe,
    SearchFilterPipe,
    TrimPipe,
    HighlightPipe,
    FileSizePipe,
    ForbiddenDirective,
    OnlyNumberDirective,
    ImagePreloadDirective,
    TimeagoModule,
    InfiniteScrollModule,
    ColorPickerModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    FilterPipeModule,
    CKEditorModule,
    MultiselectStructureModule,
    RecaptchaModule,
    TrendComponent
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}

