import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageType } from '@app.enum';
import { Pagination } from '@interface/interface.pagination';
import { BehaviorSubject } from 'rxjs';
import { ISubscription } from 'rxjs-compat/Subscription';
import { EmployeeService } from './admin/employee.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class GenericDataService {

    //Accessright start
    EditRights= new BehaviorSubject<ISubscription>(undefined);

    sidebarData(param: any) {
        let routeAccessRight:any=[];
        if(!!param && param.length){
            for (let i = 0; i < param.length; i++) {
                const element = param[i];
                let obj:any;
                if(!!element.link){
                    obj = {link:element.link, IsAdd: element.IsAdd, IsEdit: element.IsEdit, IsView: element.IsView, IsDelete: element.IsDelete};
                    routeAccessRight.push(obj);
                }
                if(!!element.submenu && element.submenu.length){
                    for (let j = 0; j < element.submenu.length; j++) {
                        const data = element.submenu[j];
                        obj = {link:data.link, IsAdd: data.IsAdd, IsEdit: data.IsEdit, IsView: data.IsView, IsDelete: data.IsDelete};
                        routeAccessRight.push(obj);
                    }
                }
            }
        }
        this.EditRights.next(routeAccessRight);
        localStorage.setItem("AccessRights", btoa(JSON.stringify(routeAccessRight)));
    }

  constructor(private employeeService:EmployeeService, private route: Router, private _sharedService: SharedService) { 
  }


  EmployeeList = new BehaviorSubject<ISubscription>(undefined);
  RespEmployeeList = new BehaviorSubject<ISubscription>(undefined);
  ContEmployeeList = new BehaviorSubject<ISubscription>(undefined);
      //Employee for current location
      GetEmployeeList() {
        console.log(_$config.api_endpoint)
        let page = new Pagination();
        // normal Employee
        this.employeeService.getEmployeeDataForCurrentLocation().subscribe(
            (data) => {
                if (data.MessageType == MessageType.Success) {
                    let list = data.Result.filter((e) => e.isActive !== false);
                    this.EmployeeList.next(list);
                }
            },
            (err) => {
            },
            () => { }
        );

        // responsible employee
        this.employeeService.getRespEmployeeDataForCurrentLocation().subscribe(
            (data) => {
                if (data.MessageType == MessageType.Success) {
                    let list = data.Result.filter((e) => e.isActive !== false);
                    this.RespEmployeeList.next(list);
                }
            },
            (err) => {
            },
            () => { }
        );


        // contractor employee
        this.employeeService.getContEmployeeDataForCurrentLocation().subscribe(
            (data) => {
                if (data.MessageType == MessageType.Success) {
                    let list = data.Result.filter((e) => e.isActive !== false);
                    this.ContEmployeeList.next(list);
                }
            },
            (err) => {
            },
            () => { }
        );

    }
    //Employee list end

    //access right start
    getAccessRightForCurrentRoute(){

    }
    //access right end
}
