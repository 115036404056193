import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserRole } from '@app.enum';
import { AppSession } from '@interface/interface.app-session';
import { Application } from '@utility/utility.application';

@Injectable()
export class TranslatorService {

    private defaultLanguage: string = 'en';

    session: AppSession;
    //private defaultLanguage: string = 'hi';
    private languages = [
        { code: 'en', text: 'English' },
        { code: 'hi', text: 'Hindi (हिंदी)' },
        { code: 'gu', text: 'Gujarati (ગુજરાતી)' }
    ];

    private availablelangs = [];

    constructor(public translate: TranslateService) {
        this.setClientLanguage();
        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        this.useLanguage();

    }

    useLanguage(lang: string = null) {
        let validLanguage = false;
        for (let index = 0; index < this.languages.length; index++) {
            if (this.languages[index].code == lang) {
                validLanguage = true;
            }

        }
        if (validLanguage) {
            Application.Set("Accept-Language", lang || this.translate.getDefaultLang());
            this.translate.use(lang || this.translate.getDefaultLang());
        }
    }

    getAvailableLanguages() {
        this.setClientLanguage();
        return this.availablelangs;
    }

    setClientLanguage() {
        this.availablelangs = [];
        this.session = Application.GetSession();
        if (!!this.session && this.session.UserRoleId != UserRole.SuperAdmin) {
            if (!!this.session.Languages && this.session.Languages.length > 0) {
                let langs = this.session.Languages.split(',');
                for (let index = 0; index < langs.length; index++) {
                    let _lan = this.languages.filter(x => x.code == langs[index]);
                    if (!!_lan && _lan.length > 0) {
                        this.availablelangs.push(_lan[0]);
                        this.defaultLanguage = _lan[0].code;
                    }
                }
            }

            if (!this.availablelangs || this.availablelangs.length == 0) {
                this.availablelangs.push(this.availablelangs[0]);
            }
        } else {
            this.availablelangs = this.languages;
        }

        let session_lang = Application.Get("Accept-Language");
        for (let index = 0; index < this.availablelangs.length; index++) {
            let _lan = this.availablelangs.filter(x => x.code == session_lang);
            if (!!_lan && _lan.length > 0) {
                this.defaultLanguage = _lan[0].code;
            }
        }

        if (session_lang != this.defaultLanguage) {
            this.useLanguage(this.defaultLanguage);
        }
    }

    getSelectedLanguages() {
        return this.defaultLanguage;
    }

}
