import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root'
})
export class FilterHistoryService {
  private history: string[] = [];
  previousUrl: any;
 
  private filtersStack: { [key: string]: string }[] = [];
 
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        this.history = this.history.concat(url);
      });
  }
 
  getHistory(){
    return this.history
  }
 
  goBack(): void {
    if (this.history.length > 1) {
      this.history.pop();
      this.previousUrl = this.history[this.history.length - 1];
      const url = this.previousUrl.split('?')
      console.log('Previous URL:', url[0]);
      console.log('Current Query Params:', this.getCurrentQueryParams());
      this.router.navigateByUrl(url[0], { queryParams: this.getCurrentQueryParams() })
    } else {
      this.router.navigateByUrl('/', { queryParams: this.getCurrentQueryParams() });
    }
  }
 
  pushFilters(filters: { [key: string]: string }): void {
    this.filtersStack.push(filters);
  }
 
  popFilters(): { [key: string]: string } | undefined {
    return this.filtersStack.pop();
  }
 
  popFilter(): { [key: string]: string } | undefined {
    return this.filtersStack[0];
  }
 
  private getCurrentQueryParams(){
    return this.route.snapshot.queryParams.q;
  }
 
 
  updateFilters(filters: { [key: string]: string }){
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: filters,
      queryParamsHandling: 'merge',
    });
  }
}
 