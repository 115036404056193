import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { Application } from '@utility/utility.application';
import { ApiUtility } from '@utility/utility.api-utility';

// Designation Service
@Injectable()
export class DesignationService {

  constructor(private http: HttpClient) { }

  // Get Designations
  getJobCategories(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'designation/job-category' + uri);
  }

  // Get Designations
  getDesignations(page: Pagination = null,search?:any,selectedCh?:any) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'designation' + uri +(!!selectedCh ? '&Character=' + selectedCh : '')+ (!!search ? '&Search=' + search : ''));
  }

  // Edit Designation
  editDesignation(designationId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'designation/' + designationId);
  }

  // getDesignationAuditTrail
  getDesignationAuditTrail(designationId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'designation/' + designationId + '/audit-trail');
  }

  // Add Designation
  addDesignation(designation: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'designation', designation);
  }

  // Update Designation
  updateDesignation(designation: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'designation/' + designation.DesignationId, designation);
  }

  // Update Designation
  updateDesignationStatus(designationId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'designation/' + designationId + '/status?isActive=' + isActive, null);
  }

  // Delete Designation
  deleteDesignation(designationId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'designation/' + designationId);
  }

  importFile(fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http
      .post<iApiResponse>(_$config.api_endpoint + 'designation/import', input);
  }

  downloadDesignationExcel(format) {
    var api = 'designation/Report/' + format;
    this.downloadFile(api, 'DesignationList.' + format);
  }

  SampleFormatDesignationDownload() {
    var api = 'designation/Sample'
    ApiUtility.downloadFile(api, 'SampleFormatDesignationList.xlsx');
  }

  getHeaders() {
    var headers: any = {};
            headers.Authorization = 'Bearer ' + Application.Get("access_token");

            if (!!Application.Get("ClientId")) {
                headers.ClientId = Application.Get("ClientId");
            }

            if (!!Application.Get("ClientRoleId")) {
                headers.ClientRoleId = Application.Get("ClientRoleId");
            }

            if (!!Application.Get("LocationId")) {
                headers.LocationId = Application.Get("LocationId");
            }

            if (!!Application.Get("TimezoneId")) {
                headers.TimezoneId = Application.Get("TimezoneId");
            } else {
                headers.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
            }

            return headers;
  }

  private downloadFile(api, filename) {
    var headers = this.getHeaders();
    var request = new XMLHttpRequest();
    request.open('GET', _$config.api_endpoint + api, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.setRequestHeader('Authorization', headers.Authorization);
    request.setRequestHeader('ClientId', headers.ClientId);
    request.setRequestHeader('ClientRoleId', headers.ClientRoleId);
    request.setRequestHeader('LocationId', headers.LocationId);
    request.setRequestHeader('TimezoneId', headers.TimezoneId);
    request.responseType = 'blob';

    request.onload = function () {
      // Only handle status code 200
      if (request.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        // var disposition = request.getResponseHeader('content-disposition');
        // var matches = /"([^"]*)"/.exec(disposition);
        // var filename = (matches != null && matches[1] ? matches[1] : 'file.' + format);

        // The actual download
        var blob = new Blob([request.response], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }

      // some error handling should be done here...
    };

    request.send();
  }

}
