import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Nature Of Work Service
@Injectable()
export class NatureOfWorkService {

  constructor(private http: HttpClient) { }

  // Get Nature Of Works
  getNatureOfWorks(page: Pagination, showResponsibleUser: boolean = false,search?:any,selectedCh?:any) {
    let uri: string = "";
    var param = [];
    if (!!page) {
      if (showResponsibleUser) {
        param = ['showResponsibleUser=true'];
      }
      uri = page.GetUri(param);
    } else if (showResponsibleUser) {
      uri = '?showResponsibleUser=true';
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-work' + uri +(!!selectedCh ? '&Character=' + selectedCh : '')+ (!!search ? '&Search=' + search : ''));
  }

  // Edit Nature Of Work
  editNatureOfWork(natureOfWorkId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-work/' + natureOfWorkId);
  }

  // getNatureOfWorkAuditTrail
  getNatureOfWorkAuditTrail(natureOfWorkId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-work/' + natureOfWorkId + '/audit-trail');
  }

  // Add Nature Of Work
  addNatureOfWork(natureOfWork: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'nature-of-work', natureOfWork);
  }

  // Update Nature Of Work
  updateNatureOfWork(natureOfWork: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'nature-of-work/' + natureOfWork.NatureOfWorkId, natureOfWork);
  }

  // Update Nature Of Work
  updateNatureOfWorkStatus(natureOfWorkId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'nature-of-work/' + natureOfWorkId + '/status?isActive=' + isActive, null);
  }

  // Delete Nature Of Work
  deleteNatureOfWork(natureOfWorkId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'nature-of-work/' + natureOfWorkId);
  }

  downloadnatureofworkReport(format) {
    var api = 'nature-of-work/Report/' + format;
    ApiUtility.downloadFile(api, 'NatureOfWorkList.' + format);
  }

}
