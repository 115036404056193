import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';
import { debugOutputAstAsTypeScript } from '@angular/compiler';


// Employee Service
@Injectable()
export class EmployeeService {

  constructor(private http: HttpClient) { }

  getEmpParams(employee: any) {
    var query = "";
    var param = [];
    if (!!employee.DepartmentId) {
      param.push("DepartmentIds=" + employee.DepartmentId);
    }
    if (!!employee.DesignationId) {
      param.push("DesignationIds=" + employee.DesignationId);
    }
    if (!!employee.PositionId) {
      param.push("PositionIds=" + employee.PositionId);
    }
    // if (!!employee.FacilityMasterId) {
    //   param.push("FacilityIds=" + employee.FacilityMasterId);
    // }

    if (!!employee.FacilityMasterId && employee.FacilityMasterId.length > 0) {
      for (let index = 0; index < employee.FacilityMasterId.length; index++) {
        param.push("FacilityIds=" + employee.FacilityMasterId[index]);
      }
    }
    //param.push("IsUser=" + true);
    if (!!employee.IsUser) {
      param.push("IsUser=" + employee.IsUser);
    }
    if (!!employee.Search) {
      param.push("Search=" + employee.Search);
    }
    if (!!employee.ClientRoleId) {
      param.push("ClientRoleId=" + employee.ClientRoleId);
    }
    if (!!employee.IMConfigDesc) {
      param.push("IMConfigDesc=" + employee.IMConfigDesc);
    }
    if (param.length > 0) {
      query = "?" + param.join("&");
    }
    return query;
  }

  getallemployees() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/getallempOfalllocations')
  }

  getEmployees(page: Pagination, params: string[] = [],search?:any,Employeeid?:any) {
    let uri: string = page.GetUri(params);
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + (!!search ? '&Search=' + search : '')+ (!!Employeeid ? '&employeeIds=' + Employeeid : ''));
  }

  getClientUser() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/clientuser');
  }

  // Get Employees
  getEmployeeWithSearch(page: Pagination, employee, character, search, empTypeId?:any, onlyUser = false) {
    var query = this.getEmpParams(employee);
    let uri: string = page.GetUri() + query.replace('?', '&');
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + '&StartWith=' + character + '&Search=' + search + (onlyUser ? '&IsUser=true' : '') + (empTypeId ? '&EmployeeTypeId=' + empTypeId : ''));
  }

  
  // Get Master Employees
  getMasterEmployeeWithSearch(page: Pagination, employee, character, search, empTypeId?: any, onlyUser = false) {
    var query = this.getEmpParams(employee);
    let uri: string = page.GetUri() + query.replace('?', '&');
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/master-employees' + uri + '&StartWith=' + character + '&Search=' + search + (onlyUser ? '&IsUser=true' : '') + (empTypeId ? '&EmployeeTypeId=' + empTypeId : ''));
  }

  // Get Profile
  getProfile() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/profile');
  }

  // Get Profile Employee
  getProfile_Employee() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/profile/current-user');
  }

  // Update Profile Employee
  updateProfile_Employee(employee: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/profile/current-user', employee);
  }

  // Edit Employee
  editEmployee(employeeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/' + employeeId);
  }

  // Edit Contractor Employee
  editContractorEmployee(employeeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/contractor/' + employeeId);
  }

  // getEmployeeAuditTrail
  getEmployeeAuditTrail(employeeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/' + employeeId + '/audit-trail');
  }

  // Add Employee
  addEmployee(employee: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'employee', employee);
  }

  // Update Employee
  updateEmployee(employee: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/' + employee.EmployeeId, employee);
  }

  //Add Contractpr Employee
  addContractorEmployee(employee: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'employee/contractor', employee);
  }

  //Update Contractor Employee
  updateContractorEmployee(employee: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/contractor/' + employee.EmployeeId, employee);
  }

  // Update Employee
  updateEmployeeStatus(employeeId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/' + employeeId + '/status?isActive=' + isActive, null);
  }

  // Delete Employee
  deleteEmployee(employeeId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'employee/' + employeeId);
  }

  importFile(fileToUpload: any, importOption: number = 1) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http
      .post<iApiResponse>(_$config.api_endpoint + 'employee/import?importOption=' + importOption, input);
  }

  saveColumnMapping(employeeMapping: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'employee/column-mapping', employeeMapping);
  }

  getEmployee(page: Pagination, employee,EmployeeType?:boolean,moduleId?:number,search?:any) {
    let query = this.getEmpParams(employee);
    let uri: string = page.GetUri() + query.replace('?', '&');
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + (EmployeeType ? '&EmployeeTypeId='+EmployeeType : '') + (!!moduleId ? '&moduleId='+moduleId : '')+  (!!search ? '&Search='+search : ''));
  }

  downloadEmployeeReport(format) {
    var api = 'employee/Report/' + format;
    ApiUtility.downloadFile(api, 'EmployeeList.' + format);
  }

  SampleFormatEmployeeDownload() {
    var api = 'employee/Sample'
    ApiUtility.downloadFile(api, 'SampleFormatEmployeeList.xlsx');
  }

  getEmployeeName() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/information?onlyEmployee=false');
  }

  //Pending Task get api
  getEmployeePendingTaskDetail(employeeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/task-pending/' + employeeId);
  }

  //Search employee by employeeid
  SearchEmployeeById(EmployeeId:number){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + (!!EmployeeId ? '?employeeIds=' + EmployeeId : ''));
  }

  // login employee data is been loaded
  getEmployeeDataForCurrentLocation(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + '?EmployeeTypeId=1');
  }
  getContEmployeeDataForCurrentLocation(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + '?EmployeeTypeId=2');
  }
  getRespEmployeeDataForCurrentLocation(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + '?moduleId=13');
  }

  // role transfer
  getRoleTransferDetail(id){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/transfer/' + id);
  }
  updateRoleTransferDetail(id,data){
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/transfer/' + id,data);
  }
  updateUnit(id,unitId,body?:any){
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'employee/transfer/location/' + id + '?TransferToEnterpriseMasterId=' + unitId,body);
  }
}
