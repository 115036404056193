import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Attachment Service
@Injectable()
export class AttachmentService {

  constructor(private http: HttpClient) { }

  // Remove Attachment
  removeAttachment(filepath: string, directory: string) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'attachment/remove/' + filepath + '/' + directory);
  }

  saveAttachment(data,id){
    return this.http.post<iApiResponse>(_$config.api_endpoint + `incident/${id}/incident-attachment`,data)
  }

  getAttachmentDetail(id){
    return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/incident-attachment`)
  }
  ModifyData(data:any,id:any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + `incident/${id}/incident-attachment` ,data)
  }

  // getIncidentDetail(id:any) {
  //   return this.http.get<any>(`https://my-json-server.typicode.com/kaf-shekh/incident/data`);
  // }
  // getIncidentDetail1(id:any) {
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/incident-detail`);
  // }
  // UpdateData(data:any,id:any) {
  //   return this.http.post<iApiResponse>(_$config.api_endpoint + `incident/${id}/fitness-detail` ,data)
  // }
  // ModifyData(data:any,id:any) {
  //   return this.http.put<iApiResponse>(_$config.api_endpoint + `incident/${id}/incident-detail` ,data)
  // }



}
