import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Encryption } from '@utility/utility.encryption';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { UserRole } from '@app.enum';

// Auth Service
@Injectable()
export class AuthService {

  constructor(private http: HttpClient) { }

  // Get basic authorization header
  private getBasicAuth(username: string, password: string) {
    let headers: any = {};
    let keyLevel1 = Encryption.Encrypt(username + ":" + password);
    let keyLevel2 = Encryption.Encrypt(keyLevel1);

    headers.Authorization = 'Basic ' + keyLevel2;

    return {
      headers: new HttpHeaders(headers)
    }
  }

  // Get Access Token
  getAccessToken(username: string, password: string) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'auth/access_token', this.getBasicAuth(username, password));
  }

  // Validate User
  validateUser(username: string, password: string) {
    let keyLevel1 = Encryption.Encrypt(username + ":" + password);
    let keyLevel2 = Encryption.Encrypt(keyLevel1);
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'account/validate-user',{Password: keyLevel2});
  }

  // Logout
  logout() {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'account/logout', null);
  }

  // ValidateTwoFactorAuthCode
  validateTwoFactorAuthCode(session: string, code: String) {
    let userId: number = Application.GetUserId(session);
    let clientId: number = Application.GetClientId(session);
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'account/client/' + clientId + '/user/' + userId + '/tow-factor/' + code, null);
  }

  // Refresh Access Token
  refreshToken() {
    let clientId = 0;
    let session: AppSession = Application.GetSession();
    if (!!session && session.UserRoleId == UserRole.SuperAdmin) {
      clientId = 0;
      let _clientIdString = Application.Get("ClientId");
      if (!!_clientIdString) {
        clientId = parseInt(_clientIdString);
      }
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'auth/refresh_token' + (!!clientId ? '?clientId=' + clientId : ''));
  }

  // Recover password
  recoverPassword(username: string) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'account/recover?userName=' + username, null);
  }

  // Reset password
  resetPassword(user: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'account/resetPassword', user);
  }
}
