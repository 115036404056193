import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';

 @Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    session: AppSession;

    constructor(public userblockService: UserblockService) {
    }

    ngOnInit() {
        this.getSession();
    }

    getSession() {
        this.session = Application.GetSession();
        var clientUserLogo = Application.Get('client-user-logo');
        if (!!clientUserLogo) {
            this.session.EmployeeLogo = clientUserLogo;
        }
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
