import { NgModule } from '@angular/core';
import { RouterModule, Router, ActivationEnd } from '@angular/router';

import { TranslatorService } from '@core/translator/translator.service';
import { MenuService } from '@core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';

import { routes } from './routes';
import { AuthGuard } from '../shared/guards/auth-guard.service';
import { mySetuMenuService } from '@services/admin/menu.service';
import { SharedService } from '@services/shared.service';
import { Application } from '@utility/utility.application';
import { PrivateService } from '@services/admin/private.service';
import { RouterCustomPreloaderService } from '@services/router-custom-preloader.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: RouterCustomPreloaderService }),
        PagesModule
    ],
    declarations: [],
    providers: [
        AuthGuard,
        mySetuMenuService,
        SharedService,
        PrivateService
    ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService, router: Router, private _sharedService: SharedService) {
        router.events
            .filter(event => event instanceof ActivationEnd)
            .subscribe((event: ActivationEnd) => {
                try {
                    if (!!event.snapshot && !!event.snapshot.data && !!event.snapshot.data.menu) {
                        var menuName = event.snapshot.data.menu;
                        if (!!menuName && !!event.snapshot.data && !event.snapshot.data.isSuperAdmin && (event.snapshot.data.code[0] != '#' || event.snapshot.data.code == '#ERROR_403')) {
                            //var mySetuUi = Application.Get('mySetu-ui');
                            //if (mySetuUi != menuName) {
                                Application.Set('mySetu-ui', menuName);
                                this._sharedService.resetMenu(menuName);
                            //}
                        }
                    }
                } catch (e) { }
            });
    }
}
