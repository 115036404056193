import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// IM Environment Incident Service
@Injectable()
export class IMEnvironmentIncidentService {

    constructor(private http: HttpClient) { }

    // Get IMTemplate
    getIMEnvironmentIncidentType(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'environment-incident-type' + uri);
    }


    // Get IM Environment Incident Type
    getEnvironmentIncidentType(page: Pagination) {
        let uri: string = page.GetUri();
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'environment-incident-type' + uri);
    }

    // Edit IM Cause Factor  Type
    editIMEnvironmentIncidentType(causeFactorTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'environment-incident-type' + causeFactorTypeId);
    }

    // Add IM Cause Factor Type
    addIMEnvironmentIncidentType(environmentIncidentType: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'environment-incident-type', environmentIncidentType);
    }

    // Update Cause Factor type
    updateEnvironmentIncidentType(environmentIncidentType: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'environment-incident-type' + environmentIncidentType.environmentIncidentTypeId, environmentIncidentType);
    }

    // Delete Cause Factor Type
    deleteEnvironmentIncidentType(environmentIncidentTypeId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'environment-incident-type/' + environmentIncidentTypeId);
    }
    // get Cause Factor Type Audit Trail
    getEnvironmentIncidentTypeAuditTrail(environmentIncidentTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'environment-incident-type/' + environmentIncidentTypeId + '/audit-trail');
    }
}
