import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

@Injectable({
  providedIn: 'root'
})
export class ImReportService {

  constructor(private http: HttpClient) { }

  getListOfIncident(page: Pagination, filter, url: any) {
    filter.export = false;
    var query = this.getIncidentParams(filter);
    let uri: string = page.GetUri() + query.replace('?', '&');
    // console.log(_$config.api_endpoint + url + uri);
    return this.http.get<iApiResponse>(_$config.api_endpoint + url + uri);
    // return this.http.get<iApiResponse>(_$config.api_endpoint + 'im/reports/list-of-incident-report' + uri);
  }

  downloadReports(format, filter = null, url, file) {
    if (!!filter) {
      filter.export = true
    }
    var query = this.getIncidentParams(filter, true);
    let uri: string = "";
    if (query.length > 0) {
      uri = query;
    }
    var filename = file + '.' + format
    var api = url + uri;
    ApiUtility.downloadFile(api, filename);
  }

  getincidentDetails(url: any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + url);

  }

  downloadGicWiseReport(imSearchFilter, format) {
    // let uri: string = "";
    // var query = this.getSearchParams(imSearchFilter);
    // if (query.length > 0) {
    //   uri = "?" + query.join("&");
    // }
    // var api = 'bbs/report/cat/typecount/download/' + format + uri;
    // ApiUtility.downloadFile(api, 'gic ' + format);
  }
  getUnitList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/1/data')
  }
  getGICList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/0/data');
  }
  getAreaList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/2/data');
  }
  getLocationList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/3/data');
  }
  getClusterList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/2/data');
  }
  getDepartmentList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/0/data');
  }
  getPositionList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'position');
  }
  getIncidentNature() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-injury');
  }

  getCauseFactorType() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'operational-cause');
  }

  getLine() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'wti/line');
  }
  getFunctionList() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/0/data');
  }

  getIncidentParams(filter: any, value?: boolean) {
    let query = '';
    const param = [];
    if (!!filter.incidentOccureFromandToDate) {
      param.push('FromDate=' + moment(filter.incidentOccureFromandToDate.startDate).format('YYYY-MMM-DD 00:00:00'));
      param.push('toDate=' + moment(filter.incidentOccureFromandToDate.endDate).format('YYYY-MMM-DD 23:59:59'));
      // param.push('FromDate=' + moment(filter.incidentOccureFromandToDate[0]).format('YYYY-MMM-DD'));
      // param.push('toDate=' + moment(filter.incidentOccureFromandToDate[1]).format('YYYY-MMM-DD'));
    }
    if (!!filter.Status) {
      param.push("Status=" + filter.Status);
    }
    if (!!filter.selected) {
      param.push('FromDate=' + moment(filter.selected.startDate).format('YYYY-MMM-DD 00:00:00'));
      param.push('toDate=' + moment(filter.selected.endDate).format('YYYY-MMM-DD 23:59:59'));
    }
    if (!!filter.DateRange) {
      param.push('FromDate=' + moment(filter.DateRange.startDate).format('YYYY-MMM-DD 00:00:00'));
      param.push('toDate=' + moment(filter.DateRange.endDate).format('YYYY-MMM-DD 23:59:59'));
    }
    // if (!!filter.date) {
    //   param.push('year=' + moment(filter.date.startDate).format('YYYY-MMM-DD'));

    // }

    if (!!filter.month) {
      param.push('month=' + moment(filter.month).format('YYYY-MMM'));
    }
    if (!!filter.frommonth) {
      param.push('frommonth=' + moment(filter.frommonth).format('YYYY-MMM'));
    }
    if (!!filter.tomonth) {
      param.push('tomonth=' + moment(filter.tomonth).format('YYYY-MMM'));
    }
    if (!!filter.year) {
      param.push('year=' + moment(filter.year).format('YYYY'));
    }
    if (!!filter.fromyear) {
      param.push('fromyear=' + moment(filter.fromyear).format('YYYY'));
    }

    if (!!filter.toyear) {
      param.push('toyear=' + moment(filter.toyear).format('YYYY'));
    }

    if (!!filter.monthNumber) {
      param.push('monthNumber=' + filter.monthNumber);
    }

    if (!!filter.ShiftIds) {
      if (typeof filter.ShiftIds == 'string' && filter.ShiftIds.includes(',')) {
        let element = filter.ShiftIds.split(',');
        element.forEach(x => {
          param.push("ShiftIds=" + x);
        });
      } else {
        if (typeof filter.ShiftIds == 'string' || typeof filter.ShiftIds == 'number') {
          param.push("ShiftIds=" + filter.ShiftIds);
        }
        else {
          filter.ShiftIds.forEach(element => {
            param.push("ShiftIds=" + element);
          });
        }
      }
    }

    if (!!filter.EmployeeIds) {
      if (
        typeof filter.EmployeeIds == "string" &&
        filter.EmployeeIds.includes(",")
      ) {
        let element = filter.EmployeeIds.split(",");
        element.forEach((x) => {
          param.push("EmployeeIds=" + x);
        });
      } else {
        if (
          typeof filter.EmployeeIds == "string" ||
          typeof filter.EmployeeIds == "number"
        ) {
          param.push("EmployeeIds=" + filter.EmployeeIds);
        } else {
          filter.EmployeeIds.forEach((element) => {
            param.push("EmployeeIds=" + element);
          });
        }
      }
    }

    if (!!filter.ObserverIds) {
      if (
        typeof filter.ObserverIds == "string" &&
        filter.ObserverIds.includes(",")
      ) {
        let element = filter.ObserverIds.split(",");
        element.forEach((x) => {
          param.push("ObserverIds=" + x);
        });
      } else {
        if (
          typeof filter.ObserverIds == "string" ||
          typeof filter.ObserverIds == "number"
        ) {
          param.push("ObserverIds=" + filter.ObserverIds);
        } else {
          filter.ObserverIds.forEach((element) => {
            param.push("ObserverIds=" + element);
          });
        }
      }
    }

    if (!!filter.ResponsibleIds) {
      if (
        typeof filter.ResponsibleIds == "string" &&
        filter.ResponsibleIds.includes(",")
      ) {
        let element = filter.ResponsibleIds.split(",");
        element.forEach((x) => {
          param.push("ResponsibleIds=" + x);
        });
      } else {
        if (
          typeof filter.ResponsibleIds == "string" ||
          typeof filter.ResponsibleIds == "number"
        ) {
          param.push("ResponsibleIds=" + filter.ResponsibleIds);
        } else {
          filter.ResponsibleIds.forEach((element) => {
            param.push("ResponsibleIds=" + element);
          });
        }
      }
    }

    if (!!filter.ObservationType) {
      if (typeof filter.ObservationType == "string" && filter.ObservationType.includes(",")) {
          let element = filter.ObservationType.split(",");
          element.forEach((x) => {
              param.push("ObservationType=" + x);
          });
      } else {
          if (typeof filter.ObservationType == "string" || typeof filter.ObservationType == "number") {
              param.push("ObservationType=" + filter.ObservationType);
          } else {
              filter.ObservationType.forEach((element) => {
                  param.push("ObservationType=" + element);
              });
          }
      }
    }

    if (!!filter.IncidentCategoryId) {
      param.push("IncidentCategoryId=" + filter.IncidentCategoryId);
    }
    if (!!filter.IncidentStatusId) {
      param.push("IncidentStatusId=" + filter.IncidentStatusId);
    }

    if (!!filter.OperationalCauseID) {
      param.push("OperationalCauseID=" + filter.OperationalCauseID);
    }
    if (!!filter.IncidentClassificationId) {
      param.push("IncidentClassificationId=" + filter.IncidentClassificationId);
    }
    if (!!filter.IncidentTypeIds) {
      if (typeof filter.IncidentTypeIds == 'string' && filter.IncidentTypeIds.includes(',')) {
        let element = filter.IncidentTypeIds.split(',');
        element.forEach(x => {
          param.push("IncidentTypeIds=" + x);
        });
      } else {
        if (typeof filter.IncidentTypeIds == 'string' || typeof filter.IncidentTypeIds == 'number') {
          param.push("IncidentTypeIds=" + filter.IncidentTypeIds);
        }
        else {
          filter.IncidentTypeIds.forEach(element => {
            param.push("IncidentTypeIds=" + element);
          });
        }
      }
    }
    if (!!filter.EmployeeTypeIds) {
      if (typeof filter.EmployeeTypeIds == 'string' && filter.EmployeeTypeIds.includes(',')) {
        let element = filter.EmployeeTypeIds.split(',');
        element.forEach(x => {
          param.push("EmployeeTypeIds=" + x);
        });
      } else {
        if (typeof filter.EmployeeTypeIds == 'string' || typeof filter.EmployeeTypeIds == 'number') {
          param.push("EmployeeTypeIds=" + filter.EmployeeTypeIds);
        }
        else {
          filter.EmployeeTypeIds.forEach(element => {
            param.push("EmployeeTypeIds=" + element);
          });
        }
      }
    }
    if (!!filter.ReportedByGIC) {
      if (typeof filter.ReportedByGIC == 'string' || typeof filter.ReportedByGIC == 'number') {
        param.push("ReportedByGIC=" + filter.ReportedByGIC);
      }
      else {
        filter.ReportedByGIC.forEach(element => {
          param.push("ReportedByGIC=" + element);
        });
      }
    }

    if (!!filter.ObserverGIC) {
      if (typeof filter.ObserverGIC == 'string' || typeof filter.ObserverGIC == 'number') {
        param.push("ObserverGIC=" + filter.ObserverGIC);
      }
      else {
        filter.ObserverGIC.forEach(element => {
          param.push("ObserverGIC=" + element);
        });
      }
    }

    if (!!filter.ReportedInGIC) {
      if (typeof filter.ReportedInGIC == 'string' || typeof filter.ReportedInGIC == 'number') {
        param.push("ReportedInGIC=" + filter.ReportedInGIC);
      }
      else {
        filter.ReportedInGIC.forEach(element => {
          param.push("ReportedInGIC=" + element);
        });
      }
    }
    if (!!filter.CategoryIds) {
      if (filter.CategoryIds.includes(',')) {
        let element = filter.CategoryIds.split(',');
        element.forEach(x => {
          param.push("CategoryIds=" + x);
        });
      } else {
        if (typeof filter.CategoryIds == 'string' || typeof filter.CategoryIds == 'number') {
          param.push("CategoryIds=" + filter.CategoryIds);
        }
        else {
          filter.CategoryIds.forEach(element => {
            param.push("CategoryIds=" + element);
          });
        }
      }
    }
    if (!!filter.IncidentNatureIds) {
      if (typeof filter.IncidentNatureIds == 'string' && filter.IncidentNatureIds.includes(',')) {
        let element = filter.IncidentNatureIds.split(',');
        element.forEach(x => {
          param.push("IncidentNatureIds=" + x);
        });
      } else {
        if (typeof filter.IncidentNatureIds == 'string' || typeof filter.IncidentNatureIds == 'number') {
          param.push("IncidentNatureIds=" + filter.IncidentNatureIds);
        }
        else {
          filter.IncidentNatureIds.forEach(element => {
            param.push("IncidentNatureIds=" + element);
          });
        }
      }
    }
    if (!!filter.OperationalCauseIds) {
      if (typeof filter.OperationalCauseIds == 'string' && filter.OperationalCauseIds.includes(',')) {
        let element = filter.OperationalCauseIds.split(',');
        element.forEach(x => {
          param.push("OperationalCauseIds=" + x);
        });
      } else {
        if (typeof filter.OperationalCauseIds == 'string' || typeof filter.OperationalCauseIds == 'number') {
          param.push("OperationalCauseIds=" + filter.OperationalCauseIds);
        }
        else {
          filter.OperationalCauseIds.forEach(element => {
            param.push("OperationalCauseIds=" + element);
          });
        }
      }

    }
    if (!!filter.LineIds) {
      if (typeof filter.LineIds == 'string' && filter.LineIds.includes(',')) {
        let element = filter.LineIds.split(',');
        element.forEach(x => {
          param.push("LineIds=" + x);
        });
      } else {
        if (typeof filter.LineIds == 'string' || typeof filter.LineIds == 'number') {
          param.push("LineIds=" + filter.LineIds);
        }
        else {
          filter.LineIds.forEach(element => {
            param.push("LineIds=" + element);
          });
        }
      }
    }
    // if (!!filter.FacilityMasterIds) {
    //   if(typeof filter.FacilityMasterIds == 'string' || typeof filter.FacilityMasterIds == 'number'){
    //     param.push("FacilityMasterIds=" + filter.FacilityMasterIds);
    //   }
    //   else{
    //     filter.FacilityMasterIds.forEach(element => {
    //       param.push("FacilityMasterIds=" + element);
    //     });
    //   }
    // }
    if (!!filter.ClusterIds) {
      if (typeof filter.ClusterIds == 'string' && filter.ClusterIds.includes(',')) {
        let element = filter.ClusterIds.split(',');
        element.forEach(x => {
          param.push("ClusterIds=" + x);
        });
      } else {
        if (typeof filter.ClusterIds == 'string' || typeof filter.ClusterIds == 'number') {
          param.push("ClusterIds=" + filter.ClusterIds);
        }
        else {
          filter.ClusterIds.forEach(element => {
            param.push("ClusterIds=" + element);
          });
        }
      }

    }
    if (!!filter.DepartmentIds) {
      if (typeof filter.DepartmentIds == 'string' && filter.DepartmentIds.includes(',')) {
        let element = filter.DepartmentIds.split(',');
        element.forEach(x => {
          param.push("DepartmentIds=" + x);
        });
      } else {
        if (typeof filter.DepartmentIds == 'string' || typeof filter.DepartmentIds == 'number') {
          param.push("DepartmentIds=" + filter.DepartmentIds);
        }
        else {
          filter.DepartmentIds.forEach(element => {
            param.push("DepartmentIds=" + element);
          });
        }
      }
    }
    if (!!filter.FunctionIds) {
      if (typeof filter.FunctionIds == 'string' && filter.FunctionIds.includes(',')) {
        let element = filter.FunctionIds.split(',');
        element.forEach(x => {
          param.push("FunctionIds=" + x);
        });
      } else {
        if (typeof filter.FunctionIds == 'string' || typeof filter.FunctionIds == 'number') {
          param.push("FunctionIds=" + filter.FunctionIds);
        }
        else {
          filter.FunctionIds.forEach(element => {
            param.push("FunctionIds=" + element);
          });
        }
      }
    }
    if (!!filter.PositionIds) {
      if (typeof filter.PositionIds == 'string' && filter.PositionIds.includes(',')) {
        let element = filter.PositionIds.split(',');
        element.forEach(x => {
          param.push("PositionIds=" + x);
        });
      } else {
        if (typeof filter.PositionIds == 'string' || typeof filter.PositionIds == 'number') {
          param.push("PositionIds=" + filter.PositionIds);
        }
        else {
          filter.PositionIds.forEach(element => {
            param.push("PositionIds=" + element);
          });
        }
      }
    }
    if (!!filter.FacilityMasterId) {
      param.push("FacilityMasterId=" + filter.FacilityMasterId)
    }

    if (!!filter.FacilityMasterIds) {
      if (typeof filter.FacilityMasterIds == 'string' && filter.FacilityMasterIds.includes(',')) {
        let element = filter.FacilityMasterIds.split(',');
        element.forEach(x => {
          param.push("FacilityMasterIds=" + x);
        });
      } else {
        if (typeof filter.FacilityMasterIds == 'string' || typeof filter.FacilityMasterIds == 'number') {
          param.push("FacilityMasterIds=" + filter.FacilityMasterIds)
        }
        if (!!filter.FacilityMasterIds && filter.FacilityMasterIds.length > 0) {
          param.push("FacilityMasterIds=" + filter.FacilityMasterIds);
        }
        else {
          filter.FacilityMasterIds.forEach(element => {
            param.push("FacilityMasterIds=" + element);
          });
        }
      }
    }

    if (!!filter.UnitId) {
      param.push("UnitId=" + filter.UnitId)
    }
    if (!!filter.UnitIds) {
      if (typeof filter.UnitIds == 'string' && filter.UnitIds.includes(',')) {
        let element = filter.UnitIds.split(',');
        element.forEach(x => {
          param.push("UnitIds=" + x);
        });
      } else {
        if (typeof filter.UnitIds == 'string' || typeof filter.UnitIds == 'number') {
          param.push("UnitIds=" + filter.UnitIds);
        }
        else {
          filter.UnitIds.forEach(element => {
            param.push("UnitIds=" + element);
          });
        }
      }
    }
    if (!!filter.GICId) {
      // filter.GICId.forEach(element => {
      param.push("GICId=" + filter.GICId);
      // });
    }
    if (!!filter.Details) {
      param.push("IncidentDetails=" + filter.Details);
    }

    if (!!filter.GIC) {
      filter.GIC.forEach(element => {
        param.push("GIC=" + element);
      });
    }

    
    if (!!filter.FacilityMasterIds) {
      filter.FacilityMasterIds.forEach(element => {
        param.push("GIC=" + element);
      });
    }

    if (!!filter.Area) {
      filter.Area.forEach(element => {
        param.push("Area=" + element);
      });
    }

    if (!!filter.Area) {
      filter.Area.forEach(element => {
        param.push("Area=" + element);
      });
    }

    if (!!filter.InspectorId) {
      param.push("InspectorId=" + filter.InspectorId);
    }
    if (!!filter.ExportType) {
      param.push("ExportType=" + filter.ExportType);
    }

    if (!!filter.ShowAll) {
      param.push("ShowAll=" + filter.ShowAll);
    }

    if (!!filter.AreaId) {
      param.push("AreaId=" + filter.AreaId);
    }

    if (!!filter.StandardId) {
      param.push("StandardId=" + filter.StandardId);
    }
    if (!!filter.StatusId) {
      param.push("StatusId=" + filter.StatusId);
    }
    if (!!filter.DepartmentId) {
      param.push("DepartmentId=" + filter.DepartmentId);
    }
    if (!!filter.AreaIds) {
      if (typeof filter.AreaIds == 'string' && filter.AreaIds.includes(',')) {
        let element = filter.AreaIds.split(',');
        element.forEach(x => {
          param.push("AreaIds=" + x);
        });
      } else {
        if (typeof filter.AreaIds == 'string' || typeof filter.AreaIds == 'number') {
          param.push("AreaIds=" + filter.AreaIds);
        }
        else {
          filter.AreaIds.forEach(element => {
            param.push("AreaIds=" + element);
          });
        }
      }
    }
    if (!!filter.EnterpriseMasterId) {
      if (typeof filter.EnterpriseMasterId == 'string' || typeof filter.EnterpriseMasterId == 'number') {
        param.push("EnterpriseMasterId=" + filter.EnterpriseMasterId);
      }
      else {
        filter.EnterpriseMasterId.forEach(element => {
          param.push("EnterpriseMasterId=" + element);
        });
      }
    }
    if (!!filter.Location) {
      filter.Location.forEach(element => {
        param.push("Location=" + element);
      });
    }
    if (!!filter.IsLWC) {
      param.push("IsLWC=" + filter.IsLWC)
    }
    if (!!filter.IsRWC) {
      param.push("IsRWC=" + filter.IsRWC)
    }
    if (!!filter.IsMTC) {
      param.push("IsMTC=" + filter.IsMTC)
    }
    if (!!filter.IsFullInvestigationNeeded) {
      param.push("IsFullInvestigationNeeded=" + filter.IsFullInvestigationNeeded)
    }
    if (!!filter.IsInvestigationBeyond7Days) {
      param.push("IsInvestigationBeyond7Days=" + filter.IsInvestigationBeyond7Days)
    }
    if (!!filter.ViewForAllUnits) {
      param.push("ViewForAllUnits=" + filter.ViewForAllUnits)
    }
    if (!!filter.IsAreaWise && filter.IsAreaWise == true) {
      param.push("IsAreaWise=" + filter.IsAreaWise)
    }
    if (!!filter.IsGICWise && filter.IsGICWise == true) {
      param.push("IsGICWise=" + filter.IsGICWise)
    }
    if (!!filter.IsUnitWise && filter.IsUnitWise == true) {
      param.push("IsUnitWise=" + filter.IsUnitWise)
    }
    if (!!filter.IsClusterWise && filter.IsClusterWise == true) {
      param.push("IsClusterWise=" + filter.IsClusterWise)
    }
    if (!!filter.IsareaWiseForCluster && filter.IsareaWiseForCluster == true) {
      param.push("IsareaWiseForCluster=" + filter.IsareaWiseForCluster)
    }
    if (!!filter.IsSeverityWise && (filter.IsSeverityWise == true || filter.IsSeverityWise == 'true')) {
      param.push("IsSeverityWise=" + filter.IsSeverityWise)
    }
    if (!!filter.IsDepartmentWise && filter.IsDepartmentWise == true) {
      param.push("IsDepartmentWise=" + filter.IsDepartmentWise)
    }
    if (!!filter.IsUserWise && filter.IsUserWise == true) {
      param.push("IsUserWise=" + filter.IsUserWise)
    }

    if (!!filter.IsUserWiseWithIds && filter.IsUserWiseWithIds == true) {
      param.push("IsUserWiseWithIds=" + filter.IsUserWiseWithIds)
    }

    if (!!filter.IsFunctionWiseWithIds && filter.IsFunctionWiseWithIds == true) {
      param.push("IsFunctionWiseWithIds=" + filter.IsFunctionWiseWithIds)
    }
    if (!!filter.IsPositionWiseWithIds && filter.IsPositionWiseWithIds == true) {
      param.push("IsPositionWiseWithIds=" + filter.IsPositionWiseWithIds)
    }
    if (!!filter.IsDepartmentWiseWithIds && filter.IsDepartmentWiseWithIds == true) {
      param.push("IsDepartmentWiseWithIds=" + filter.IsDepartmentWiseWithIds)
    }

    // filter panlel 2
    if (!!filter.IsUnitWiseWithIds && filter.IsUnitWiseWithIds == true) {
      param.push("IsUnitWiseWithIds=" + filter.IsUnitWiseWithIds)
    }
    if (!!filter.IsSectionWiseWithIds && filter.IsSectionWiseWithIds == true) {
      param.push("IsSectionWiseWithIds=" + filter.IsSectionWiseWithIds)
    }
    if (!!filter.IsLocationWiseWithIds && filter.IsLocationWiseWithIds == true) {
      param.push("IsLocationWiseWithIds=" + filter.IsLocationWiseWithIds)
    }


    if (!!filter.OverdueRecommendation) {
      param.push("OverdueRecommendation=" + filter.OverdueRecommendation)
    }
    if (!!filter.IsCementBussiness && filter.IsCementBussiness == true) {
      param.push("IsCementBussiness=" + filter.IsCementBussiness)
    }
    if (!!filter.IsComulativeSearch && filter.IsComulativeSearch == true) {
      param.push("IsComulativeSearch=" + filter.IsComulativeSearch)
    }
    if (!!filter.IsConcreteBussiness && filter.IsConcreteBussiness == true) {
      param.push("IsConcreteBussiness=" + filter.IsConcreteBussiness)
    }
    if (!!filter.IsTotalBussiness && filter.IsTotalBussiness == true) {
      param.push("IsTotalBussiness=" + filter.IsTotalBussiness)
    }
    if (!!filter.IsWhiteBussiness && filter.IsWhiteBussiness == true) {
      param.push("IsWhiteBussiness=" + filter.IsWhiteBussiness)
    }
    if (!!filter.IsBulkTerminal && filter.IsBulkTerminal == true) {
      param.push("IsBulkTerminal=" + filter.IsBulkTerminal)
    }
    if (!!filter.IsGrindingUnits && filter.IsGrindingUnits == true) {
      param.push("IsGrindingUnits=" + filter.IsGrindingUnits)
    }
    if (!!filter.IsIntegratedSites && filter.IsIntegratedSites == true) {
      param.push("IsIntegratedSites=" + filter.IsIntegratedSites)
    }
    if (!!filter.IsRMC && filter.IsRMC == true) {
      param.push("IsRMC=" + filter.IsRMC)
    }
    if (!!filter.IsCoalMine && filter.IsCoalMine == true) {
      param.push("IsCoalMine=" + filter.IsCoalMine)
    }
    if (!!filter.IsBPD && filter.IsBPD == true) {
      param.push("IsBPD=" + filter.IsBPD)
    }
    if (!!filter.IsOverseas && filter.IsOverseas == true) {
      param.push("IsOverseas=" + filter.IsOverseas)
    }
    if (!!filter.IsEastZone && filter.IsEastZone == true) {
      param.push("IsEastZone=" + filter.IsEastZone)
    }
    if (!!filter.AgeGroup) {
      // param.push("AgeGroup=" + '['+filter.AgeGroup+']')
      for (let index = 0; index < filter.AgeGroup.length; index++) {
        param.push("AgeGroup=" + filter.AgeGroup[index]);
      }
    }
    if (!!filter.PSMType) {
      filter.PSMType.forEach(element => {
        param.push("PSMType=" + element);
      });
    }
    if (!!filter.IncidenceCategorizationId) {
      param.push("IncidenceCategorizationId=" + filter.IncidenceCategorizationId);
    }
    if (!!filter.ClientRoleId) {
      param.push("ClientRoleId=" + filter.ClientRoleId);
    }
    if (!!filter.EmployeeId) {
      param.push("EmployeeId=" + filter.EmployeeId);
    }
    if (!!filter.ChecklistId) {
      param.push("ChecklistId=" + filter.ChecklistId);
    }

    if (!!filter.severityId) {
      param.push("severityId=" + filter.severityId);
    }
    if (!!filter.SubSeverityId) {
      param.push("SubSeverityId=" + filter.SubSeverityId);
    }

    if (!!filter.LineId) {
      param.push("LineId=" + filter.LineId);
    }
    if (!!filter.export) {
      param.push("export=" + filter.export);
    }

    if (!!filter.IsAll) {
      param.push("IsAll=" + filter.IsAll);
    }
    if (!!filter.format) {
      param.push("format=" + filter.format);
    }
    //edit end
    if (param.length > 0) {
      if (value) {
        query = '?' + param.join('&');
      }
      else {
        query = '&' + param.join('&');
      }
    }

    return query;
  }
  // getViewInvestigation(id: any){
  //   return this.http.get<iApiResponse>(_$config.api_endpoint + 'get-incident-investigation-detailbyid/' + id );
  // }

  getViewInvestigation(incidentId) {
    return this.http.get<iApiResponse>(
      _$config.api_endpoint + "im/reports/get-incident-investigation-detailbyid?IncidentId=" + incidentId
    );
  }
}
