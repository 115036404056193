import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { MessageType } from '@app.enum';
import { mySetuMenuService } from '@services/admin/menu.service';
import { PackageService } from '@services/admin/package.service';

// helpers
const addPath = (urlAndQuery: string[]) => urlAndQuery[0] ? '/' + urlAndQuery[0] : '';
const addQuery = (urlAndQuery: string[]) => urlAndQuery[1] ? '?' + urlAndQuery[1] : '';

 @Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

    loadingRouterOutlet: boolean;
    daysRemaining: number = -1;
    QuickStepPending:boolean = true;
    subscriptionExpired: boolean = false;;
    showExpireMessage: boolean = false;
    constructor(
        private router: Router,
        public _menu: mySetuMenuService,
        public _clientSubscription: PackageService) {
        //this.loadingRouterOutlet = true;
    }

    ngOnInit() {
        // this.getClientSubscriptionExpireDate();
        // this.router.events
        //     // filter for NavigationStart events only
        //     .filter((event: RouterEvent) => event instanceof NavigationStart)
        //     .subscribe((event: NavigationStart) => {
        //         const url = event.url === '/' ? '' : event.url;
        //         const urlAndQuery = url.split('?');
        //         const pathMap = urlAndQuery[0].split('/');
        //         // first element is an empty string, second element of the path segments is the tenant
        //         const firstPathPart = pathMap[1];
        //         let session: AppSession = Application.GetSession();
        //         const cid = parseInt(firstPathPart);
        //         // a known tenant is in the url path (in case of a direct page load)
        //         if (cid > 0) {
        //             if(cid != session.ClientId) {
        //                 this.router.navigate(['login']);
        //             }
        //         } else {
        //             const redirectUrl = ('/' + session.ClientId + addPath(urlAndQuery) + addQuery(urlAndQuery)).replace(/\/\/+/g, '/');
        //             this.router.navigate([redirectUrl]);
        //         }
        //     });
    }

    getClientSubscriptionExpireDate() {
        this.showExpireMessage = false;
        this.subscriptionExpired = false;
        this.daysRemaining = -1;
        this.QuickStepPending = true;
        this._clientSubscription.getClientSubscriptionExpireDate().subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    this.QuickStepPending = data.Result.QuickStepPending;
                    if (!!data.Result.LastDate) {
                        this.daysRemaining = moment(data.Result.LastDate).diff(moment(), 'days')
                    } else {
                        this.subscriptionExpired = true;
                    }
                    this.showExpireMessage = this.subscriptionExpired || this.daysRemaining < 15;
                }
            },
            err => {
            },
            () => {
            }
        );
    }

    ngAfterViewInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loadingRouterOutlet = true;
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.loadingRouterOutlet = false;
                }
            });
    }
}
