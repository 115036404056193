import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { EnterpriseSelectComponent } from './enterprise/enterprise-select/enterprise-select.component';
import { EnterpriseSelectMultipleComponent } from './enterprise/enterprise-select-multiple/enterprise-select-multiple.component';
import { FacilitySelectComponent } from './facility/facility-select/facility-select.component';
import { FacilitySelectMultipleComponent } from './facility/facility-select-multiple/facility-select-multiple.component';
import { StructureService } from '@services/admin/structure.service';
import { FacilityService } from '@services/admin/facility.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultipleFacilitySelectComponent } from './facility/multiple-facility-select/multiple-facility-select.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgSelectModule,
        TranslateModule
    ],
    declarations: [
        EnterpriseSelectComponent,
        EnterpriseSelectMultipleComponent,
        FacilitySelectComponent,
        FacilitySelectMultipleComponent,
        MultipleFacilitySelectComponent
    ],
    providers: [
        StructureService,
        FacilityService
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSelectModule,
        NgMultiSelectDropDownModule,
        NgSelectModule,

        EnterpriseSelectComponent,
        EnterpriseSelectMultipleComponent,
        FacilitySelectComponent,
        FacilitySelectMultipleComponent,
        MultipleFacilitySelectComponent,

        TranslateModule
    ]
})
export class MultiselectStructureModule { }
