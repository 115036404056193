import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { Encryption } from '@utility/utility.encryption';
import { ApiUtility } from '@utility/utility.api-utility';

// Client Service
@Injectable()
export class ClientService {

  constructor(private http: HttpClient) { }

  // Get basic authorization header
  private getBasicAuth(username: string, password: string) {
    var headers: any = {};
    var keyLevel1 = Encryption.Encrypt(username + ":" + password);
    var keyLevel2 = Encryption.Encrypt(keyLevel1);

    headers.Authorization = 'Basic ' + keyLevel2;

    return {
      headers: new HttpHeaders(headers)
    }
  }

  // Get Client Role
  getClient(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client' + uri);
  }


  // getDbBackupLog
  getDbBackupLog() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/database-backup');
  }

  downloadDbBackup(dbBackupId, filename) {
    var api = 'client/database-backup/' + dbBackupId + '/download';
    ApiUtility.downloadFile(api, filename + '.bak');
  }


  // Register Client
  registerClient(client: any, username: string, password: string) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'client/register', client, this.getBasicAuth(username, password));
  }

  // Send Email Validation Request
  SendEmailValidationRequest(email: string) {
    let emailValidationData = {
      EmailId: email
    }
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'client/email-validation-request', emailValidationData);
  }

  // ValidateClientName
  ValidateClientName(companyName: string) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/company-name-validate?companyName='+ companyName);
  }

  // Send Email Validation Request
  ValidateEmail(emailValidationData: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'client/email-validate', emailValidationData);
  }

  // Edit Client
  editClient(clientId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/' + clientId);
  }

  // getClientAuditTrail
  getClientAuditTrail(clientId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/' + clientId + '/audit-trail');
  }

  // Update Client
  updateClient(client: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'client/' + client.ClientId, client);
  }

  // Update Client Logo
  updateClientLogo(attachment: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'client/', attachment);
  }


  // Get Company Information Details
  getContactDetail() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/companydetail');
  }


  // Get Sectors
  getSectors() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/sector');
  }
  // GetConfig_ContactUs
  GetConfig_ContactUs() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'admin-config/contact-us');
  }
}
