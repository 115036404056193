import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

@Injectable({
  providedIn: 'root'
})
export class ImPsmService {

  constructor(public http:HttpClient) { }

  //for psm elements master
  getPSMElements(page?:Pagination){
    let uri:any
    if(page){
      uri = page.GetUri();
    } 
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements' + (!!uri?uri:''));
  }

  editPSMElements(id){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements/' + id);
  }
  updatePSMElements(data){
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'psm-elements/' + data.PSMElementId,data);
  }
  deletePSMElements(id){
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'psm-elements/' + id);
  }
  addPSMElements(data){
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'psm-elements' ,data);
  }
  // getPSMElementsAuditTrail(id){
  //   return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements' + id + '/audit-trail');
  // }

  //for psm elements checklist master
  getPSMElementsChecklist(page?:Pagination){
    let uri:any
    if(page){
      uri = page.GetUri();
    } 
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist' + (!!uri?uri:''));
  }


  editPSMElementsChecklist(id){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist/' + id);
  }
  updatePSMElementsChecklist(data){
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist/' + data.PSMElementChecklistId,data);
  }
  deletePSMElementsChecklist(id){
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist/' + id);
  }
  addPSMElementsChecklist(data){
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist' ,data);
  }
  // getPSMElementsChecklistAuditTrail(id){
  //   return this.http.get<iApiResponse>(_$config.api_endpoint + 'psm-elements-checklist' + id + '/audit-trail');
  // }

  //for psm checklist
  getIMPSM(id:any){
    // let uri: string = page.GetUri();
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'im/psm-detail/'+id);
  }
  updateIMPSM(data){
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'im/psm-detail/' + data.IMPSMDetailId,data);
  }
  addIMPSM(data){
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'im/psm-detail' ,data);
  }
}
