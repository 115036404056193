import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { MenuService } from '@core/menu/menu.service';
import { SettingsService } from '@core/settings/settings.service';
import { SharedService } from '@services/shared.service';
import { MessageType } from '@app.enum';
import { mySetuMenuService } from '@services/admin/menu.service';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { EmployeeService } from '@services/admin/employee.service';
import { Subscription } from 'rxjs';
import { GenericDataService } from '@services/generic-data.service';
import { BBSService } from '@services/bbs/bbs.service';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

    menuItems: Array<any>;
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    menuGroups: any = {};
    dashboardItemsList: any = []
    currentUser: any;

    toggle: any = [];
    show: any;
    ModuleArr: any = []
    showMenuOption: any;
    private subscriptions = new Subscription();
    sidebarloaded: any;
    roleChangedActive: boolean;


    constructor(public menu: MenuService, private employeeService: EmployeeService, public settings: SettingsService, public injector: Injector, private _sharedService: SharedService, public _menu: mySetuMenuService, private route: Router, private _generic: GenericDataService
    ) {

        // var mySetuUi = Application.Get('mySetu-ui');
        // if (!!mySetuUi) {
        //     this.setMenu(mySetuUi);
        // } else {
        //     this.setMenu('home');
        // }

        this._sharedService.changeMenu.subscribe((data) => {
            // this.setMenu(data);
        });

        this.subscriptions.add(this._sharedService.LocationChanged.subscribe((res: any) => {
            if (res !== undefined && res != null && ((res == 'locationchanged' || res == 'rolechanged'))) {
                this._sharedService.setSubsSource(null);
                if (res == 'rolechanged') {
                    this.roleChangedActive = true;
                } else {
                    this.roleChangedActive = false;
                }
                this.setMenu('home');
                // this.getToken();
            }
        }));

        this.ModuleArr = [
            { ModuleName: "Administration", ModuleId: 2, ModulePath: 'administration' },
            { ModuleName: "Action Plan", ModuleId: 3, ModulePath: 'task' },
            { ModuleName: "SBO", ModuleId: 4, ModulePath: 'bbs' },
            { ModuleName: "CSA", ModuleId: 8, ModulePath: 'csa' },
            { ModuleName: "CAPA Management", ModuleId: 9, ModulePath: 'capa' },
            { ModuleName: "Walk Through Inspection", ModuleId: 13, ModulePath: 'wti' },
            { ModuleName: "Incident Management", ModuleId: 7, ModulePath: 'im' },
            { ModuleName: "Audit", ModuleId: 8, ModulePath: 'audits' },
            { ModuleName: "Violation Tracking System", ModuleId: 11, ModulePath: 'vts' },
            { ModuleName: "Internal Audit", ModuleId: 14, ModulePath: 'internal' },
            { ModuleName: "SPSA", ModuleId: 15, ModulePath: 'spsa' },
            { ModuleName: "External Audit", ModuleId: 16, ModulePath: 'external' },
            { ModuleName: "CSM", ModuleId: 10, ModulePath: 'csm' },
            { ModuleName: "CCI", ModuleId: 17, ModulePath: 'cci' },
            { ModuleName: "Sub Committee Dashboard", ModuleId: 18, ModulePath: 'scd' },
            { ModuleName: "EHSSHC", ModuleId: 5, ModulePath: 'ssps' },
            { ModuleName: "HHPS", ModuleId: 6, ModulePath: 'hhps' },
            { ModuleName: "MIS", ModuleId: 19, ModulePath: 'mis' },

        ]
        this._sharedService.setSubsSource(null);
        this.setMenu('home');
        // this.getToken();
        if (this.route.url) {
            let url = this.ModuleArr.find(x => this.route.url.includes(x.ModulePath))
            console.log('url:', this.route.url, url)
            if (!!url) {
                this.showMenuOption = url;
            }
        }
        // console.log(this.route.url)
    }

    change(value) {
        this.toggle = []
        this.toggle[value] = true;
    }

    ngOnInit() {

        this.router = this.injector.get(Router);

        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.settings.layout.asideToggled = false;
        });

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();

        this.show = false;

    }

    setMenu(menuName: string) {
        let session: AppSession = Application.GetSession();
        let key = menuName + '_' + session.UserId + '_' + session.ClientRoleId + '_' + session.LocationId + '_' + session.Ticks;
        if (!!this.menuGroups[key] && this.menuGroups[key].length > 0) {
            this.menuItems = this.menuGroups[key];
        } else {
            this._menu.getMenu(menuName).subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        this.menuItems = [];
                        if (!!data.Result) {
                            let wizard = data.Result.filter(x => x.text == "Wizard");
                            if (!wizard && wizard.length == 0) {
                                this.menuGroups[key] = this.menuItems;
                            }
                            this.menuItems = data.Result;
                            let value: any
                            this.dashboardItemsList = []
                            for (let index = 0; index < this.menuItems.length; index++) {
                                if ((this.menuItems[index]['text'] == 'Home') || this.menuItems[index]['text'] == 'Dashboard') {
                                    value = this.dashboardItemsList.filter(x => { return x.text == this.menuItems[index]['text'] && x.link == this.menuItems[index].link })
                                    if (value.length == 0) {
                                        this.dashboardItemsList.push(this.menuItems[index])
                                    }
                                }
                            }
                            localStorage.setItem("dashboardCustom", btoa(JSON.stringify(this.dashboardItemsList)));
                            for (let index = 0; index < this.menuItems.length; index++) {
                                if (this.menuItems[index]['submenu'] && this.menuItems[index]['submenu'].length == 0) {
                                    this.menuItems[index]['submenu'] = null
                                }
                            }
                        }
                        if (this.roleChangedActive) {
                            this._sharedService.setSubsSource("rolechangedashboard");
                        }
                        else {
                            this._sharedService.setSubsSource("dashboard");
                        }
                        this._generic.sidebarData(this.menuItems);
                    }
                },
                err => {
                },
                () => {
                });
        }

    }

    // getToken() {
    //     this._bbs.GetTokenFromThirdParty().subscribe((data: any) => {
    //         if (data && data.status == 'Success') {
    //             let token = data && data.data && data.data.token ? data.data.token : null;
    //             if (token) {
    //                 localStorage.setItem("B_token", btoa(JSON.stringify(token)));
    //             }
    //         }
    //     }, err => {
    //         console.log(err)
    //     })
    // }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.settings.layout.asideToggled = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.sbclickEvent);
    }

    toggleSubmenuClick(event) {
        if (event.target.innerText) {
            let obj = this.ModuleArr.find(x => x.ModuleName == event.target.innerText);
            if (!!obj) {
                this.showMenuOption = obj
            }
            console.log(this.showMenuOption)
        }
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            event.preventDefault();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a').first();
            }
            ul = anchor.next();

            // hide other submenus
            let parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                let $el = $(el);
                // if element is not a parent or self ul
                if (!$el.is(parentNav) && !$el.is(ul)) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            if (parseInt(ul.height(), 0)) {
                this.closeMenu(ul);
            }
            else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.height('auto').off('transitionend');
                }).height(ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    closeMenu(elem) {
        elem.height(elem[0].scrollHeight); // set height
        elem.height(0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        let self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;
            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a');
            }
            ul = anchor.next();

            if (!ul.length) {
                return; // if not submenu return
            }

            let $aside = $('.aside-container');
            let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            let $sidebar = $asideInner.children('.sidebar');
            let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            let floatingNav = ul.clone().appendTo($aside);
            let vwHeight = $(window).height();

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.settings.layout.isFixed ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function (e) {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    let routeTo = $(this).attr('route');
                    if (routeTo) self.router.navigate([routeTo]);
                });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        let $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside-container').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.settings.layout.isCollapsed;
    }
    isSidebarCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }
    isEnabledHover() {
        return this.settings.layout.asideHover;
    }
    //current user
    getCurrentUser() {
        this.employeeService.getProfile().subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    this.currentUser = data.Result;
                    localStorage.setItem("currentUser", btoa(JSON.stringify(this.currentUser)));
                }
            },
            err => {
                //Notify.ApiError(err, this.translate);
            },
            () => {
            });
    }
}


