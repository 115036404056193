import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageType } from '@app.enum';
import { AuthService } from '@services/admin/auth.service';
import { Notify } from '@utility/utility.notify';
import { Encryption } from '@utility/utility.encryption';
import { TranslateService } from '@ngx-translate/core';

 @Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    // initialization
    recoverForm: FormGroup;
    resetForm: FormGroup;
    isLoading: boolean;
    isHide: boolean;
    resetPasswordCode: any;
    displayMessage:any;
    showHide:boolean;

    constructor(
        public settings: SettingsService,
        fb: FormBuilder,
        private router: Router,
        private _auth: AuthService,
        private route: ActivatedRoute,
        private translate: TranslateService) {

        this.recoverForm = fb.group({
            // 'UserName': [null, Validators.compose([Validators.required, CustomValidators.email])]
            'UserName': [null, Validators.required]
        });

        this.resetForm = fb.group({
            // 'UserName': [null, Validators.compose([Validators.required, CustomValidators.email])]
            'Password': [null, Validators.required],
            'ConfirmPassword': [null, Validators.required]
        });

        this.route.params.subscribe(params => {
            this.resetPasswordCode = params.ResetPasswordCode;
        });
        if (this.resetPasswordCode) {
            this.isHide = true;
        } else {
            this.isHide = false;
        }
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.recoverForm.controls) {
            this.recoverForm.controls[c].markAsTouched();
        }
        if (this.recoverForm.valid) {
            this._auth.recoverPassword(value.UserName).subscribe(
                data => {
                    if (data) {
                        if (data.MessageType == MessageType.Success) {
                            var result = data.Result;
                            this.showHide = !this.showHide;
                            this.displayMessage = data.Message;
                        } else {
                            Notify.MessageByType(data.Message, data.MessageType);
                        }
                    }
                }, err => {
                    this.isLoading = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    this.isLoading = false;
                });
        } else {
            Notify.Warning('Invalid data');
        }
    }

    resetPassword($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.resetForm.controls) {
            this.resetForm.controls[c].markAsTouched();
        }
        value.Password = Encryption.Encrypt(value.Password);
        value.ConfirmPassword = Encryption.Encrypt(value.ConfirmPassword);
        value.ResetPasswordCode = this.resetPasswordCode;
        if (this.resetForm.valid) {
            this._auth.resetPassword(value).subscribe(
                data => {
                    if (data) {
                        if (data.MessageType == MessageType.Success) {
                            var result = data.Result;
                            Notify.Success(data.Message);
                            this.router.navigate(['login']);
                        }
                    } else {
                        Notify.Warning(data.Message);
                    }
                }, err => {
                    this.isLoading = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    this.isLoading = false;
                });
        } else {
            Notify.Warning('Invalid data');
        }
    }


    ngOnInit() {
        document.body.classList.add("setu-bg");
    }

    ngOnDestroy() {
        document.body.classList.remove("setu-bg");
    }

}
