import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'listfilter',
  pure: true
})

export class FilterPipe implements PipeTransform {

  transform(items: any[], key: any, value: any): any {
 
    if (typeof items != "undefined") {

      if (_.isArray(key)) {
        return _.filter(items, function (o) {
          var found = false;
          var obj = o;
         // console.log(key);
          for (let index = 0; index < key.length; index++) {
            const k = key[index];
            obj = obj[k];
          }
          if (typeof value === "undefined") {
            found = obj;
          }
          else {
            found = obj == value;
          }
          return found;
        });
      } else {
        if (typeof value === "undefined") {
         
          return items.filter(item => item[key]);
          
        }
        else {
       
       

      //   // for (let prop in value) {
      //   //   console.log("prop is " + value[prop]);
      //   //   var code = value[prop];
      //     if(key != null){
      //     const d = items.filter(item => item[key] );
      //     console.log(d);
      //     if(d.length > 0){
           
      //       return true;
      //     }
      //     else{
      //       return false;
      //     }
      //   }
      //   else{
      //     return false;
      //   }
      //  // }
          return items.filter(item => item[key] == value);
        }
      }
    } else {
      return [];
    }

  }
}