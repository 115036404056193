import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// IM Incident Classification Service
@Injectable()
export class IMIncidentClassificationService {

    constructor(private http: HttpClient) { }

    // Get IMTemplate
    getIMIncidentClassificationType(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-classification' + uri);
    }


    // Get IM Incident Classification Type
    getIncidentClassificationType(page: Pagination) {
        let uri: string = page.GetUri();
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-classification' + uri);
    }

    // Edit IM Incident Classification  Type
    editIMIncidentClassificationType(incidentClassificationTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-classification' + incidentClassificationTypeId);
    }

    // Add IM Incident Classification Type
    addIMIncidentClassificationType(incidentClassificationType: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'incident-classification', incidentClassificationType);
    }

    // Update Incident Classification type
    updateIncidentClassificationType(incidentClassificationType: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'incident-classification' + incidentClassificationType.incidentClassificationTypeId, incidentClassificationType);
    }

    // Delete Incident Classification Type
    deleteIncidentClassificationType(incidentClassificationTypeId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'incident-classification' + incidentClassificationTypeId);
    }

         // get Incident Classification Type Audit Trail
    getIncidentClassificationTypeAuditTrail(incidentClassificationTypeId: number) {
          return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-classification' + incidentClassificationTypeId + '/audit-trail');
      }
}
