import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, RouterStateSnapshot, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { mySetuMenuService } from '@services/admin/menu.service';
import { SharedService } from '@services/shared.service';
import { UserRole } from '@app.enum';
import { Encryption } from '@utility/utility.encryption';
import { IndexedDbService } from '../services/indexed-db.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    currentUser: AppSession;
    previousstate: any;
    triggerState: any;
    TriggerstateObj: any;
    constructor(private _router: Router, public _menu: mySetuMenuService, public _shared: SharedService, private dbservice: IndexedDbService) {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show progress spinner or progress bar
                this.triggerState = event.navigationTrigger;
                this.TriggerstateObj = event.restoredState;
                console.log(this.triggerState,this.TriggerstateObj);
                
            }
        });
     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.checkSession(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let access_token = Application.Get('access_token')
        let token2:any
        let token:any
        let expired :any;
        let _expirationMinutes=360;
        if(access_token){
            try{
                token = atob(access_token);
            }
            catch(e){
                console.log(token)
            }
             if(!!token && token.includes('')){
                token2 = token.split("");
                token2= JSON.parse(token2[1]);
             }
        }
        if(!!token2){
            const epochOffset = 621355968000000000;
            const ticksPerMillisecond = 10000;
            const datetime = (token2.Ticks - epochOffset) / ticksPerMillisecond ;
            let datetimeobj = new Date(datetime);
            let DifferenceinTwoDates = new Date().getTime() - new Date(datetimeobj).getTime();
            let diffInMinutes = Math.floor((DifferenceinTwoDates/1000)/60);
             expired = diffInMinutes > _expirationMinutes;
            console.log(expired)
        }

        if((expired && !!access_token) || (!!access_token && !token2)  ){
            Application.ClearSession();
            this.dbservice.clearStore();
            this._router.navigate(['login']);
            return false;
        }
        else{ 
            if (this.triggerState && ( this.triggerState == 'popstate') && this.TriggerstateObj == null){
                this.triggerState = null;
                Application.ClearSession();
                this.dbservice.clearStore();
                this._router.navigate(['login']);
                return false;
            }else{
                return this.checkSession(route, state);
            }
        }
    }

    private login(route) {
        let param;
        if (!!route) {
            let _routerState = route['_routerState'];
            if (!!_routerState) {
                let url = _routerState['url'];
                if (!!url) {
                    param = Encryption.SetQueryParam(url);
                }
            }
        }
        if (!!param) {
            this._router.navigate(['login'], { queryParams: { p: param } });
        } else {
            this._router.navigate(['login']);
        }
    }

    private checkSession(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._shared.changeRoute(route.data.menu);
            if (!!route && !!route.data && !!route.data.code) {
                this.currentUser = Application.GetSession();
                if (!this.currentUser) {
                    resolve(false);
                    this.login(route);
                } else {
                    this._shared.rbac = {
                        Checking: false,
                        CanAccess: true,
                        CanAdd: true,
                        CanModify: true,
                        CanView: true,
                        CanDelete: false
                    }
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
    }
    private checkSession_OLD(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            this._shared.changeRoute(route.data.menu);

            if (!!route && !!route.data && !!route.data.code) {
                this.currentUser = Application.GetSession();
                var idle = sessionStorage.getItem('idle-' + _$config.tabid);
                if (!this.currentUser) {
                    resolve(false);
                    this.login(route);
                }
                else if (idle == "-1") {
                    resolve(false);
                    this._router.navigate(['lock']);
                }
                else {

                    var force_password_reset = Application.Get("force_password_reset");

                    var clientId = this.currentUser.ClientId;
                    if (!clientId) {
                        var _clientIdString = Application.Get("ClientId");

                        if (!!_clientIdString) {
                            clientId = parseInt(_clientIdString);
                        }
                    }

                    if (this.currentUser.UserRoleId == UserRole.SuperAdmin && !route.data['isSuperAdmin'] && !clientId) {
                        resolve(false);
                        this._router.navigate(['mySetu', 'admin', 'dashboard']);
                    } else if (this.currentUser.UserRoleId != UserRole.SuperAdmin && !!route.data['isSuperAdmin'] && !!clientId) {
                        resolve(false);
                        this._router.navigate(['error', '403']);
                    } else if (
                        this.currentUser.UserRoleId != UserRole.SuperAdmin
                        && !!route.data.code
                        && route.data.code == 'COMPANY'
                    ) {
                        resolve(false);
                        this._router.navigate(['error', '403']);
                    } else if (
                        route.data.code != ''
                        && !(
                            route.data.code == '#home-module'
                            || route.data.code == '#admin-module'
                            || route.data.code == '#task-module'
                            || route.data.code == '#bbs-module'
                            || route.data.code == '#ssps-module'
                            || route.data.code == '#hhps-module'
                            || route.data.code == '#im-module'
                            || route.data.code == '#ui-module')
                        && route.data.code != 'CHG_PWD'
                        && !!force_password_reset
                        && force_password_reset == 'true'
                    ) {
                        resolve(false);
                        this._router.navigate(['administration', 'change-password']);
                    } else if (
                        !_$config.enableRBAC
                        || route.data.code == ''
                        || route.data.code[0] == '#'
                        || route.data.code == 'QUICK_START'
                        || route.data.code == 'CHG_PWD'
                        || route.data.code == 'WORK_IN_PROGRESS'
                        || route.data.code == 'SEARCH'
                        || route.data.code == 'COMP_INFO'

                        || (
                            this.currentUser.UserRoleId != UserRole.Client
                            &&
                            (route.data.code == 'SUBSCR'
                                || route.data.code == 'TRANS'
                                || route.data.code == 'PMNT_CONFIG')
                        )

                    ) {
                        resolve(true);
                    } else {
                        // Set default values
                        this._shared.rbac.Checking = true;
                        this._shared.rbac.CanAccess = false;
                        this._menu.getRBAC(route.data.code).subscribe(
                            data => {

                                this._shared.rbac = data.Result;
                                // if (data.Result.AllowedByQuickStep) {
                                //     resolve(true);
                                // } else if (!data.Result.CanAccess) {
                                //     resolve(false);
                                //     this._router.navigate(['error', '403']);
                                // } else if (data.Result.AllowedByQuickStep) {
                                //     resolve(true);
                                // }

                                if (data.Result.CanAccess) {
                                    if (data.Result.AllowedByQuickStep) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                        this._router.navigate(['home', 'wizard']);
                                    }
                                } else {
                                    resolve(false);
                                    this._router.navigate(['error', '403']);
                                }
                            },
                            err => {
                                resolve(false);
                                //this._router.navigate(['login']);
                                this.login(route);
                            },
                            () => {
                            });
                    }
                }
            } else {
                resolve(true);
            }
        });
    }
}
