import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { Application } from '@utility/utility.application';

 @Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    TimezoneId = "";
    constructor(public settings: SettingsService) { }

    ngOnInit() {
        this.TimezoneId = Application.Get("TimezoneId");
        if(!this.TimezoneId) {
            this.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
        }
    }
}
