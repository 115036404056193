import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageType, WindowsServiceStatus, Recurring, ScheduleType } from '@app.enum';
import { PrivateService } from '@services/admin//private.service';
import { Notify } from '@utility/utility.notify';
const _clone = (d) => JSON.parse(JSON.stringify(d));
const DaysOfWeek = { Sunday: false, Monday: false, Tuesday: false, Wednesday: false, Thursday: false, Friday: false, Saturday: false };
const WeekNumber = { FirstWeek: false, SecondWeek: true, ThirdWeek: false, FourthWeek: false, LastWeek: false };
const Month = { January: false, February: false, March: false, April: false, May: false, June: false, July: false, August: false, September: false, October: false, November: false, December: false };
const DaysOfMonth = { _1: false, _2: false, _3: false, _4: false, _5: false, _6: false, _7: false, _8: false, _9: false, _10: false, _11: false, _12: false, _13: false, _14: false, _15: false, _16: false, _17: false, _18: false, _19: false, _20: false, _21: false, _22: false, _23: false, _24: false, _25: false, _26: false, _27: false, _28: false, _29: false, _30: false, _31: false, LastDay: false };

 @Component({
  selector: 'app-task-scheduler',
  templateUrl: './task-scheduler.component.html',
  styleUrls: ['./task-scheduler.component.scss']
})
export class TaskSchedulerComponent implements OnInit {

  // Enum reference form HTML
  get WindowsServiceStatus() { return WindowsServiceStatus; }
  get ScheduleType() { return ScheduleType; }

  // Variable declaration
  triggerName: String = "";
  dateFormat: string = _$config.dateFormat;

  minStartDate: Date = new Date();
  startDateConfig = Object.assign(_clone(_$config.datepickerConfig), { minDate: this.minStartDate });

  minEndDate: Date = new Date();
  endDateConfig = Object.assign(_clone(_$config.datepickerConfig), { minDate: this.minEndDate });

  minOntimeDate: Date = new Date();
  onetimeDateConfig = Object.assign(_clone(_$config.datepickerConfig), { minDate: this.minOntimeDate });

  recurrings: Array<any> = [];

  isLoading: boolean = false;
  isSaving: boolean = false;
  isLoggedIn: boolean = false;
  serviceStatus: WindowsServiceStatus = WindowsServiceStatus.Uninstall;
  triggers: Array<any> = [];
  editIndex: number = -1;

  // Form
  loginFrm: FormGroup;
  triggerFrm: FormGroup;

  // TIMEPICKER PROPS
  public hstep: number = 1;
  public mstep: number = 1;
  public ismeridian: boolean = false;
  public isEnabled: boolean = true;
  public options: any = {
    hstep: [1, 2, 3],
    mstep: [1, 2, 3, 4, 5, 6]
  };

  // Constructor
  constructor(
    private _privateapi: PrivateService,
    public fb: FormBuilder,
    private translate: TranslateService) { }


  // Initilization
  ngOnInit() {
    this.initForm();
  }

  // Create form
  initForm() {
    // Login Form
    this.loginFrm = this.fb.group({
      Password: [null, Validators.required]
    });

    // Trigger form
    this.triggerFrm = this.fb.group({
      Tag: [null, Validators.required],
      StartDate: [new Date(), Validators.required],
      EndDate: [new Date(), Validators.required],
      TriggerTime: [new Date(), Validators.required],
      Enabled: [true],
      Recurring: [Recurring.None, Validators.required],
      ScheduleType: [ScheduleType.OneTimeOnly, Validators.required],
      // Trigger Settings
      TriggerSettings: this.fb.group({
        // One Time Only
        OneTimeOnly: this.fb.group({
          Active: [false],
          Date: [new Date()]
        }),

        // Daily
        Daily: this.fb.group({
          Interval: [0]
        }),

        // Weekly
        Weekly: this.fb.group({
          DaysOfWeek: [[
            DaysOfWeek.Sunday,
            DaysOfWeek.Monday,
            DaysOfWeek.Tuesday,
            DaysOfWeek.Wednesday,
            DaysOfWeek.Thursday,
            DaysOfWeek.Friday,
            DaysOfWeek.Saturday]]
        }),

        // Monthly
        Monthly: this.fb.group({
          Month: [[
            Month.January,
            Month.February,
            Month.March,
            Month.April,
            Month.May,
            Month.June,
            Month.July,
            Month.August,
            Month.September,
            Month.October,
            Month.November,
            Month.December]],
          DaysOfMonth: [[
            DaysOfMonth._1,
            DaysOfMonth._2,
            DaysOfMonth._3,
            DaysOfMonth._4,
            DaysOfMonth._5,
            DaysOfMonth._6,
            DaysOfMonth._7,
            DaysOfMonth._8,
            DaysOfMonth._9,
            DaysOfMonth._10,
            DaysOfMonth._11,
            DaysOfMonth._12,
            DaysOfMonth._13,
            DaysOfMonth._14,
            DaysOfMonth._15,
            DaysOfMonth._16,
            DaysOfMonth._17,
            DaysOfMonth._18,
            DaysOfMonth._19,
            DaysOfMonth._20,
            DaysOfMonth._21,
            DaysOfMonth._22,
            DaysOfMonth._23,
            DaysOfMonth._24,
            DaysOfMonth._25,
            DaysOfMonth._26,
            DaysOfMonth._27,
            DaysOfMonth._28,
            DaysOfMonth._29,
            DaysOfMonth._30,
            DaysOfMonth._31,
            DaysOfMonth.LastDay]],
          WeekDay: this.fb.group({
            WeekNumber: [[
              WeekNumber.FirstWeek,
              WeekNumber.SecondWeek,
              WeekNumber.ThirdWeek,
              WeekNumber.FourthWeek,
              WeekNumber.LastWeek]],
            DayOfWeek: [[
              DaysOfWeek.Sunday,
              DaysOfWeek.Monday,
              DaysOfWeek.Tuesday,
              DaysOfWeek.Wednesday,
              DaysOfWeek.Thursday,
              DaysOfWeek.Friday,
              DaysOfWeek.Saturday]]
          })
        })
      })
    });
  }

  // Login
  login($ev, loginData: any) {
    if (this.loginFrm.valid) {
      this.isLoading = true;
      this._privateapi.validateUser(loginData).subscribe(
        data => {
          this.isLoading = false;
          if (data.MessageType == MessageType.Success) {
            this.isLoggedIn = data.Result;
            if (this.isLoggedIn) {
              this.setRecurrings();
              this.getServiceStatus();
              this.getSchedulerItem(true);
              this.loginFrm.markAsUntouched();
              this.loginFrm.markAsPristine();
            } else {
              Notify.Warning('Invalid password!');
            }
          } else {
            Notify.MessageByType(data.Message, data.MessageType);
          }
        },
        err => {
          this.isLoading = false;
          Notify.ApiError_Normal(err, this.translate);
        },
        () => {
          this.isLoading = false;
        });
    }
  }

  // Logout
  logout() {
    this.isLoggedIn = false;
    this.loginFrm.reset();
  }

  // Set tab
  setTab(tab: ScheduleType) {
    this.triggerFrm.patchValue({ ScheduleType: tab });
  }

  // Save
  save($ev, trigger: any) {
    $ev.preventDefault();
    for (let c in this.triggerFrm.controls) {
      this.triggerFrm.controls[c].markAsTouched();
    }
    if (this.triggerFrm.valid) {
      if (this.editIndex >= 0) {
        this.triggers[this.editIndex] = trigger;
      } else {
        this.triggers.push(trigger);
      }
      this.isSaving = true;
      var triggers = _clone(this.triggers);

      for (let index = 0; index < triggers.length; index++) {
        triggers[index].StartDate = moment(triggers[index].StartDate).format("YYYY-MMM-DD");
        triggers[index].EndDate = moment(triggers[index].EndDate).format("YYYY-MMM-DD");
        triggers[index].TriggerTime = moment(triggers[index].TriggerTime).format("YYYY-MMM-DD HH:mm:ss");
        triggers[index].TriggerSettings.OneTimeOnly.Date = moment(triggers[index].TriggerSettings.OneTimeOnly.Date).format("YYYY-MMM-DD");
      }


      this._privateapi.updateSchedulerItem(triggers).subscribe(
        data => {
          this.isSaving = false;
          Notify.MessageByType(data.Message, data.MessageType);
          if (data.MessageType == MessageType.Success) {
            this.getServiceStatus();
            this.getSchedulerItem(false);
            this.triggerFrm.markAsUntouched();
            this.triggerFrm.markAsPristine();
          }
        },
        err => {
          this.isSaving = false;
          Notify.ApiError_Normal(err, this.translate);
        },
        () => {
          this.isSaving = false;
        });
    }
  }

  // Set Recurrings
  setRecurrings() {
    this.recurrings = [
      { Id: Recurring.None, Name: "None" },
      { Id: Recurring.EveryMinute, Name: "Every Minute" },
      { Id: Recurring.Every5Minute, Name: "Every 5 Minute" },
      { Id: Recurring.Every10Minute, Name: "Every 10 Minute" },
      { Id: Recurring.Every15Minute, Name: "Every 15 Minute" },
      { Id: Recurring.Every20Minute, Name: "Every 20 Minute" },
      { Id: Recurring.Every30Minute, Name: "Every 30 Minute" },
      { Id: Recurring.Every45Minute, Name: "Every 45 Minute" },
      { Id: Recurring.EveryHour, Name: "Every Hour" },
      { Id: Recurring.Every2Hour, Name: "Every 2 Hour" },
      { Id: Recurring.Every3Hour, Name: "Every 3 Hour" },
      { Id: Recurring.Every4Hour, Name: "Every 4 Hour" },
      { Id: Recurring.Every6Hour, Name: "Every 6 Hour" }
    ];
  }

  // Edit Trigger
  editTrigger(trigger, editIndex) {

    this.editIndex = editIndex;
    this.triggerName = _clone(trigger.Tag);

    var triggerObj = _clone(trigger);
    triggerObj.StartDate = moment(triggerObj.StartDate).toDate();
    triggerObj.EndDate = moment(triggerObj.EndDate).toDate();
    triggerObj.TriggerSettings.OneTimeOnly.Date = moment(triggerObj.TriggerSettings.OneTimeOnly.Date).toDate();
    this.triggerFrm.patchValue(triggerObj);
  }

  // Get Windows Service Status
  getServiceStatus() {
    this.isLoading = true;
    this._privateapi.getWindowsServiceStatus().subscribe(
      data => {
        if (data.MessageType == MessageType.Success) {
          this.serviceStatus = data.Result.Status;
        } else {
          this.isLoading = false;
          Notify.Warning(data.Message);
        }
      },
      err => {
        this.isLoading = false;
        Notify.ApiError_Normal(err, this.translate);
      },
      () => {
        this.isLoading = false;
      });
  }

  // Change Windows Service Status
  changeServiceStatus(status: WindowsServiceStatus) {
    this.isLoading = true;
    this._privateapi.changeWindowsServiceStatus(status).subscribe(
      data => {
        if (data.MessageType == MessageType.Success) {
          this.serviceStatus = data.Result.Status;
        } else {
          this.isLoading = false;
          Notify.Warning(data.Message);
        }
      },
      err => {
        this.isLoading = false;
        Notify.ApiError_Normal(err, this.translate);
      },
      () => {
        this.isLoading = false;
      });
  }

  // Get Scheduled triggers
  getSchedulerItem(selectFirstTrigger: boolean) {
    this.isLoading = true;
    this._privateapi.getSchedulerItem().subscribe(
      data => {
        if (data.MessageType == MessageType.Success) {
          this.triggers = data.Result;
          if (selectFirstTrigger && !!this.triggers && this.triggers.length > 0) {
            this.editTrigger(this.triggers[0], 0);
          }
        } else {
          this.isLoading = false;
          Notify.Warning(data.Message);
        }
      },
      err => {
        this.isLoading = false;
        Notify.ApiError_Normal(err, this.translate);
      },
      () => {
        this.isLoading = false;
      });
  }


  onStartDateChange(value: Date) {
    // this.endDateConfig.minDate = value;
    // this.endDatePicker.setConfig();
    // if (this.taskForm.controls['EndDate'].value < value) {
    //   this.taskForm.patchValue({ EndDate: value });
    // }
  }


  onEndDateChange(value: Date) {
    // this.endDateConfig.minDate = value;
    // this.endDatePicker.setConfig();
    // if (this.taskForm.controls['EndDate'].value < value) {
    //   this.taskForm.patchValue({ EndDate: value });
    // }
  }

  onOnetimeDateChange(value: Date) {
    // this.endDateConfig.minDate = value;
    // this.endDatePicker.setConfig();
    // if (this.taskForm.controls['EndDate'].value < value) {
    //   this.taskForm.patchValue({ EndDate: value });
    // }
  }

}
