import { Component, OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ClientService } from '@services/admin/client.service';
import { Notify } from '@utility/utility.notify';
import { MessageType } from '@app.enum';
import { Router, ActivatedRoute } from '../../../../../node_modules/@angular/router';
import { Application } from '@utility/utility.application';
import { AuthService } from '@services/admin/auth.service';
import { ValidateContactNumber } from '../../../shared/validators/contact-number.validator';
import { CountryService } from '@services/admin/country.service';
import { Pagination } from '@interface/interface.pagination';
import { EnquiryService } from '@services/admin/enquiry.service';
import { TranslateService } from '@ngx-translate/core';

const _clone = (d) => JSON.parse(JSON.stringify(d));

 @Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit, OnDestroy {

    // Variable declaration
    PackageId: number = 0;
    upload:any;
    showCode:any;
    disabled:any;

    // Form
    valForm: FormGroup;
    passwordForm: FormGroup;
    regClient: any;
    emailVerifications: Array<any> = [];
    emailVerificationPending: boolean = true;
    email: string = "";
    oneTimePassword: string = "";
    verificationInProgress = false;
    invalidCodeMessage = "";

    enquiryForm: FormGroup;
    // activity state
    isLoading: boolean;
    isSaving: boolean;
    isSavingEnquiry: boolean;
    Recaptcha: FormControl = new FormControl(false);

    public country: Array<any> = [];
    public sectors: Array<any> = [];
    public AdminConfig:any;

    constructor(
        public settings: SettingsService,
        public _registerClient: ClientService,
        fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _auth: AuthService,
        private _country: CountryService,
        private _enquiry: EnquiryService,
        private translate: TranslateService
        ) {

        let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            Password: password,
            confirmPassword: certainPassword
        });

        this.valForm = fb.group({
            CompanyName: [null, Validators.required],
            Email: [null, Validators.compose([Validators.required, CustomValidators.email])],
            PhoneNo: [null, Validators.compose([Validators.required,ValidateContactNumber()])],
            IsSubscribe: [null],
            passwordGroup: this.passwordForm
        });

        this.enquiryForm = fb.group({
            CompanyName: [null, Validators.required],
            ContactPersonName: [null, Validators.required],
            CountryId: [null, Validators.required],
            SectorIds: [null, Validators.required],
            Website: [null, Validators.required],
            Email: [null, Validators.compose([Validators.required, CustomValidators.email])],
            PhoneNo: [null, Validators.compose([Validators.required,ValidateContactNumber()])],
            Designation: [null, Validators.required],
            Comment: [null, Validators.required],
            IsSubscribe: [false, Validators.required],
            EnquiryTypeId : [1],
            ClientId : [null],
            UserId : [null],
            PackagePriceId : [null]
        });

        this.route.params.subscribe(params => {
            this.PackageId = !!params.PackageId ? params.PackageId : 0;
        });
    }

    ngOnInit() {
        document.body.classList.add("setu-bg");
        this.initForm();
        this.getCountry();
        this.getSectors();
        this.GetConfig_ContactUs();
    }

    getCountry() {
        let page = new Pagination();
        this._country.getCountry(page).subscribe(
        data => {
            if (data.MessageType == MessageType.Success) {
                this.country = _clone(data.Result);
            }
        },
        err => {
        },
        () => {
        });
    }

    getSectors() {
        this._registerClient.getSectors().subscribe(
        data => {
            if (data.MessageType == MessageType.Success) {
            this.sectors = _clone(data.Result);
            }
        },
        err => {
        },
        () => {
        });
    }

    GetConfig_ContactUs() {
        this._registerClient.GetConfig_ContactUs().subscribe(
        data => {
            if (data.MessageType == MessageType.Success) {
            this.AdminConfig = _clone(data.Result);
            }
        },
        err => {
        },
        () => {
        });
    }

    SetEmailVerification() {
        if (this.valForm.controls.Email.valid) {
            this.email = this.valForm.controls.Email.value;
            let verifiedEmails = this.emailVerifications.filter(x => x.Email == this.valForm.controls.Email.value && x.IsVerified);
            if (!!verifiedEmails && verifiedEmails.length > 0) {
                this.emailVerificationPending = false;
            } else {
                this.emailVerificationPending = true;
                this.AddEmailForVerification(this.email);
            }
        }
    }

    AddEmailForVerification(email) {
        let verifiedEmails = this.emailVerifications.filter(x => x.Email == email);
        if (!verifiedEmails || verifiedEmails.length == 0) {
            this.emailVerifications.push({
                Email: email,
                IsVerified: false
            });
            this.SendVerificationCode();
        }
    }

    SendVerificationCode() {
        if (!this.verificationInProgress) {
            this.oneTimePassword = '';
            this.invalidCodeMessage = '';
            this._registerClient.SendEmailValidationRequest(this.email).subscribe(
                data => {
                },
                err => {
                },
                () => {
                });
        }
    }

    closePopup(emailVerificationPopup){
        this.verificationInProgress = false;
        this.oneTimePassword = '';
        this.invalidCodeMessage = '';
        emailVerificationPopup.hide();
    }

    OpenEmailVerificationPopup(emailVerificationPopup){
        emailVerificationPopup.show();
    }

    VerifyEmail(emailVerificationPopup) {

        if (!this.verificationInProgress && !!this.oneTimePassword) {
            let emailValidationData = {
                EmailId: this.email,
                OneTimePassword: this.oneTimePassword
            }
            this.verificationInProgress = true;

            this._registerClient.ValidateEmail(emailValidationData).subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        emailVerificationPopup.hide();
                        this.MarkEmailAsVerified();
                    } else {
                        this.invalidCodeMessage = "Invalid Code";
                    }
                    this.verificationInProgress = false;
                },
                err => {
                    this.verificationInProgress = false;
                },
                () => {
                });
        }
    }

    MarkEmailAsVerified() {
        for (let index = 0; index < this.emailVerifications.length; index++) {
            if (this.emailVerifications[index].Email == this.email) {
                this.emailVerifications[index].IsVerified = true;
            }
        }
        this.emailVerificationPending = false;
    }

    ngOnDestroy() {
        document.body.classList.remove("setu-bg");
    }
    // Initialize Form
    initForm() {

        this.regClient = this.getDefaultRegisterData();
    }

    onScriptLoad() {
        //console.log('Google reCAPTCHA loaded and is ready for use!')
    }

    onScriptError() {
        //console.log('Something went long when loading the Google reCAPTCHA')
    }

    // Get Default data
    getDefaultRegisterData() {
        return {
            CompanyName: '',
            Email: '',
            Password: '',
            confirmPassword: '',
            IsSubscribe: false
        };
    }

    submitForm($ev, value: any, emailVerificationPopup, enquiryPopup) {
        if (!this.Recaptcha.value) {
            Notify.Warning('Please enter CAPTCHA');
            return;
        }

        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        for (let c in this.passwordForm.controls) {
            this.passwordForm.controls[c].markAsTouched();
        }

        if(this.valForm.valid && this.emailVerificationPending){
            emailVerificationPopup.show();
            return;
        }

        if (this.valForm.valid) {
            this.isSaving = true;
            this.isLoading = true;
            var client = _clone(value);
            delete client.passwordGroup;
            if (this.PackageId > 0) {
                client["PackageId"] = this.PackageId;
            } else {
                client["PackageId"] = null;
            }

            let service = this._registerClient.registerClient(client, value.Email, value.passwordGroup.Password);

            service.subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        Notify.Success('Account created successfully');
                        this.login(value.Email, value.passwordGroup.Password);

                        this.valForm.markAsUntouched();
                        this.valForm.markAsPristine();
                    } else if (data.MessageType == MessageType.Warning && data.Result.CompanyNameAlreadyExists) {

                     this.isSaving = false;
                     this.enquiryForm.patchValue({
                            CompanyName : client.CompanyName,
                            Email: client.Email,
                            PhoneNo:client.PhoneNo
                        });
                        enquiryPopup.show();
                    } else {
                        this.isSaving = false;
                        Notify.MessageByType(data.Message, data.MessageType);
                    }
                },
                err => {
                    this.isSaving = false;
                    this.isLoading = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    this.isSaving = false;
                    this.isLoading = false;
                });
        }
    }

    submitEnquiryForm($ev, value: any, enquiryPopup) {
        $ev.preventDefault();
        for (let c in this.enquiryForm.controls) {
            this.enquiryForm.controls[c].markAsTouched();
        }

        if (this.enquiryForm.valid) {
            this.isSavingEnquiry = true;
            var enquiry = _clone(value);

            let service = this._enquiry.AddEnquiry(enquiry);

            service.subscribe(
                data => {
                        this.isSavingEnquiry = false;
                    if (data.MessageType == MessageType.Success) {
                        Notify.Success('Form submitted successfully, we will get back to you soon.');

                        this.enquiryForm.markAsUntouched();
                        this.enquiryForm.markAsPristine();

                        enquiryPopup.hide();
                    } else {
                        Notify.MessageByType(data.Message, data.MessageType);
                    }
                },
                err => {
                    this.isSavingEnquiry = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    this.isSavingEnquiry = false;
                });
        }
    }

    ValidateCompanyName(enquiryPopup) {
        let companyName = this.valForm.controls['CompanyName'].value;
        this._registerClient.ValidateClientName(companyName).subscribe(
            data => {
                if (data.MessageType == MessageType.Warning && data.Result.CompanyNameAlreadyExists) {

                    this.isSaving = false;
                    this.enquiryForm.patchValue({
                           CompanyName : this.valForm.controls['CompanyName'].value,
                           Email: this.valForm.controls['Email'].value,
                           PhoneNo:this.valForm.controls['PhoneNo'].value
                       });
                       enquiryPopup.show();
                   }
            },
            err => {
            },
            () => {
            });
    }

    login(UserName: string, Password: string) {

        this.isLoading = true;
        this._auth.getAccessToken(UserName, Password).subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {

                    var result = data.Result;
                    Application.SetSession(result.access_token, result.session);
                    sessionStorage.clear();
                    // if (!result.IsPackagePurchased) {
                    //     Application.Set('got-to-cart', 'yes');
                    //     Application.Set('p-cart', '[' + result.PackageId + ']');
                    //     this.router.navigate(result.redirect.split(','));
                    // } else
                    if (result.quick_start) {
                        //this.router.navigate(['home', 'quick-start']);
                        this.router.navigate(['home', 'wizard']);
                    } else if (!result.subscriptions || result.subscriptions.length == 0) {
                        this.router.navigate(['administration', 'package']);
                    } else {
                        var hash = Application.Get("redirect");
                        if (!!hash && hash != "login") {
                            var navigation = hash.split('/');
                            Application.Remove("redirect");
                            this.router.navigate(navigation);
                        } else {
                            this.router.navigate(['home']);
                        }
                    }
                } else {
                    this.isLoading = false;
                    Notify.Warning(data.Message);
                }
            },
            err => {
                this.isLoading = false;
                Notify.ApiError_Normal(err, this.translate);
            },
            () => {
                this.isLoading = false;
            });
    }

    refreshValue(data){
    }

    selected(data){

    }
    removed(data){

    }
    typed(data){

    }
    onSelectCountry(data){

    }
}


