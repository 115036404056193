import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Contractor Service
@Injectable()
export class ContractorService {

  constructor(private http: HttpClient) { }

  // Get Contractors
  getContractors(page: Pagination = null,search?:any,selectedCh?:any) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'contractor' + uri +(!!selectedCh ? '&Character=' + selectedCh : '')+ (!!search ? '&Search=' + search : ''));
  }

  // Edit Contractor
  editContractor(contractorId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'contractor/' + contractorId);
  }

  // getContractorAuditTrail
  getContractorAuditTrail(contractorId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'contractor/' + contractorId + '/audit-trail');
  }

  // Add Contractor
  addContractor(contractor: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'contractor', contractor);
  }

  // Update Contractor
  updateContractor(contractor: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'contractor/' + contractor.ContractorId, contractor);
  }

  // Update Contractor
  updateContractorStatus(contractorId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'contractor/' + contractorId + '/status?isActive=' + isActive, null);
  }

  // Delete Contractor
  deleteContractor(contractorId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'contractor/' + contractorId);
  }

  // import file
  importFile(fileToUpload: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http
      .post<iApiResponse>(_$config.api_endpoint + 'contractor/import', input);
  }

  downloadContractorReport(format) {
    var api = 'contractor/Report/' + format;
    ApiUtility.downloadFile(api, 'ContractorList.' + format);
  }

  SampleFormatContractorDownload() {
    var api = 'contractor/Sample'
    ApiUtility.downloadFile(api, 'SampleFormatContractorList.xlsx');
  }

}
