import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Facility Service
@Injectable()
export class FacilityService {

  constructor(private http: HttpClient) { }

  // Get Structures
  getStructures(locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility' + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // Get Structures
  getStructureAndData(layout: string = 'normal', locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/data?layout=' + layout + (!!locationId ? '&locationId=' + locationId : ''));
  }

  downloadFacilityReport(level, format, structure) {
    var api = 'facility/level/Report/' + level + "/" + format;
    ApiUtility.downloadFile(api, structure + " Master Report." + format);
  }

  // Edit Structure
  editStructure(facilityStructureId: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/' + facilityStructureId + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // Edit Structure by level
  editStructureLevel(facilityLevel: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/' + facilityLevel + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // Get Structure data
  getStructureData(facilityStructureId: number, parentId: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/' + facilityStructureId + '/data?parentId=' + parentId + (!!locationId ? '&locationId=' + locationId : ''));
  }

  // Get Parent data
  getParentData(facilityMasterId: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/data/parents/' + facilityMasterId + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // Get Structure data by level
  getStructureLevelData(page: Pagination, facilityLevel: number, parentId: number,search:any,selectedCh:any, locationId = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri(['parentId=' + parentId]);
    }else {
      uri = '?parentId=' + parentId;
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/' + facilityLevel + '/data' + uri +'&Character='+selectedCh+'&Search='+search+ (!!locationId ? '&locationId=' + locationId : ''));
    //this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/' + facilityLevel + '/data' + uri +'&CurrentPage='+page.CurrentPage+'&PageSize='+page.PageSize+'&OrderBy=FacilityName&Descending=false'+'&StartWith=i'+'&Search=C' + (!!locationId ? '&locationId=' + locationId : ''));
  }
  

  // Edit Structure data
  editStructureData(facilityStructureId: number, facilityMasterId: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/' + facilityStructureId + '/data/' + facilityMasterId + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // Eet Structure data by level
  editStructureLevelData(facilityLevel: number, facilityMasterId: number, locationId = null) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/' + facilityLevel + '/data/' + facilityMasterId + (!!locationId ? '?locationId=' + locationId : ''));
  }

  // getStructureLevelDataAuditTrail
  getStructureLevelDataAuditTrail(structureLevel: number, facilityMasterId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'facility/level/' + structureLevel + '/data/' + facilityMasterId + '/audit-trail');
  }

  // Add Structure
  addStructure(structure: any, locationId = null) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'facility' + (!!locationId ? '?locationId=' + locationId : ''), structure);
    //return this.http.post<iApiResponse>(_$config.api_endpoint + 'facility', structure));
  }

  // Update Structures
  updateStructure(structure: any, locationId = null) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'facility/' + structure.FacilityStructureId + (!!locationId ? '?locationId=' + locationId : ''), structure);
  }

  // Add Structure data
  addStructureData(structureData: any, locationId = null) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'facility/' + structureData.FacilityStructureId + '/data' + (!!locationId ? '?locationId=' + locationId : ''), structureData);
  }

  // Update Structure data
  updateStructureData(structureData: any, locationId = null) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'facility/' + structureData.FacilityStructureId + '/data/' + structureData.FacilityMasterId + (!!locationId ? '?locationId=' + locationId : ''), structureData);
  }

  // Delete Structure Data
  deleteStructureData(facilityMasterId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'facility/' + facilityMasterId);
  }

  importFile(fileToUpload: any, level: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http.post<iApiResponse>(_$config.api_endpoint + 'facility/import/' + level, input);
  }

  SampleFormatFacilityDownload(level: any, structure: string) {
    var api = 'facility/Sample/' + level
    ApiUtility.downloadFile(api, structure + ' Master.xlsx');
  }

}
