import { OnInit, OnDestroy, Directive, Input, ElementRef } from '@angular/core';
import { SharedService } from '@services/shared.service';

@Directive({
    selector: '[forbidden]'
})
export class ForbiddenDirective implements OnInit, OnDestroy {

    @Input() forbidden: boolean;


    constructor(public element: ElementRef, public _shared: SharedService) { }


    ngOnInit() {
        //this.setLoader();
    }

    ngOnChanges() {
        //this.setForbidden();
    }

    setLoader() {
    //     this.element.nativeElement.innerHTML = `
    //     <div class="loader-demo loader-demo-sk" >
    //     <div class="sk-cube-grid">
    //         <div class="sk-cube sk-cube1">    </div>
    //         <div class="sk-cube sk-cube2">    </div>
    //         <div class="sk-cube sk-cube3">    </div>
    //         <div class="sk-cube sk-cube4">    </div>
    //         <div class="sk-cube sk-cube5">    </div>
    //         <div class="sk-cube sk-cube6">    </div>
    //         <div class="sk-cube sk-cube7">    </div>
    //         <div class="sk-cube sk-cube8">    </div>
    //         <div class="sk-cube sk-cube9">    </div>
    //         </div>
    //     </div>
    //     `;
    this.element.nativeElement.innerHTML = `
    <div class="text-center" >
        <div>
            <h4>Loading...</h4>
            </div>
        </div>
        `;
    }

    setForbidden() {
        if (!this._shared.rbac.CanAccess) {
            this.element.nativeElement.innerHTML = `
        <div class="alert alert-warning">
            <h1 class="text-center" style="font-size: 10rem;">403</h1>
            <h2 class="text-center">Page Forbidden</h2>
            <h3 class="text-center">We're sorry, you don't have access to the page or resource you requested.</h3>
            <h5 class="text-center">To view this page, you may have to sign in with a different account.</h5>
            </div>
        `;
        } else {
            this.element.nativeElement.innerHTML = ``;
        }

    }

    ngOnDestroy() {
    }

}
