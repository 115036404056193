import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// IM Incident Service
@Injectable()
export class IMIncidentTypeService {

    constructor(private http: HttpClient) { }

    // Get IMTemplate
    getIMIncidentType(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-type' + uri);
    }


    // Get IM Incident Type
    getIncidentType(page: Pagination) {
        let uri: string = page.GetUri();
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-type' + uri);
    }

    // Edit IM Incident  Type
    editIMIncidentType(incidentTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-type' + incidentTypeId);
    }

    // Add IM Incident Type
    addIMIncidentType(incidentType: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'incident-type', incidentType);
    }

    // Update Incident type
    updateIncidentType(incidentType: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'incident-type' + incidentType.incidentTypeId, incidentType);
    }

    // Delete Incident Type
    deleteIncidentType(incidentTypeId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'incident-type' + incidentTypeId);
    }

    // get Incident Audit Trail
    getIncidentTypeAuditTrail(incidentTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-type/' + incidentTypeId + '/audit-trail');
    }

}
