import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';

// Enquiry Service
@Injectable()
export class EnquiryService {

  constructor(private http: HttpClient) { }

  // GetEnquiries
  GetEnquiries(fromDate, toDate, types, status) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'enquiry?FromDate=' + fromDate + '&ToDate=' + toDate + '&Type=' + types + '&Status=' + status);
  }

  // AddEnquiry
  AddEnquiry(enquiry) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'enquiry', enquiry);
  }
  // UpdateStatus
  UpdateStatus(EnquiryId, EnquiryStatusId) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'enquiry/'+EnquiryId+'/status/'+EnquiryStatusId, null);
  }

  // AddEnquiryRegistered
  AddEnquiryRegistered(enquiry) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'enquiry/registered', enquiry);
  }
}