import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Contractor Service
@Injectable()
export class BBSObservationCategoryService {

  constructor(private http: HttpClient) { }

  // Get BBS Observation Category
  getBBSObservationCategory(page: Pagination=null) {
    let uri: string = "";
    if(!!page){
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category');
  }

   // Edit BBS Observation Category
   editBBSObservationCategory(observationCategoryId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category/' + observationCategoryId);
  }

  // getObservationCategoryAuditTrail
  getObservationCategoryAuditTrail(observationCategoryId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category/' + observationCategoryId + '/audit-trail');
  }

  // Add BBS Observation Category
  addBBSObservationCategory(observationCategory: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category', observationCategory);
  }

  // Update Observation Category
  updateObservationCategory(observationCategory: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category/' + observationCategory.ObservationCategoryId, observationCategory);
  }

  // Delete Observation Category
  deleteObservationCategory(observationCategoryId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'bbs/observation-category/' + observationCategoryId);
  }
  
}