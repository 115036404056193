import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Employee Service
@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }


  getuserParams(filter: any) {
    var query = "";
    var param = [];
    if (!!filter.DepartmentId) {
      param.push("DepartmentIds=" + filter.DepartmentId);
    }
    if (!!filter.DesignationId) {
      param.push("DesignationIds=" + filter.DesignationId);
    }
    if (!!filter.PositionId) {
      param.push("PositionIds=" + filter.PositionId);
    }
    if (!!filter.FacilityMasterId) {
      param.push("FacilityIds=" + filter.FacilityMasterId);
    }
    //param.push("IsUser=" + true);
    if (!!filter.IsUser) {
      param.push("IsUser=" + filter.IsUser);
    }
    if (!!filter.Search) {
      param.push("Search=" + filter.Search);
    }
    if (!!filter.ClientRoleId) {
      param.push("ClientRoleId=" + filter.ClientRoleId);
    }
    if (param.length > 0) {
      query = "?" + param.join("&");
    }
    return query;
  }


  // Get Users
  getUsers(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    // let query = this.getuserParams(bbsSearchFilter);
    // let uri: string = page.GetUri() + query.replace('?', '&');
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user' + uri);
  }
  // Get Employees
  getuserWithSearch(page: Pagination, employee, character, search) {
    var query = this.getuserParams(employee);
    let uri: string = page.GetUri() + query.replace('?', '&');
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user' + uri + '&character=' + character + '&Search=' + search);
  }

  // Edit Users
  editUsers(userId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user/' + userId);
  }

  // getUserAuditTrail
  getUserAuditTrail(userId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user/' + userId + '/audit-trail');
  }


  getEmployee() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee');
  }

  getProfile() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user/profile');
  }

  saveProfile(userProfileData: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'user/profile', userProfileData);
  }

  updateTwoFactorAuth(isTowFactorAuthActive: boolean) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'user/profile/two-factor-auth', { IsTowFactorAuthActive: isTowFactorAuthActive });
  }

  getLayout() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user/layout');
  }

  saveLayout(userLayoutData: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'user/layout', userLayoutData);
  }

  getUserRolePrivileges(clientUserRoleMappingId: any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'user/role-mapping/' + clientUserRoleMappingId + '/privileges');
  }

  getEmployeeName(employeeId: number = 0) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/information?onlyEmployee=false' + (!!employeeId ? '&EmployeeId=' + employeeId : ''));
  }

  getEmployeeNameOnlyEmployee(employeeId: number = 0) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/information?onlyEmployee=true' + (!!employeeId ? '&EmployeeId=' + employeeId : ''));

  }
  getListOfEmployee(character, employeeId: number = 0) {
    // let uri: string = page.GetUri();
    // return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + '&StartWith=' + character);
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee?CurrentPage=1&PageSize=50' + '&Search=' + character + (!!employeeId ? '&EmployeeId=' + employeeId : ''));
  }

  addUser(user: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'user', user);
  }

  updateUser(user: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'user/' + user.UserId, user);
  }

  addRole(role: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'user/add/role', role);
  }

  // Update Profile Logo
  updateProfileLogo(attachment: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'user/profile-picture', attachment);
  }

  downloadUserReport(format) {
    var api = 'user/Report/' + format;
    ApiUtility.downloadFile(api, 'UserList.' + format);
  }
}
