import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Enterprise Structure Service
@Injectable()
export class StructureService {

  constructor(private http: HttpClient) { }

  // Get Structures
  getStructures() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure');
  }

  // Get Structures
  getStructureAndData(layout: string = 'normal') {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/data?layout=' + layout);
  }

  // Edit Structure
  editStructure(enterpriseStructureId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/' + enterpriseStructureId);
  }

  // Edit Structure by level
  editStructureLevel(structureLevel: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/' + structureLevel);
  }

  ListName: string;
  downloadEnterpriseReport(level, format: string, structure: string) {
    var api = 'structure/level/Report/' + level + "/" + format;
    ApiUtility.downloadFile(api, structure + " Master Report." + format);
  }


  // Get Structure data
  getStructureData(enterpriseStructureId: number, parentId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/' + enterpriseStructureId + '/data?parentId=' + parentId);
  }

  // Get Parent data
  getParentData(enterpriseMasterId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/data/parents/' + enterpriseMasterId);
  }

  // Get Structure data by level
  getStructureLevelData(structureLevel: number, parentId: number, page: Pagination,search?:any,selectedCh?:any, locationId = null) {
    const params: string[] = [];
    params.push('parentId=' + parentId);
    let uri: string = page.GetUri(params);
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/' + structureLevel + '/data' + uri +(!!selectedCh ? '&Character=' + selectedCh : '')+ (!!search ? '&Character=' + search : '')+  (!!locationId ? '&locationId=' + locationId : ''));
  }

  // Edit Structure data
  editStructureData(enterpriseStructureId: number, enterpriseMasterId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/' + enterpriseStructureId + '/data/' + enterpriseMasterId);
  }

  // Eet Structure data by level
  editStructureLevelData(structureLevel: number, enterpriseMasterId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/' + structureLevel + '/data/' + enterpriseMasterId);
  }

  // getStructureLevelDataAuditTrail
  getStructureLevelDataAuditTrail(structureLevel: number, enterpriseMasterId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'structure/level/' + structureLevel + '/data/' + enterpriseMasterId + '/audit-trail');
  }

  // Add Structure
  addStructure(structure: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'structure', structure);
  }

  // Set Operation Level
  setOperationLevel(enterpriseStructureId: number) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'structure/' + enterpriseStructureId + '/OperationLevel', null);
  }

  // Update Structures
  updateStructure(structure: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'structure/' + structure.EnterpriseStructureId, structure);
  }

  // Add Structure data
  addStructureData(structureData: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'structure/' + structureData.EnterpriseStructureId + '/data', structureData);
  }

  // Update Structure data
  updateStructureData(structureData: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'structure/' + structureData.EnterpriseStructureId + '/data/' + structureData.EnterpriseMasterId, structureData);
  }

  // Delete Structure Data
  deleteStructureData(enterpriseMasterId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'structure/' + enterpriseMasterId);
  }

  importFile(fileToUpload: any, level: any) {
    let input = new FormData();
    input.append("file", fileToUpload);

    return this.http.post<iApiResponse>(_$config.api_endpoint + 'structure/import/' + level, input);
  }

  SampleFormatEnterpriseDownload(level: any, structure: string) {
    var api = 'structure/Sample/' + level
    ApiUtility.downloadFile(api, structure + ' Master.xlsx');
  }

}
