import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// IM Standard Service
@Injectable()
export class IMIncidentSubmitService {

  constructor(private http: HttpClient) { }


  // Get IM Incident Submit data
  getIMIncidentSubmitData(id) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident/' + id);
  }

  // Post IM Incident Submit Data
  submitIMIncidentSubmitData(data) {
    // let uri: string = page.GetUri();
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'incident', data);
  }
  // Post IM Incident Submit Data
  updateIMIncidentSubmitData(data: any, id: number) {
    // let uri: string = page.GetUri();
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'incident/' + id, data);
  }
  // Review IM Incident Submit Data
  reviewIMIncidentSubmitData(data: any, id: number) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + `incident/${id}/review-incident` , data);
  }

  // Get IMTemplate
  // getIMStandardType(page: Pagination = null) {
  //     let uri: string = "";
  //     if (!!page) {
  //         uri = page.GetUri();
  //     }
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'standard' + uri);
  // }


  // Get IM Standard Type
  // getStandardType(page: Pagination) {
  //     let uri: string = page.GetUri();
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'standard' + uri);
  // }

  // Edit IM Standard Type
  // editIMStandardType(standardTypeId: number) {
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'standard' + standardTypeId);
  // }

  // Add IM Standard Type
  // addIMStandardType(standardType: any) {
  //     return this.http.post<iApiResponse>(_$config.api_endpoint + 'standard', standardType);
  // }

  // Update Standard type
  // updateStandardType(standardType: any) {
  //     return this.http.put<iApiResponse>(_$config.api_endpoint + 'standard' + standardType.standardTypeId, standardType);
  // }

  // Delete Nature of Injury Type
  //     deleteStandardType(standardTypeId: number) {
  //         return this.http.delete<iApiResponse>(_$config.api_endpoint + 'standard' + standardTypeId);
  //     }

  //     getStandardTypeAuditTrail(standardTypeId: number) {
  //       return this.http.get<iApiResponse>(_$config.api_endpoint + 'standard' + standardTypeId + '/audit-trail');
  //   }

}
