import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageType, UserRole } from '@app.enum';
import { AuthService } from '@services/admin/auth.service';
import { Application } from '@utility/utility.application';
import { Notify } from '@utility/utility.notify';
import { Encryption } from '@utility/utility.encryption';
import { TranslatorService } from '@core/translator/translator.service';
import { AppSession } from '@interface/interface.app-session';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    isLoading: boolean;
    showTwoFactorAuth: boolean = false;
    loginResult: any = null;
    twoFactorCode: string = "";
    isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent)
    redirectTo: string = "";
    constructor(
        public settings: SettingsService,
        private _router: ActivatedRoute,
        public translator: TranslatorService,
        fb: FormBuilder,
        private router: Router,
        private _auth: AuthService,
        private translate: TranslateService,
        private dbservice: IndexedDbService
    ) {

        this.loginForm = fb.group({
            UserName: [null, Validators.required],
            Password: [null, Validators.required],
            RememberMe: [false]
        });
        this.getRememberedCredentials();
        this._router.queryParams.subscribe(params => {
            this.redirectTo = "";
            if (!!params && !!!!params.p) {
                this.redirectTo = Encryption.GetQueryParam(params.p);
            }
        });
    }

    cancelTwoFactorVerification() {
        this.twoFactorCode = "";
        this.loginResult = null;
        this.showTwoFactorAuth = false;
    }

    loginToSystem(result) {
        Application.Remove('client-logo');
        Application.Remove('client-user-logo');
        // Application.Remove('got-to-cart');
        Application.Remove('p-cart');
        Application.SetSession(result.access_token, result.session);

        let session: AppSession = Application.GetSession();
        if (!!session) {
            if (!!session.Languages && session.Languages.length > 0) {
                this.translator.useLanguage(session.Languages.split(',')[0]);
            }
        }

        sessionStorage.clear();
        //this.setRemember(value);
        Application.Remove("force_password_reset");

        if (!!result.force_password_reset) {
            Application.Set("force_password_reset", result.force_password_reset);
            this.router.navigate(['administration', 'change-password']);
        }
        // else if (!result.IsPackagePurchased) {
        //     Application.Set('got-to-cart', 'yes');
        //     Application.Set('p-cart', '['+result.PackageId+']');
        //     this.router.navigate(result.redirect.split(','));
        // }
        else if (result.quick_start) {
            if (result.allow_quick_start) {
                if (result.isOnboardingAvailable) {
                    this.router.navigate(['home', 'wizard-upload']);
                } else {
                    this.router.navigate(['home', 'wizard']);
                }
            } else {
                this.router.navigate(['home', 'work-in-progress']);
            }
        } 
        // else if (!result.subscriptions || result.subscriptions.length == 0) { // uncomment when subscription done
        else if (!result.subscriptions) {
            this.router.navigate(['administration', 'package']);
        } else {
            if (!!this.redirectTo) {
                this.router.navigateByUrl(this.redirectTo);
            } else {
                let hash = Application.Get("redirect");
                if (!!hash && hash != "login") {
                    let navigation = hash.split('/');
                    Application.Remove("redirect");
                    this.router.navigate(navigation);
                } else {
                    this.router.navigate(['home']);
                }
            }
        }
    }

    login($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.loginForm.controls) {
            this.loginForm.controls[c].markAsTouched();
        }

        this.twoFactorCode = "";
        this.loginResult = null;
        this.showTwoFactorAuth = false;

        if (this.loginForm.valid) {
            this.isLoading = true;
            this._auth.getAccessToken(value.UserName, value.Password).subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        this.setRemember(value);
                        if (data.Result.IsTowFactorAuthActive) {
                            this.loginResult = data.Result;
                            this.showTwoFactorAuth = true;
                            this.isLoading = false;
                        } else {
                            this.loginToSystem(data.Result);
                            this.isLoading = true;
                        }
                    } else {
                        this.isLoading = false;
                        Notify.Warning(data.Message);
                    }
                },
                err => {
                    this.isLoading = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    //this.isLoading = false;
                });
        } else {
            Notify.Warning('Invalid data');
        }
    }

    verifyCode() {
        if (!this.twoFactorCode) {
            return;
        }

        this.isLoading = true;
        this._auth.validateTwoFactorAuthCode(this.loginResult.session, this.twoFactorCode).subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    this.loginToSystem(this.loginResult);
                } else {
                    this.isLoading = false;
                    Notify.Warning(data.Message);
                }
            },
            err => {
                this.isLoading = false;
                Notify.ApiError_Normal(err, this.translate);
            },
            () => {
                //this.isLoading = false;
            });
    }

    setRemember(value) {
        if (value.RememberMe) {
            let remember_token = Encryption.Encrypt(JSON.stringify(value));
            Application.Set('remtn', remember_token);
        } else {
            Application.Remove('remtn');
        }
    }

    getRememberedCredentials() {
        let remember_token = Application.Get('remtn');
        if (!!remember_token) {
            let valueStr = Encryption.Decrypt(remember_token);
            let key = Encryption.Decrypt('FS10qeQfvDWGh9FzPsmw52DZSTx0xhGc2kY+7XHMv9ZFiBaRGekmc51CJnOn6HB7Y06Qv20h9yGowCgnbfaW/vozWlVtFBG6TLAQsulRrj7RG9Q23uNsK+KZeOhc6z/3')
            console.log(key)
            let value = JSON.parse(valueStr);
            this.loginForm.patchValue(value);
        }
    }

    ngOnInit() {
        document.body.classList.add("setu-bg");
        let session = Application.GetSession();
        if (!!session) {
            this._auth.logout().subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        Application.ClearSession();
                        this.dbservice.clearStore();
                    }
                },
                err => {
                },
                () => {
                });
        }
    }

    ngOnDestroy() {
        document.body.classList.remove("setu-bg");
    }

}
