import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Body Part Service
@Injectable()
export class BodyPartService {

  constructor(private http: HttpClient) { }

  // Get Body Parts
  getBodyParts(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bodypart' + uri);
  }

  // Edit Body Part
  editBodyPart(bodyPartId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bodypart/' + bodyPartId);
  }

  // Add Body Part
  addBodyPart(bodypart: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bodypart', bodypart);
  }

  // Update Body Part
  updateBodyPart(bodypart: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bodypart/' + bodypart.BodyPartId, bodypart);
  }

  // Update Body Part Status
  updateBodyPartStatus(bodyPartId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bodypart/' + bodyPartId + '/status?isActive=' + isActive, null);
  }

  // Delete Body Part
  deleteBodyPart(bodyPartId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'bodypart/' + bodyPartId);
  }
}