import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { mySetuMenuService } from '@services/admin/menu.service';

 @Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewInit {

    loadingRouterOutlet: boolean;
    constructor(private router: Router, public _menu: mySetuMenuService) {
        //this.loadingRouterOutlet = true;
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.loadingRouterOutlet = true;
                } else if (
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel
                ) {
                    this.loadingRouterOutlet = false;
                }
            });
    }
}
