import { Component, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

import { SettingsService } from '@core/settings/settings.service';
import { ThemesService } from '@core/themes/themes.service';
import { TranslatorService } from '@core/translator/translator.service';
import { SharedService } from '@services/shared.service';
import { Application } from '@utility/utility.application';

 @Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {
    languages = [
        { code: 'en', text: 'English' },
        { code: 'hi', text: 'Hindi (हिंदी)' },
        { code: 'gu', text: 'Gujarati (ગુજરાતી)' }
    ];
    currentTheme: any;
    selectedLanguage: string;
    clickEvent = 'click.offsidebar';
    $doc: any = null;

    constructor(public settings: SettingsService, public themes: ThemesService, public translator: TranslatorService,
        public _shared: SharedService) {
        this.currentTheme = themes.getDefaultTheme();
        this.selectedLanguage = this.getLangs()[0].code;
    }

    ngOnInit() {
        this.anyClickClose();
        this.setUserLastTheme();
    }

    setUserLastTheme() {
        var currentTheme = Application.Get('currentTheme');
        if(!!currentTheme) {
            this.currentTheme = currentTheme;
            this.themes.setTheme(this.currentTheme);
        }
    }

    setTheme() {
        Application.Set('currentTheme',this.currentTheme);
        this.themes.setTheme(this.currentTheme);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
        this._shared.resetLanguage();
    }

    anyClickClose() {
        this.$doc = $(document).on(this.clickEvent, (e) => {
            if (!$(e.target).parents('.offsidebar').length) {
                this.settings.layout.offsidebarOpen = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.clickEvent);
    }
}
