import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { iApiResponse } from "@interface/interface.api-response";

@Injectable({
  providedIn: "root",
})
export class TrendService {
  constructor(private http: HttpClient) {}
  multi = new Multiline();
  multilineDatasets: MultilineDataset[] = [];
  categories: MultilineCategory[] = [];
  getChartDetails(url: any, searchFilter) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + url, {
      params: searchFilter,
    });
  }

  getGraphData(data: any[], type: string) {
    let graph = {} as any;
    switch (type) {
      case "Singleline":
        graph = this.createSingleLineGraph(data);
        break;
      case "Multiline":
        graph = this.createMultiLineGraph(data);
      default:
        break;
    }
    return graph;
  }

  createSingleLineGraph(graphData: any): Single {
    const single = new Single();
    single.chart.caption = graphData.Caption;
    single.chart.numberPrefix = "";
    (single.chart.xAxisName = graphData.XAxisName),
      (single.chart.yAxisName = graphData.YAxisName);
    graphData.data.forEach((item) => {
      const data = new Data();
      (data.label = item.Name), 
       (data.value = item.Total || item.FractionTotal);
      data.link = location.origin + item.Link;
      single.data.push(data);
    });
    return single;
  }

  createMultiLineGraph(graphData: any): Multiline {
    this.multi.chart.caption = graphData.Caption;
    this.multi.chart.numberPrefix = "";
    this.multi.chart.divLineDashLen = "1";
    this.multi.chart.divLineGapLen = "1";
    this.multi.chart.divLineIsDashed = "1";
    this.multi.chart.plotFillAlpha = "80";
    this.multi.chart.showhovereffect = "1";
    this.multi.chart.exportEnabled = 1;
    this.multi.chart.xAxisName = graphData.XAxisName;
    this.multi.chart.yAxisName = graphData.YAxisName;
    this.categories = [];
    this.multilineDatasets = [];
    this.findAllSeries(graphData.data);
    this.createMultiStructure(graphData.data);
    this.multi.categories = [
      {
        category: this.categories,
      },
    ];
    this.multi.dataset = this.multilineDatasets;
    return this.multi;
  }

  findAllSeries(data: any[]) {
    data.forEach((d) => {
      const multiDataSet = new MultilineDataset();
      multiDataSet.seriesname = d.SeriesName;
      if (this.multilineDatasets.length > 0) {
        const present = this.multilineDatasets.find((ms) => {
          return ms.seriesname === d.SeriesName;
        });
        if (!present) {
          this.multilineDatasets.push(multiDataSet);
        }
      } else {
        this.multilineDatasets.push(multiDataSet);
      }
    });
  }

  createMultiStructure(data: any[]) {
    data.forEach((d) => {
      const catagory = new MultilineCategory();
      catagory.label = d.Name;
      if (this.categories.length > 0) {
        const present = this.categories.find((c) => {
          return c.label == d.Name;
        });
        if (!present) {
          this.categories.push(catagory);
          this.addSeriesData(data, d);
        }
      } else {
        this.categories.push(catagory);
        this.addSeriesData(data, d);
      }
    });
  }

  addSeriesData(data: any[], d: any) {
    const items = data.filter((da) => {
      return da.Name === d.Name;
    });
    this.multilineDatasets.forEach((ds) => {
      const multilineData = new MultilineData();
      const present = items.find((i) => {
        return i.SeriesName === ds.seriesname;
      });
      if (present) {
        multilineData.value = present.Total || present.FractionTotal;
        ds.data.push(multilineData);
      } else {
        (multilineData.value = "0"), ds.data.push(multilineData);
      }
    });
  }
  export() {
    return this.http.get<iApiResponse>(
      _$config.api_endpoint + "export-to-excel"
    );
  }
}

export class Single {
  chart = new Chart();
  data: Data[] = [];
}

export class Chart {
  caption = "";
  xAxisName = "";
  yAxisName = "";
  numberPrefix = "";
  theme = "fint";
  exportEnabled = 1;
  dataLoadStartMessage = "Please Wait";
  formatNumberScale = 1;
  showlabels = 1;
  showPercentInTooltip = 0;
  showLegend = 1;
  enablemultislicing = 0;
  slicingdistance = 20;
  bgColor = "#ffffff";
  borderAlpha = 0;
  bgRatio = 0;
  palette = 0;
  paletteColors = "#0075c2,#1aaf5d,#f2c500,#ff52ce,#fa0c0c,#07c3e8,#d4830b";
  canvasBgColor = "#ffffff";
  canvasBgAlpha = 0;
  lineThickness = 5;
  showBorder = 0;
  showShadow = 0;
  showValues = 1;
  baseFontSize = "15";
  valueFontSize = "15";
  valueFontColor = "#000000";
  legendFontSize = "15";
  rotateValues = 0;
  labelDisplay = "Auto";
  legendItemFontSize = "15";
  placeValuesInside = 0;
  numVisiblePlot = "60";
}

export class Data {
  label = "";
  value = 0;
  link: any;
}

export class MultilineData {
  value = "";
}

export class MultilineDataset {
  seriesname = "";
  data: MultilineData[] = [];
}

export class MultilineChart {
  caption = "";
  xAxisName = "";
  yAxisName = "";
  numberPrefix = "";
  theme = "fint";
  plotFillAlpha = "80";
  divLineIsDashed = "1";
  divLineDashLen = "1";
  divLineGapLen = "1";
  showhovereffect = "";
  exportEnabled = 1;
  formatNumberScale = 1;
  showlabels = 1;
  showPercentInTooltip = 0;
  showLegend = 1;
  enablemultislicing = 0;
  slicingdistance = 20;
  bgColor = "#ffffff";
  borderAlpha = 0;
  bgRatio = 0;
  palette = 0;
  paletteColors = "#0075c2,#1aaf5d,#f2c500,#ff52ce,#fa0c0c,#07c3e8,#d4830b";
  canvasBgColor = "#ffffff";
  canvasBgAlpha = 0;
  lineThickness = 5;
  showBorder = 0;
  showShadow = 0;
  showValues = 1;
  baseFontSize = "15";
  valueFontSize = "15";
  valueFontColor = "#000000";
  legendFontSize = "15";
  rotateValues = 0;
  labelDisplay = "Auto";
  legendItemFontSize = "15";
  placeValuesInside = 0;
  numVisiblePlot = "60";
}

export class MultilineCategory {
  label = "";
}

export class Multiline {
  chart = new MultilineChart();
  categories: [{ category: MultilineCategory[] }];
  dataset: MultilineDataset[] = [];
}
