import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { ApiUtility } from '@utility/utility.api-utility';

// Survey Service
@Injectable()
export class SSPSSurveyService {

  constructor(private http: HttpClient) { }

  // Get Survey Details
  getSurveyDetails() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/survey/details');
  }

  // Get Survey Details by link
  getSurveyDetailsByLink(link: string, withQuestion: boolean) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/survey/' + link + '/details?withQuestion=' + withQuestion);
  }

  saveSurveyResponse(link: string, scheduleId: number, surveyResponses: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'ssps/survey/schedule/' + link + '/' + scheduleId + '/response', surveyResponses);
  }

  addQuestion(question: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'ssps/question', question);
  }

  updateQuestion(question: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'ssps/question/' + question.QuestionId, question);
  }

  getQuestions() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/question');
  }

  getQuestion(questionId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/question/' + questionId);
  }

  getSurvey() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/survey/summary');
  }

  getLanguage() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'language');
  }

  getSection() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/survey/section?Active=true');
  }

  addAnswer(answer: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'ssps/question/answer', answer);
  }

  updateAnswer(answer: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'ssps/question/answer/' + answer.AnswerId, answer);
  }

  getAnswers(questionId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/question/' + questionId + '/answer');
  }

  getAnswer(answerId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'ssps/question/answer/' + answerId);
  }

  // addSurvey(survey: any) {
  //   return this.http.post<iApiResponse>(_$config.api_endpoint + 'survey', survey);
  // }

  updateSurvey(survey: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'ssps/survey/' + survey.SurveyId, survey);
  }
}
