import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'numberToWord' })
export class NumberToWordPipe implements PipeTransform {

    arr1 = new Array("", " thousand", " million", " billion");
    arr2 = new Array("zero", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety");
    arr3 = new Array("zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine");
    arr4 = new Array("ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen");

    transform(text: string, currency: string): string {
        var result = "";
        if (!!text) {
            currency = currency || "INR";
            if (currency == "INR") {
                result = this.transform_INR(text);
            } else if (currency == "USD") {
                result = this.transform_USD(text + "");
            }
            else {
                result = text;
            }
            // result = this.convertNumberToWords(text);

            // result = "Rupees " + result
            // text = text + "";
            // if (text.split('.').length == 2) {
            //     var decimalNumber = ((parseInt(parseFloat(text) * 100 + "")) + "").slice(-2);
            //     var decimal = this.convertNumberToWords(decimalNumber);
            //     result = result + " and " + decimal + " Paise";
            // }

            // result = result + " Only";

        } else {
            if (text == '0') {
                if(currency == "INR"){
                    result = "RUPEES ZERO ONLY";
                } else {
                    result = "US DOLLARS ZERO ONLY";
                }
            } else {
                result = text;
            }
        }

        return result;
    }

    transform_INR(text) {
        var result = this.convertNumberToWords(text);

        result = "Rupees " + result;
        text = text + "";
        if (text.split('.').length == 2) {
            var decimalNumber = ((parseInt(parseFloat(text) * 100 + "")) + "").slice(-2);
            var decimal = this.convertNumberToWords(decimalNumber);
            result = result + " and " + decimal + " Paise";
        }

        result = result + " Only";

        return result.toUpperCase();
    }

    transform_USD(number) {
        var result = "";
        if (number.indexOf(".") != -1) {
            var integer = number.split(".")[0];

            var decimal = number.split(".")[1].substring(0, 2);

            var decimalword;

            var interword = this.intergernumber(integer);

            decimalword = this.decimalnumber2(decimal);

            result = interword + " AND " + decimalword;
        } else {
            result = this.numbertotext(number);
        }

        return result;

    }

    convertNumberToWords(amount) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        var value;
        if (n_length <= 9) {
            var n_array = new Array<any>(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            value = "";
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    //words_string += "Hundred and ";
                    words_string += "Hundred ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string;
    }


    intergernumber(a) {
        var num3;
        var strEng;
        var b = a.length,
            f, h = 0,
            g = "",
            e = Math.ceil(b / 3),
            k = b - e * 3;
        g = "";
        for (f = k; f < b; f += 3) {
            ++h;
            num3 = f >= 0 ? a.substring(f, f + 3) : a.substring(0, k + 3);
            strEng = this.English(num3);
            if (strEng != "") {
                if (g != "") g += ",";
                g += this.English(num3) + this.arr1[e - h]
            }
        }
        return "US DOLLARS " + g.toUpperCase();
    }

    English(a) {
        let strRet = "";
        if (a.length == 3 && a.substr(0, 3) != "000") {
            if (a.substr(0, 1) != "0") {
                strRet += this.arr3[a.substr(0, 1)] + " hundred";
                if (a.substr(1, 2) != "00") strRet += " and "
            }
            a = a.substring(1);
        }
        if (a.length == 2)
            if (a.substr(0, 1) == "0") a = a.substring(1);
            else if (a.substr(0, 1) == "1") strRet += this.arr4[a.substr(1, 2)];
            else {
                strRet += this.arr2[a.substr(0, 1)];
                if (a.substr(1, 1) != "0") strRet += "-";
                a = a.substring(1)
            } if (a.length == 1 && a.substr(0, 1) != "0") strRet += this.arr3[a.substr(0, 1)];
        return strRet;
    };

    decimalnumber2(a) {
        var num3;
        var strEng;
        var b = a.length,
            f, h = 0,
            g = "",
            e = Math.ceil(b / 3),
            k = b - e * 3;
        g = "";
        for (f = k; f < b; f += 3) {
            ++h;
            num3 = f >= 0 ? a.substring(f, f + 3) : a.substring(0, k + 3);
            strEng = this.English(num3);
            if (strEng != "") {
                if (g != "") g += ",";
                g += this.English(num3) + this.arr1[e - h]
            }
        }
        return "CENTS " + g.toUpperCase() + " ONLY";
    }

    numbertotext(a) {
        var num3;
        var strEng;
        var b = a.length,
            f, h = 0,
            g = "",
            e = Math.ceil(b / 3),
            k = b - e * 3;
        g = "";
        for (f = k; f < b; f += 3) {
            ++h;
            num3 = f >= 0 ? a.substring(f, f + 3) : a.substring(0, k + 3);
            strEng = this.English(num3);
            if (strEng != "") {
                if (g != "") g += ",";
                g += this.English(num3) + this.arr1[e - h]
            }
        }
        return "US DOLLARS " + g.toUpperCase() + " ONLY";
    }

}