export enum MessageType {
  Success = 1,
  Error = 2,
  Warning = 3,
  NotFound = 4
}

export enum UserRole {
  System = 1,
  SuperAdmin = 2,
  ClientAdmin = 3,
  Client = 4,
  CorporateUser = 5
}


export enum AttachmentType {
  CompanyLogo = 1,
  UserLogo = 2,
  TaskAttachment = 3,
  SEZDocument = 4,
  TicketAttachment = 5,
  WhyWhyAnalaysisAttachment = 6,
  IncidentAttachment = 7,
  AffectedPersonFitnessAttachment = 8,
  LessonLearningAttachment = 9,
  IncidentAffectedPersonAttachment = 10
}
export enum EmailStatus {
  Outbox = 1,
  Sending = 2,
  Fail = 3,
  Sent = 4,
  Deleted = 5
}

export enum BBSFormatType {
  Basic = 1,
  Checklist = 2
}

export enum EmployeeType {
  Employee = 1,
  ContractorEmployee = 2,
  ContractorSupervisor = 3,
  Visitor = 4
}

export enum ImportedFileStatus {
  New = 1,
  InProgress = 2,
  Completed = 3
}

export enum Priority {
  VeryHigh = 1,
  High = 2,
  Medium = 3,
  Low = 4,
  VeryLow = 5
}

export enum TaskType {
  General = 1,
  BBS = 2
}

export enum TargetFrequency {
  Yearly = 1,
  Quarterly = 2,
  Monthly = 3,
  Weekly = 4,
  DateRange = 5
}

export enum QuickStep {
  CompanyInformation = 1,
  PaymentConfiguration = 2,
  ManagePackage = 3,
  Subscription = 4,
  DefineEnterprise = 5,
  AddEnterpriseData = 6,
  ConfigurationSettings = 7,
  CommonApplicationConfiguration = 8,
  ApplicationConfigurationforlocation = 9,
  DefineFacility = 10,
  AddFacilityData = 11,
  AddUserRoles = 12,
  SetRolePrivileges = 13,
  AddDepartments = 14,
  AddDesignations = 15,
  AddPositions = 16,
  AddContractor = 17,
  AddEmployee = 18,
  AddUsers = 19,
  ConfigureWeek = 20,
  AddShift = 21,
  TaskStatus = 22,
  TaskActions_Events_ = 23,
  TaskWorkflowConfiguration = 24,
  BBSStatus = 25,
  AddObservationCategory = 26,
  DefineBBSTemplate = 27,
  AddNatureofwork = 28,
  AddBBSObservationType = 29,
  AddBBSActionTypes = 30,
  AddSeverity = 31,
  PersonalInformation = 32
}

export enum EmailTemplate {
  ForgotPassword = 1
}

export enum FileImportOption {
  InsertUpdate = 1,
  InsertOnly = 2,
  UpdateOnly = 3
}

export enum MasterDataFileStatus {
  Pending = 1,
  Rejected = 2,
  Verified = 3,
  InProcess = 4,
  Completed = 5,
  PaymentRequired = 6,
  PaymentDone = 7,
  PaymentReceived = 7
}

export enum InvoiceType {
  ProformaInvoice = 1,
  TaxInvoice = 2
}

export enum Module {
  Common = 1,
  Admin = 2,
  ActionPlan = 3,
  BBS = 4,
  SSPS = 5,
  HHPS = 6,
  IM = 7,
  WTI = 13
}

export enum TaskField {
  Title = 1,
  Date = 2,
  Description = 3,
  Responsible = 4,
  Reviewer = 5,
  Attachment = 6
}

export enum TaskRole {
  Assignee = 1,
  Responsible = 2,
  Reviewer = 3
}

/* BEGIN Enums for Windows Service  */
export enum WindowsServiceStatus {
  Install = 1,
  Uninstall = 2,
  Start = 3,
  Stop = 4
}

export enum Recurring {
  None = 0,
  EveryMinute = 1,
  Every5Minute = 5,
  Every10Minute = 10,
  Every15Minute = 15,
  Every20Minute = 20,
  Every30Minute = 30,
  Every45Minute = 45,
  EveryHour = 60,
  Every2Hour = 120,
  Every3Hour = 180,
  Every4Hour = 240,
  Every6Hour = 360
}

export enum ScheduleType {
  OneTimeOnly = 0,
  Daily = 1,
  Weekly = 2,
  MonthlyByDate = 3,
  MonthlyByWeekDay = 4
}
/* END Enums for Windows Service  */
