import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { Location } from '@angular/common';
import { SettingsService } from '@core/settings/settings.service';
import { MenuService } from '@core/menu/menu.service';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { UserRole, Module, MessageType } from '@app.enum';
import { SharedService } from '@services/shared.service';
import { PackageService } from '@services/admin/package.service';
import { AuthService } from '@services/admin/auth.service';
import { GenericDataService } from '@services/generic-data.service';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';
import { FilterHistoryService } from '@services/admin/filter-history.service';

 @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    session: AppSession;
    locations: Array<any>;
    showLogo: boolean = true;
    loggingOut: boolean = false;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton') fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService,
        private _sharedService: SharedService,
        public _router: Router,
        public userblockService: UserblockService,
        public settings: SettingsService,
        public packageService: PackageService,
        private _auth: AuthService,
        private generic: GenericDataService,
        private dbservice: IndexedDbService,
        private filterhistory:FilterHistoryService,
        private location: Location,
    ) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.getSession();

        this._sharedService.refreshToken.subscribe((data) => {
            this.getSession();
        });

        this._sharedService.routeChanged.subscribe((data) => {
            this.getSession();
            // this.getPackageFeature(data);
        });
    }

    ngOnInit() {
        this.isNavSearchVisible = false;
        if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }
        this.generic.GetEmployeeList();
    }
    getSession() {
        this.session = Application.GetSession();
        var clientLogo = Application.Get('client-logo');
        if (!!clientLogo) {
            this.session.CompanyLogo = clientLogo;
        }
        this.setClientRole({ClientRoleId : this.session.ClientRoleId});

        //var loadedSystem = Application.Get('loaded-system');
        //  this.getPackageFeature();
// this.allow = JSON.parse(atob(localStorage.getItem("PackageFeature")));
    }

    // getPackageFeature(moduleName) {
    //     var moduleid = 0;
    //     this.showLogo = false;
    //     switch (moduleName) {
    //         case 'bbs':
    //             moduleid = Module.BBS;
    //             break;
    //         case 'ssps':
    //             moduleid = Module.SSPS;
    //             break;
    //         case 'hhps':
    //             moduleid = Module.HHPS;
    //             break;
    //         case 'im':
    //             moduleid = Module.IM;
    //             break;
    //         case 'WTI':
    //             moduleid = Module.WTI;
    //             break;
    //         default:
    //             break;
    //     }
    //     if (moduleid > 0) {
    //         this.packageService.getPackageFeature('LOGO_UPDATE_COUNT', moduleid).subscribe(
    //             data => {
    //                 if (data.MessageType == MessageType.Success) {
    //                     this.showLogo = data.Result.filter(x => x.Code == 'LOGO_UPDATE_COUNT' && x.Value > 0).length > 0;
    //                 }
    //             },
    //             err => {
    //             },
    //             () => {

    //             });
    //     }
    // }

    onImageSrcError(event) {
        this.showLogo = false;;
    }

    changeClientSession() {
        Application.Remove("ClientId");
        this._router.navigate(['mySetu', 'admin', 'select-company']);
    }

    setLocation(location, refreshToken: boolean = false, type?:any) {
        
        Application.Set("LocationId", location.EnterpriseMasterId);

        var selectedLocation = this.locations.filter(x => x.EnterpriseMasterId == location.EnterpriseMasterId);
        if (!!selectedLocation && selectedLocation.length > 0) {
            this.session.TimezoneId = selectedLocation[0].TimezoneId;
            Application.Set("TimezoneId", this.session.TimezoneId);
        }
        //window.location.reload();
        this.reloadPage(refreshToken);
        if(!!type){
            this.generic.GetEmployeeList();
            this.dbservice.clearStore();
            this._sharedService.setLocationChanged("locationchanged");
        }
    }

    reloadPage(refreshToken: boolean) {
        var path = window.location.hash.split('/').join('♥').replace('#♥', '');
        //var pathArray = ['home', 'redirect', path];
        var pathArray = ['home','redirect', 'home'];
        if (refreshToken) {
            this._sharedService.refreshAccessToken(false, pathArray);
        }
    }

    setClientRole(role, refreshToken: boolean = false, type?:any) {
        Application.Set("ClientRoleId", role.ClientRoleId);
        this.locations = this.session.Locations.filter(x => !x.ClientRoleId || x.ClientRoleId == role.ClientRoleId);

        var _locationIdString = Application.Get("LocationId");
        var locationId = 0;
        if (!!_locationIdString) {
            locationId = parseInt(_locationIdString);
        }
        var selectedLocation = this.locations.filter(x => x.EnterpriseMasterId == locationId);
        if (!!type){
            this._sharedService.setSubsSource(null);
            this._sharedService.setLocationChanged("rolechanged");
    }
        if ((!selectedLocation || selectedLocation.length == 0) && !!this.locations && this.locations.length > 0) {
            this.session.LocationId = this.locations[0].EnterpriseMasterId;
            this.setLocation({EnterpriseMasterId : this.session.LocationId}, refreshToken);
        }
         else {
            this.reloadPage(refreshToken);
        }
    }

    logout(logOutOverlay) {
        //logOutOverlay.show();
        this.loggingOut = true;
        this._auth.logout().subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    Application.ClearSession();
                    localStorage.removeItem("dashboardCustom");
                    this.dbservice.clearStore();
                    //logOutOverlay.hide();
                    this._router.navigate(['login']);
                } else {
                    this.loggingOut = false;
                }
            },
            err => {
                //logOutOverlay.hide();
                this.loggingOut = false;
            },
            () => {
                //logOutOverlay.hide();
                this.loggingOut = false;
            });
    }

    lock() {
        Application.Set('unlock-to', window.location.hash.replace('#/', ''));
        window.location.hash = "#/lock";
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }

    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }

    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        }
        else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }

    goBack() {
 
        // this.filterService.goBack()
        this.location.back();
    }

}
