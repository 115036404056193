import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Payment Config Service
@Injectable()
export class PaymentConfigService {

  constructor(private http: HttpClient) { }

  // Get Payment Config
  getPaymentConfig() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'payment/config');
  }

  // Get Payment Config
  getClientPaymentConfig(clientId:any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/payment/config/data/' +clientId);
  }

  // getPaymentConfigAuditTrail
  getPaymentConfigAuditTrail(clientPaymentConfigId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'payment/config/' + clientPaymentConfigId + '/audit-trail');
  }


  // Get Invoice Config
  getInvoiceConfig(invoiceNumber:any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'payment/invoice/' + invoiceNumber);
  }

  // Get Payment
  getPayment() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'payment');
  }

  // Get Invoice Detail
  getInvoiceDetail(transactionId:any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'payment/invoice/detail/' + transactionId);
  }

  getSearchParams(SearchFilter: any) {

    var param = [];
    if (!!SearchFilter) {
     
      if (!!SearchFilter.DateRange && SearchFilter.DateRange.length == 2) {
        param.push("FromDate=" + moment(SearchFilter.DateRange[0]).format('YYYY-MMM-DD 00:00:00'));
        param.push("ToDate=" + moment(SearchFilter.DateRange[1]).format('YYYY-MMM-DD 23:59:59'));
      } else {

        if (!!SearchFilter.FromDate) {
          param.push("FromDate=" + moment(SearchFilter.FromDate).format('YYYY-MMM-DD 00:00:00'));
        }
        if (!!SearchFilter.ToDate) {
          param.push("ToDate=" + moment(SearchFilter.ToDate).format('YYYY-MMM-DD 23:59:59'));
        }
      }

      if (!!SearchFilter.StatusId && SearchFilter.StatusId.length > 0) {
        //param.push("SelectedEmployeeIds=" + bbsSearchFilter.SelectedEmployeeIds);
        for (let index = 0; index < SearchFilter.StatusId.length; index++) {
          param.push("StatusId=" + SearchFilter.StatusId[index]);          
        }
      }
    }

    // if (param.length > 0) {
    //   query = "?" + param.join("&");
    // }
    return param;
  }

   // Get SEZ Verification Data
   getSEZVerificationData(fromDate, toDate, status) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'client/config/sez/verification?FromDate=' + fromDate +'&ToDate='+toDate+'&Status='+status );
  }

  // Update Payment Config
  updatePaymentConfig(invoiceConfig: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'payment/config/' + invoiceConfig.ClientId, invoiceConfig);
  }

   // Update SEZ Detail
   updateSEZDetail(paymentConfig: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'client/config/sez/approval/' + paymentConfig.ClientId,paymentConfig);
  }
}