import { MessageType } from "@app.enum";
import { Encryption } from "./utility.encryption";
import * as XLSX from 'xlsx';


export class Notify {
    /*
        available positions
        top-left
        top-right
        top-center
        bottom-left
        bottom-right
        bottom-center
    */
    private static options: any = {
        status: 'normal',
        timeout: 5000,
        group: null,
        pos: 'top-center'
    };

    constructor() { }

    // Notify default message
    public static Message(message: string) {
        this.options.status = 'normal';
        $.notify(message, this.options);
    }

    public exportAsExcelFile(column,json: any[], title: string): void {
        let obj=[];
        for (let j = 0; j < json.length; j++) {

            for (let k = 0; k < column.length; k++) {
                 
            }
        }

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader:true});
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.writeFile(workbook, `${title}.xls`);
      }

    // Notify message by type
    public static MessageByType(message: string, messageType: MessageType) {
        switch (messageType) {
            case MessageType.Success:
                this.Success(message);
                break;
            case MessageType.Warning:
                this.Warning(message);
                break;
            case MessageType.Error:
                this.Error(message);
                break;
            case MessageType.NotFound:
                this.Error(message);
                break;
            default:
                this.Error(message);
                break;
        }
    }

    // Notify information message
    public static Info(message: string) {
        this.options.status = 'info';
        $.notify(message, this.options);
    }

    // Notify successful message
    public static Success(message: string) {
        this.options.status = 'success';
        $.notify(message, this.options);
    }

    // Notify warning message
    public static Warning(message: string) {
        this.options.status = 'warning';
        $.notify(message, this.options);
    }

    // Notify error message
    public static Error(message: string) {
        this.options.status = 'danger';
        $.notify(message, this.options);
    }

    // Notify api error
    public static ApiError(err: any, translate = null) {
        if (err && err.status == 0) {
            this.options.status = 'warning';
            if (!!translate && !!translate.instant) {
                $.notify(translate.instant(_$config.errorMessageNetwork), this.options);
            } else {
                $.notify(_$config.errorMessageNetwork, this.options);
            }
        } else {
            this.options.status = 'danger';
            if (!!translate && !!translate.instant) {
                $.notify(translate.instant(_$config.errorMessage), this.options);
            } else {
                $.notify(_$config.errorMessage, this.options);
            }
            // const swal = require('sweetalert');
            // let title = !!translate && !!translate.instant ? translate.instant(_$config.errorMessage) : _$config.errorMessage;
            // swal({
            //     title: title,
            //     text: '',
            //     type: 'error',
            //     showCancelButton: true,
            //     confirmButtonColor: '#23b7e5',
            //     confirmButtonText: 'Raise Support',
            //     cancelButtonText: 'OK',
            //     closeOnConfirm: true,
            //     closeOnCancel: true
            //   }, (isConfirm) => {
            //     if (isConfirm) {
            //       setTimeout(() => {
            //           window.location.href = "#/home/support?q=" + Encryption.SetQueryParam({
            //               Date : new Date(),
            //               IsFree : true
            //           });
            //       }, 100);
            //     } else {
            //     }
            //   });
        }
    }

    // Notify api error
    public static ApiError_Normal(err: any, translate = null) {
        if (err && err.status == 0) {
            this.options.status = 'warning';
            if (!!translate && !!translate.instant) {
                $.notify(translate.instant(_$config.errorMessageNetwork), this.options);
            } else {
                $.notify(_$config.errorMessageNetwork, this.options);
            }
        } else {
            this.options.status = 'danger';
            if (!!translate && !!translate.instant) {
                $.notify(translate.instant(_$config.errorMessage), this.options);
            } else {
                $.notify(_$config.errorMessage, this.options);
            }
        }
    }
}
