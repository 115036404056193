import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '@core/settings/settings.service';
import { MenuService } from '@core/menu/menu.service';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { MessageType, UserRole } from '@app.enum';
import { SharedService } from '@services/shared.service';
import { AuthService } from '@services/admin/auth.service';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';

 @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    session: AppSession;
    locations: Array<any>;
    loggingOut: boolean;

    isNavSearchVisible: boolean;
    @ViewChild('fsbutton') fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService,
        private _sharedService: SharedService,
        public _router: Router,
        public userblockService: UserblockService,
        public settings: SettingsService,
        private _auth: AuthService,
        private dbservice: IndexedDbService) {

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        this.getSession();

        this._sharedService.refreshToken.subscribe((data) => {
            this.getSession();
        });
    }

    ngOnInit() {
        this.isNavSearchVisible = false;
        if (browser.msie) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }
    }

    getSession() {
        this.session = Application.GetSession();
        // if (this.session.UserRoleId == UserRole.ClientAdmin || this.session.UserRoleId == UserRole.Client) {
        //     this.setClientRole(this.session.ClientRoleId);
        // }
        this.setClientRole(this.session.ClientRoleId);
    }

    changeClientSession() {
        Application.Remove("ClientId");
        this._router.navigate(['mySetu', 'admin', 'select-company']);
    }

    setLocation(locationId, refreshToken: boolean = false) {
        Application.Set("LocationId", locationId);

        var selectedLocation = this.locations.filter(x => x.EnterpriseMasterId == locationId);
        if (!!selectedLocation && selectedLocation.length > 0) {
            this.session.TimezoneId = selectedLocation[0].TimezoneId;
            Application.Set("TimezoneId", this.session.TimezoneId);
        }
        //window.location.reload();
        this.reloadPage(refreshToken);
    }

    reloadPage(refreshToken: boolean) {
        var path = window.location.hash.split('/').join('♥').replace('#♥', '');
        var pathArray = ['home', 'redirect', path];
        if (refreshToken) {
            this._sharedService.refreshAccessToken(false, pathArray);
        }
    }

    setClientRole(clientRoleId, refreshToken: boolean = false) {
        Application.Set("ClientRoleId", clientRoleId);
        this.locations = this.session.Locations.filter(x => !x.ClientRoleId || x.ClientRoleId == clientRoleId);

        var _locationIdString = Application.Get("LocationId");
        var locationId = 0;
        if (!!_locationIdString) {
            locationId = parseInt(_locationIdString);
        }
        var selectedLocation = this.locations.filter(x => x.EnterpriseMasterId == locationId);
        if ((!selectedLocation || selectedLocation.length == 0) && !!this.locations && this.locations.length > 0) {
            this.session.LocationId = this.locations[0].EnterpriseMasterId;
            this.setLocation(this.session.LocationId, refreshToken);
        } else {
            this.reloadPage(false);
        }
    }

    logout(logOutOverlay) {
        //logOutOverlay.show();
        this.loggingOut = true;
        this._auth.logout().subscribe(
            data => {
                if (data.MessageType == MessageType.Success) {
                    Application.ClearSession();
                    this.dbservice.clearStore();
                    //logOutOverlay.hide();
                    this._router.navigate(['login']);
                } else {
                    this.loggingOut = false;
                }
            },
            err => {
                //logOutOverlay.hide();
                this.loggingOut = false;
            },
            () => {
                //logOutOverlay.hide();
                this.loggingOut = false;
            });
    }

    lock() {
        Application.Set('unlock-to', window.location.hash.replace('#/', ''));
        window.location.hash = "#/lock";
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }

    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }

    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    toggleFullScreen(event) {

        if (screenfull.enabled) {
            screenfull.toggle();
        }
        // Switch icon indicator
        let el = $(this.fsbutton.nativeElement);
        if (screenfull.isFullscreen) {
            el.children('em').removeClass('fa-expand').addClass('fa-compress');
        }
        else {
            el.children('em').removeClass('fa-compress').addClass('fa-expand');
        }
    }
}
