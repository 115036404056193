import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { SSPSSurveyPageComponent } from './pages/ssps-survey-page/ssps-survey-page.component';
import { HHPSSurveyPageComponent } from './pages/hhps-survey-page/hhps-survey-page.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { Error403Component } from './pages/error403/error403.component';
import { AuthGuard } from '../shared/guards/auth-guard.service';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { TaskSchedulerComponent } from './pages/task-scheduler/task-scheduler.component';
import { AdminLayoutComponent } from '../admin-layout/admin-layout.component';

export const routes = [
    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent, data: { code: '#LOGIN' } },
    { path: ':tenant/auth', component: LoginComponent, data: { code: '#LOGIN' } },
    { path: 'ssps/survey/:q', component: SSPSSurveyPageComponent, data: { code: '#SSPS_SURVEY_PAGE' } },
    { path: 'hhps/survey/:q', component: HHPSSurveyPageComponent, data: { code: '#HHPS_SURVEY_PAGE' } },
    { path: 'task-scheduler', component: TaskSchedulerComponent, data: { code: '#TASK_SCHEDULER' } },
    { path: 'recover', component: RecoverComponent, data: { code: '#RECOVER' } },
    { path: 'recover/:ResetPasswordCode', component: RecoverComponent, data: { code: '#RECOVER' } },
    // { path: 'register', component: RegisterComponent, data: { code: '#REGISTER' } },
    // { path: 'register/:PackageId', component: RegisterComponent, data: { code: '#REGISTER' } },
    { path: 'lock', component: LockComponent, data: { code: '#LOCK' } },
    { path: 'maintenance', component: MaintenanceComponent, data: { code: '#MAINTANANCE' } },
    { path: '404', component: Error404Component, data: { code: '#404' } },
    { path: '500', component: Error500Component, data: { code: '#500' } },
    { path: '403', component: Error403Component, data: { code: '#403' } },
    { path: 'invoice/:InvoiceNumber', component: InvoiceComponent, data: { code: '#INVOICE' } },

    // mySetu Admin Panel
    {
        path: 'mySetu',
        component: AdminLayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: 'admin', pathMatch: 'full' },

            { path: 'admin', loadChildren: './admin-config/admin-config.module#AdminConfigModule', data: { menu: 'home', code: '#home-module', isSuperAdmin: true } },

        ]
    },

    {
        path: 'ux-ui',
        component: LayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'widgets', loadChildren: './widgets/widgets.module#WidgetsModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'elements', loadChildren: './elements/elements.module#ElementsModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'forms', loadChildren: './forms/forms.module#FormsModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'charts', loadChildren: './charts/charts.module#ChartsModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'tables', loadChildren: './tables/tables.module#TablesModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'maps', loadChildren: './maps/maps.module#MapsModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'blog', loadChildren: './blog/blog.module#BlogModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'ecommerce', loadChildren: './ecommerce/ecommerce.module#EcommerceModule', data: { menu: 'ui', code: '#ui-module' } },
            { path: 'extras', loadChildren: './extras/extras.module#ExtrasModule', data: { menu: 'ui', code: '#ui-module' } }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: './home/home.module#HomeModule', data: { menu: 'home', code: '#home-module' } },
            { path: 'error', loadChildren: './error/error.module#ErrorModule', data: { menu: 'home', code: '#error-module' } },
            { path: 'administration', loadChildren: './admin/admin.module#AdminModule', data: { menu: 'administration', code: '#admin-module' , preload:true } },
            { path: 'task', loadChildren: './task/task.module#TaskModule', data: { menu: 'task', code: '#task-module' , preload:true } },
            { path: 'bbs', loadChildren: './bbs/bbs.module#BBSModule', data: { menu: 'bbs', code: '#bbs-module' , preload:true} },
            { path: 'ssps', loadChildren: './ssps/ssps.module#SSPSModule', data: { menu: 'ssps', code: '#ssps-module' } },
            { path: 'hhps', loadChildren: './hhps/hhps.module#HHPSModule', data: { menu: 'hhps', code: '#hhps-module' } },
            { path: 'im', loadChildren: './im/im.module#IMModule', data: { menu: 'im', code: '#im-module', preload:true } },
            {path: "support",loadChildren: "./support/support.module#SupportModule",data: { menu: "support", code: "#support-module" }},
        ]
    },

    // Not found
    { path: '**', redirectTo: '404' }

];
