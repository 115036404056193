import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidateContactNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!!control.value) {
            var contactNumberPattern = /^([0-9]{1,3}|([+][0-9]{1,2})){0,2}[- ]?([0-9]{10}|[0-9]{5}[- ][0-9]{5}|[\(\[]?[0-9]{3}[\)\]]?[- ][0-9]{7}|[0-9]{3}[- ][0-9]{4}|([\(\[]?[0-9]{3}[\)\]]?[- ]){2}[0-9]{4}|([0-9]{3}|[\(\[]?[0-9]{3}[\)\]]?)([- ][0-9]{4}){2})$/g;
            var re = new RegExp(contactNumberPattern);
            var isContactNumber = re.test(control.value);
            if (!isContactNumber) {
                return { validContactNumber: true };
            }
        }
    
        return null;
    };
}