import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';
import { Application } from '@utility/utility.application';
import { ApiUtility } from '@utility/utility.api-utility';

// Shift Service
@Injectable()
export class ShiftService {

  constructor(private http: HttpClient) { }

  // Get Shifts
  getShifts(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'shift' + uri);
  }

  // Edit Shift
  editShift(shiftId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'shift/' + shiftId);
  }

  // getShiftAuditTrail
  getShiftAuditTrail(shiftId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'shift/' + shiftId + '/audit-trail');
  }

  // Add Shift
  addShift(shift: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'shift', shift);
  }

  // Update Shift
  updateShift(shift: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'shift/' + shift.ShiftId, shift);
  }

  // Update Shift
  updateShiftStatus(shiftId: number, isActive: boolean) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'shift/' + shiftId + '/status?isActive=' + isActive, null);
  }

  // Delete Shift
  deleteShift(shiftId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'shift/' + shiftId);
  }


downloadshiftReport(format) {
  var api = 'shift/Report/' + format;
  ApiUtility.downloadFile(api, 'ShiftList.' + format);
}

  getHeaders() {
    var headers: any = {};
            headers.Authorization = 'Bearer ' + Application.Get("access_token");

            if (!!Application.Get("ClientId")) {
                headers.ClientId = Application.Get("ClientId");
            }

            if (!!Application.Get("ClientRoleId")) {
                headers.ClientRoleId = Application.Get("ClientRoleId");
            }

            if (!!Application.Get("LocationId")) {
                headers.LocationId = Application.Get("LocationId");
            }

            if (!!Application.Get("TimezoneId")) {
                headers.TimezoneId = Application.Get("TimezoneId");
            } else {
                headers.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
            }

            return headers;
  }


  private downloadFile(api, filename) {
    var headers = this.getHeaders();
    var request = new XMLHttpRequest();
    request.open('GET', _$config.api_endpoint + api, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.setRequestHeader('Authorization', headers.Authorization);
    request.setRequestHeader('ClientId', headers.ClientId);
    request.setRequestHeader('ClientRoleId', headers.ClientRoleId);
    request.setRequestHeader('LocationId', headers.LocationId);
    request.setRequestHeader('TimezoneId', headers.TimezoneId);
    request.responseType = 'blob';

    request.onload = function () {
      // Only handle status code 200
      if (request.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        // var disposition = request.getResponseHeader('content-disposition');
        // var matches = /"([^"]*)"/.exec(disposition);
        // var filename = (matches != null && matches[1] ? matches[1] : 'file.' + format);

        // The actual download
        var blob = new Blob([request.response], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }

      // some error handling should be done here...
    };

    request.send();
  }
}
