import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// IM Nature of Injury Service
@Injectable()
export class IMNatureOfInjuryService {

    constructor(private http: HttpClient) { }

    // Get IMTemplate
    getIMNatureOfInjuryType(page: Pagination = null) {
        let uri: string = "";
        if (!!page) {
            uri = page.GetUri();
        }
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + uri);
    }


    // Get IM Nature of Injury Type
    getNatureOfInjuryType(page: Pagination) {
        let uri: string = page.GetUri();
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + uri);
    }

    // Edit IM Nature of Injury  Type
    editIMNatureOfInjuryType(natureOfInjuryTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + natureOfInjuryTypeId);
    }

    // Add IM Nature of Injury Type
    addIMNatureOfInjuryType(natureOfInjuryType: any) {
        return this.http.post<iApiResponse>(_$config.api_endpoint + 'nature-of-injury', natureOfInjuryType);
    }

    // Update Nature of Injury type
    updateNatureOfInjuryType(natureOfInjuryType: any) {
        return this.http.put<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + natureOfInjuryType.natureOfInjuryTypeId, natureOfInjuryType);
    }

    // Delete Nature of Injury Type
    deleteNatureOfInjuryType(natureOfInjuryTypeId: number) {
        return this.http.delete<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + natureOfInjuryTypeId);
    }

      // get Nature Of Injury Audit Trail
      getNatureOfInjuryTypeAuditTrail(natureOfInjuryTypeId: number) {
        return this.http.get<iApiResponse>(_$config.api_endpoint + 'nature-of-injury' + natureOfInjuryTypeId + '/audit-trail');
    }

}
