import { Component, OnInit, OnDestroy, ViewEncapsulation, TemplateRef } from '@angular/core';
import { MessageType } from '@app.enum';
import { Notify } from '@utility/utility.notify';
import { PaymentConfigService } from '@services/admin/payment-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

const _clone = (d) => JSON.parse(JSON.stringify(d));

 @Component({
    selector: 'invoice-div',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class InvoiceComponent implements OnInit, OnDestroy {

    //initialization
    invoice: any = {
        Order: {
            OrderItem: []
        }
    };
    invoiceConfig: any = {
    }
    adminConfig: any = {
    }
    loading: boolean = false;;
    InvoiceNumber: any;

    constructor(
        public _invoice: PaymentConfigService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService) {
        this.route.params.subscribe(params => {
            this.InvoiceNumber = !!params.InvoiceNumber ? params.InvoiceNumber : 0;
            //this.InvoiceNumber = this.InvoiceNumber.replace(new RegExp('-', 'g'), '/');
        });
    }

    ngOnInit() {
        $('body').addClass('print-page');
        this.getInvoiceDetail();
    }

    ngOnDestroy() {
        $('body').removeClass('print-page');
    }

    print() {
        window.print();
    }

    back() {
        this.loading = true;
        this.router.navigate(['/', 'administration', 'transactions']);
    }


    getInvoiceDetail() {
        this.loading = true;
        this._invoice.getInvoiceConfig(this.InvoiceNumber).subscribe(
            data => {
                this.loading = false;
                if (data.MessageType == MessageType.Success) {
                    this.invoice = data.Result.Invoice;
                    this.invoiceConfig = data.Result.InvoiceConfig;
                    this.adminConfig = data.Result.AdminConfig;
                }
            },
            err => {
                this.loading = false;
                Notify.ApiError(err, this.translate);
            },
            () => {
                this.loading = false;
            }
        );
    }

}
