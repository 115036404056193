import { Application } from "./utility.application";

export class ApiUtility {
  private static getHeaders() {
    var headers: any = {};
    headers.Authorization = "Bearer " + Application.Get("access_token");

    if (!!Application.Get("ClientId")) {
      headers.ClientId = Application.Get("ClientId");
    }

    if (!!Application.Get("ClientRoleId")) {
      headers.ClientRoleId = Application.Get("ClientRoleId");
    }

    if (!!Application.Get("LocationId")) {
      headers.LocationId = Application.Get("LocationId");
    }

    if (!!Application.Get("TimezoneId")) {
      headers.TimezoneId = Application.Get("TimezoneId");
    } else {
      headers.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
    }

    return headers;
  }

  public static downloadFile(api, filename) {
    var headers = this.getHeaders();
    var request = new XMLHttpRequest();
    request.open("GET", _$config.api_endpoint + api, true);
    request.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    request.setRequestHeader("Authorization", headers.Authorization);
    request.setRequestHeader("ClientId", headers.ClientId);
    request.setRequestHeader("ClientRoleId", headers.ClientRoleId);
    request.setRequestHeader("LocationId", headers.LocationId);
    request.setRequestHeader("TimezoneId", headers.TimezoneId);
    request.responseType = "blob";
    request.onload = function () {
      // Only handle status code 200
      if (request.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        // var disposition = request.getResponseHeader('content-disposition');
        // var matches = /"([^"]*)"/.exec(disposition);
        // var filename = (matches != null && matches[1] ? matches[1] : 'file.' + format);

        // The actual download
        if (filename.split(".")[1] == "csv") {
          var blob = new Blob([request.response], {
            type: "text/csv",
          });
        }
        else if (filename.split(".")[1] == "xlsx") {
          var blob = new Blob([request.response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        } else {
          var blob = new Blob([request.response], { type: "application/pdf" });
        }
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      // some error handling should be done here...
    };
    request.send();
  }

  public static downloadStaticFile(url, filename) {
    var headers = this.getHeaders();
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    request.responseType = "blob";

    request.onload = function () {
      // Only handle status code 200
      if (request.status === 200) {
        // Try to find out the filename from the content disposition `filename` value
        // var disposition = request.getResponseHeader('content-disposition');
        // var matches = /"([^"]*)"/.exec(disposition);
        // var filename = (matches != null && matches[1] ? matches[1] : 'file.' + format);

        // The actual download
        var blob = new Blob([request.response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      }

      // some error handling should be done here...
    };
    request.send();
  }
}
