import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { DaterangepickerDirective } from "ngx-daterangepicker-material";
import { MessageType } from "@app.enum";
import { EmployeeService } from "@services/admin/employee.service";
import { Notify } from "@utility/utility.notify";
import { TranslateService } from "@ngx-translate/core";
import { Pagination } from "@interface/interface.pagination";
import { TrendService } from "@services/trend/trend.service";
import { AppSession } from "@interface/interface.app-session";
import { Application } from "@utility/utility.application";
import { IMIncidentClassificationService } from "@services/im/im-incidentclassification.service";
import { IMNatureOfInjuryService } from "@services/im/im-natureofinjury.service";
import { IMIncidentTypeService } from "@services/im/im-incidenttype.service";
import { ImPsmService } from "@services/im/im-psm.service";
import { ImIncidentcauseService } from "@services/im/im-incidentcause.service";
import { IMInvestigationService } from "@services/im/im-invesgtigation.service";
import { BBSObservationCategoryService } from "@services/bbs/bbs-observationcategory.service";
import { BBSObservationTypeService } from "@services/bbs/bbs-observationtype.service";
import { Observable, of } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
const _clone = (d) => JSON.parse(JSON.stringify(d));
@Component({
  selector: "dynamic-app-trend",
  templateUrl: "./trend.component.html",
  styleUrls: ["./trend.component.scss"],
})
export class TrendComponent implements OnInit {
  currentUrl: any;
  Title: any;
  isApplyFilter: boolean = false;
  loading: boolean = false;
  entryFormClass: any;
  obsTypeCount: any = [];
  @Input() componebtDrillDown = false;
  @Output() attachments = new EventEmitter();
  graphLoad = false;
  level = 0;
  chartObj: any;
  injuryNatureType: any[];
  CountDataSource: any;
  chartType: string = "msline";
  DrillDownChartType: string = "line2d";
  width = "100%";
  height = "400";
  IncidentCatagoryList: Object[];
  employeeList: any = [];
  dataFormat = "json";
  PSMList: any;
  today = new Date();
  innerHeight = 300;
  graphData = {} as any;
  availablecolumn: any[] = [];
  availablecolumnKeys: any[] = [];
  page = new Pagination();
  filteredkeys: any[] = [];
  session: AppSession;
  graphType = "";
  incidentTypeList: any[];
  location: any;
  note: any
  locations1: Array<any>;
  BehaviouralIssueList: any;
  employeeId: any;
  search: any;
  @Input() dynamiId = "";
  ShowList = [

    { ShowListId: 'Last_7_Days', name: 'Last 7 Days' },
    { ShowListId: 'Last_30_Days', name: 'Last 30 Days' },
    { ShowListId: 'This_month', name: 'This month' },
    { ShowListId: 'Last_month', name: 'Last_month' },
    { ShowListId: 'Last_3_Months', name: 'Last 3 Months' },
    { ShowListId: 'Last_6_Months', name: 'Last 6 Months' },
    { ShowListId: 'This_Quarter', name: 'This quarter' },
    { ShowListId: 'Last_Quarter', name: 'Last quarter' },
    { ShowListId: 'This_Year', name: 'This Year' }
  ];
  selectedDate: any;
  lastSevenDays: Date;
  selectedValue: string = "Last_7_Days";
  psmelementlist: any;
  categories: Array<any> = [];
  dateRange = _$config.dateRange;
  handler: any;
  filterShown: any;
  trendSearchFilter: any = {
    fromDate: this.dateRange.startDate,
    toDate: this.dateRange.endDate,
    enterpriseMasterIds: [],
    facilityMasterIds: [],
    incidentPSMCategory: [],
    pSMElements: [],
    pSMSubelements: [],
    incidentTypeIds: [],
    natureofInjurieIds: [],
    IncidentClassficationIds: [],
    bOobservations: "",
    natureOfInjuryIds: [],
    incidentCauseIds: [],
    operationalCauseIds: [],
    observationTypeIds: [],
    observationCategoryIds: [],
  };
  dateRangeConfig = Object.assign(_clone(_$config.datepickerConfig), {
    maxDate: this.today,
  });
  dateFormat: string = _$config.dateFormat;
  @ViewChild(DaterangepickerDirective)
  pickerDirective: DaterangepickerDirective;
  psmsubelementlist: any;
  incidentCauseList: any;
  operationalCauseList: any;
  @Input() dashboardUrl: any;
  filter: FormGroup;
  @Output() showGraph = new EventEmitter();
  obsTypes: any;
  constructor(
    private router: Router,
    public fb: FormBuilder,
    private _imTrendService: TrendService,
    private investiagtionService: IMInvestigationService,
    private _incidentClassification: IMIncidentClassificationService,
    public employeeService: EmployeeService,
    private _natureOfInjuryServeice: IMNatureOfInjuryService,
    private _incidentTypeService: IMIncidentTypeService,
    public _impsm: ImPsmService,
    private imIncidentcauseService: ImIncidentcauseService,
    public _ObsCategory: BBSObservationCategoryService,
    public _ObsType: BBSObservationTypeService,
    private zone: NgZone,
  ) {
    this.session = Application.GetSession();
    this.location = this.session.LocationId;
    this.setScreenWidth($(window).width());
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.currentUrl = e["url"].substring(1);
      });


  }

  ngOnInit() {
    if (this.dashboardUrl) {
      this.currentUrl = this.dashboardUrl;
    }
    this.callMaster();
    this.getChartdata();
    console.log(this.dateRange.startDate);

  }



  ngAfterViewInit() { }

  onSelect(data: any) {
    this.trendSearchFilter.facilityMasterIds = data;
  }

  getChartdata() {
    this.loading = true;
    this.setFilterLogic();
    this._imTrendService
      .getChartDetails(this.currentUrl, this.trendSearchFilter)
      .subscribe((data) => {
        this.setTrendGraphLogic(data);
      });
  }

  setTrendGraphLogic(data: any) {
    this.Title = data.Result.data.Title;
    if (data.MessageType == MessageType.Success) {
      this.graphData = this._imTrendService.getGraphData(
        data.Result.data,
        data.Result.data.GraphType
      );

      this.note = data.Result.data.Note
      this.availablecolumn = data.Result.Colummn
        ? _clone(data.Result.Colummn)
        : [];
      this.filteredkeys = Object.keys(this.availablecolumn).filter(
        (k) => this.availablecolumn[k]
      );
      this.filterShown = data.Result.Filters ? _clone(data.Result.Filters) : [];
      this.availablecolumnKeys = Object.keys(this.availablecolumn).map(
        (val, index) => (val != null ? { value: val } : { value: val })
      );
      this.graphType = data.Result.data.GraphType;
      if (this.graphType === "Singleline") {
        this.chartType = data.Result.data.Type || "column2d";
        this.Setloading();
      } else {
        this.chartType = data.Result.data.Type || "msline";
        this.changeGraphType();
      }
      this.isApplyFilter = false;
      this.loading = false;
    }
  }

  openfilter(): void {
    this.isApplyFilter = true;
  }
  closeFilter(): void {
    this.isApplyFilter = false;
    this.setScreenWidth($(window).width());
  }

  openDatepicker(): void {
    this.pickerDirective.open();
  }

  onResize(event): void {
    this.innerHeight = event.target.innerHeight - 115;
  }

  setScreenWidth(width): void {
    this.entryFormClass = {
      "offsidebar mb-0 wp-lg-3 wp-md-5 wp-sm-6 wp-xs-8": width > 768,
      "card card-default": this.isApplyFilter,
    };
  }

  Setloading(): void {
    this.loading = true;
    setTimeout((x) => (this.loading = false), 500);
    switch (this.chartType) {
      case "msline":
        this.DrillDownChartType = "msline";
        break;
      case "mscolumn2d":
        this.DrillDownChartType = "column2d";
        break;
      case "mscolumn3d":
        this.DrillDownChartType = "column3d";
        break;
      case "msbar2d":
        this.DrillDownChartType = "bar2d";
        break;
      case "msbar3d":
        this.DrillDownChartType = "bar3d";
        break;
      default:
        break;
    }
  }
  dataUpdated($event) {
    this.showGraph.emit(this.graphData.chart.caption);
  }

  setFilterLogic() {
    this.trendSearchFilter.fromDate = moment(this.dateRange.startDate).format(
      "YYYY-MM-DD"
    );
    this.trendSearchFilter.toDate = moment(this.dateRange.endDate).format(
      "YYYY-MM-DD"
    );
  }

  callMaster() {
    this.locations1 = this.session.Locations;
    this.PSMList = [
      { PSMTypeName: "Manage For Continuous Improvement - C", PSMType: 3 },
      { PSMTypeName: "Incorporate Risk Reduction Measure - B", PSMType: 2 },
      { PSMTypeName: "Intoreable - A", PSMType: 1 },
    ];
    this.BehaviouralIssueList = [
      { Name: "Reactions of People", IssueTypeId: 1 },
      { Name: "Positions of People", IssueTypeId: 2 },
      { Name: "PPE", IssueTypeId: 3 },
      { Name: "Tools/Equipment", IssueTypeId: 4 },
      { Name: "Procedures", IssueTypeId: 5 },
      { Name: "Orderliness Standards", IssueTypeId: 6 },
    ];
    this._incidentClassification
      .getIMIncidentClassificationType()
      .subscribe((data) => {
        this.IncidentCatagoryList = data.Result;
      });

    this._natureOfInjuryServeice.getIMNatureOfInjuryType().subscribe((data) => {
      this.injuryNatureType = data.Result.filter((e) => e.isActive !== false);
    });
    this._incidentTypeService.getIMIncidentType().subscribe((data) => {
      this.incidentTypeList = data.Result.filter((e) => e.isActive !== false);
    });
    this._impsm.getPSMElements().subscribe(
      (data) => {
        if (data.MessageType == MessageType.Success) {
          this.psmelementlist = _clone(data.Result);
        }
      },
      (err) => {
        console.log(err);
      },
      () => { }
    );
    this._impsm.getPSMElementsChecklist().subscribe(
      (data) => {
        if (data.MessageType == MessageType.Success) {
          this.psmsubelementlist = _clone(data.Result);
        }
      },
      (err) => {
        console.log(err);
      },
      () => { }
    );
    this.imIncidentcauseService.getIMIncidentCause().subscribe((data) => {
      this.incidentCauseList = data.Result.filter((e) => e.isActive !== false);
    });
    this.investiagtionService.getOperationalCause().subscribe((data) => {
      this.operationalCauseList = data.Result.filter(
        (e) => e.isActive !== false
      );
    });
    this._ObsCategory.getBBSObservationCategory().subscribe((res) => {
      if (res.MessageType == MessageType.Success) {
        this.categories = _clone(res.Result);
      }
    });
    this._ObsType.getBBSObservationType().subscribe((res) => {
      if (res.MessageType == MessageType.Success) {
        this.obsTypes = _clone(res.Result);
      }
    });
  }

  initialized($event) {
    this.chartObj = $event.chart;
    this.attachEvent();
  }

  attachEvent() {
    this.handler = this.dataplotClickHandler.bind(this);
    this.chartObj.addEventListener("dataplotClick", this.handler);
  }

  dataplotClickHandler(eventObj, dataObj) {
    this.zone.run(() => {
      if (this.componebtDrillDown) {
        this.attachments.emit(dataObj.link);
      }
      else {
        const items = dataObj.link.split("?");
        // this.setParam(dataObj);
      }
    });

  }

  changeGraphType() {
    this.graphLoad = false;
    setTimeout((x) => (this.graphLoad = true), 500);
  }
  ngOndestroy() {
    console.log();
  }

  onSelectionChange(event: any) {
    this.selectedValue = event.ShowListId; // Assuming event contains the selected value from log
    console.log(this.selectedValue);

    if (this.selectedValue === "Last_7_Days") {
      this.dateRange = ({
        startDate: moment().subtract(7, "days"),
        endDate: moment(),
      });
    }
    if (this.selectedValue === 'This_month') {
      this.dateRange = ({
        startDate: moment().date(1),
        endDate: moment()
      })

      // this.dateRange.updateValueAndValidity();
      console.log(this.dateRange.startDate);
    }
    if (this.selectedValue === 'Last_month') {
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
      const endOfLastMonth = moment().subtract(1, 'month').endOf('month');
      this.dateRange = ({
        startDate: startOfLastMonth,
        endDate: endOfLastMonth
      })


    }

    if (this.selectedValue === 'Last_30_Days') {
      this.dateRange = ({
        startDate: moment().subtract(30, 'days'),
        endDate: moment()
      })


    }

    if (this.selectedValue === 'This_Quarter') {
      const currentQuarter = moment().quarter();
      const startOfQuarter = moment()
        .quarter(currentQuarter)
        .startOf("quarter");
      const endOfQuarter = moment().quarter(currentQuarter).endOf("quarter");

      this.dateRange = ({
        startDate: startOfQuarter,
        endDate: endOfQuarter

      })
    }

    if (this.selectedValue === 'This_Year') {
      this.dateRange = ({
        startDate: moment().subtract(365, "days"),
        endDate: moment()
      })

      // Get the current year
      const currentYear = moment().year();
      console.log("Current Year:", currentYear);
    }

    if (this.selectedValue === 'Last_Quarter') {
      const currentQuarter = moment().quarter();
      const lastQuarter = currentQuarter - 1 < 1 ? 4 : currentQuarter - 1; // Determine last quarter
      const startOfLastQuarter = moment()
        .quarter(lastQuarter)
        .startOf("quarter");
      const endOfLastQuarter = moment().quarter(lastQuarter).endOf("quarter");

      this.dateRange = ({
        startDate: startOfLastQuarter,
        endDate: endOfLastQuarter
      })


    }

    if (this.selectedValue === 'Last_3_Months') {
      const currentQuarter = moment().quarter();
      const lastQuarter = currentQuarter - 1 < 1 ? 4 : currentQuarter - 1; // Determine last quarter
      const currentMonth = moment().month();
      const lastThreeMonthsStart = moment().subtract(3, 'months').startOf('month');
      const lastThreeMonthsEnd = moment().subtract(1, 'months').endOf('month');
      this.dateRange = ({
        startDate: lastThreeMonthsStart,
        endDate: lastThreeMonthsEnd
      })

    }

    if (this.selectedValue === 'Last_6_Months') {
      const lastSixMonthsStart = moment().subtract(6, 'months').startOf('month');
      const lastSixMonthsEnd = moment().subtract(1, 'months').endOf('month');
      this.dateRange = ({
        startDate: lastSixMonthsStart,
        endDate: lastSixMonthsEnd
      })

    }
    else {
      this.selectedDate = null;
      this.lastSevenDays = null;
    }

    console.log(this.dateRange);
    console.log(this.dateRange.endDate._d);
    console.log(this.trendSearchFilter);

  }


}
