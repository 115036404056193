import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Application } from '@utility/utility.application';
import { Encryption } from '@utility/utility.encryption';
import { IndexedDbService } from '../services/indexed-db.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(private _router: Router, private dbservice:IndexedDbService) {

        (function (send) {

            XMLHttpRequest.prototype.send = function (data) {
                if (
                    this.__zone_symbol__xhrURL.indexOf(_$config.api_endpoint + 'ssps/report/survey/job/location/facility-') >= 0
                    || this.__zone_symbol__xhrURL.indexOf(_$config.api_endpoint + 'hhps/report/survey/job/location/facility-') >= 0
                    || this.__zone_symbol__xhrURL.indexOf(_$config.api_endpoint + 'admin-report/drill-down/') >= 0
                ) {
                    this.setRequestHeader('Authorization', 'Bearer ' + Application.Get("access_token"));
                    this.setRequestHeader('ClientId', Application.Get("ClientRoleId"));
                    this.setRequestHeader('LocationId', Application.Get("LocationId"));
                    if (!!Application.Get("TimezoneId")) {
                        this.setRequestHeader('TimezoneId', Application.Get("TimezoneId"));
                    } else {
                        this.setRequestHeader('TimezoneId', /\((.*)\)/.exec(new Date().toString())[1]);
                    }

                    this.setRequestHeader('TimezoneId', Application.Get("TimezoneId"));

                    if (!!Application.Get("Accept-Language")) {
                        this.setRequestHeader('Accept-Language', Application.Get("Accept-Language"));
                    }
                }
                send.call(this, data);
            };
        })(XMLHttpRequest.prototype.send);

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const startTime = Date.now();
        let status: string;

        if (
            req.url.indexOf(_$config.api_endpoint) >= 0
            && req.url.indexOf(_$config.api_endpoint + 'private-api/windows-service') < 0
            && req.url != _$config.api_endpoint + 'auth/access_token'
            && req.url != _$config.api_endpoint + 'client/register') {

            var headers: any = {};
            headers.Authorization = 'Bearer ' + Application.Get("access_token");

            if (!!Application.Get("ClientId")) {
                headers.ClientId = Application.Get("ClientId");
            }

            if (!!Application.Get("ClientRoleId")) {
                headers.ClientRoleId = Application.Get("ClientRoleId");
            }

            if (!!Application.Get("LocationId")) {
                headers.LocationId = Application.Get("LocationId");
            }

            if (!!Application.Get("Accept-Language")) {
                headers["Accept-Language"] = Application.Get("Accept-Language");
            }

            if (!!Application.Get("TimezoneId")) {
                headers.TimezoneId = Application.Get("TimezoneId");
            } else {
                headers.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
            }

            req = req.clone({ setHeaders: headers });
        } else {
            if (req.url.indexOf('openexchangerates') >= 0) {

            } else {
                var headers: any = {};
                headers.TimezoneId = /\((.*)\)/.exec(new Date().toString())[1];
                req = req.clone({ setHeaders: headers });
            }
        }

        return next.handle(req).pipe(
            tap(
                event => {
                    status = '';
                    if (event instanceof HttpResponse) {
                        status = 'succeeded';
                    }
                },
                error => {
                    if ([400, 401].indexOf(error.status) >= 0) {
                        Application.ClearSession();
                        this.dbservice.clearStore();
                        let param;
                        let url = window.location.hash.replace('#/', '');
                        if (!!url) {
                            param = Encryption.SetQueryParam(url);
                        }
                        // if (!!param) {
                        //     this._router.navigate(['login'], { queryParams: { p: param } });
                        // } else {
                            this._router.navigate(['login']);
                        // }
                    }
                    status = 'failed'
                }
            ),
            finalize(() => {
                const elapsedTime = Date.now() - startTime;
                const message = req.method + " " + req.urlWithParams + " " + status
                    + " in " + elapsedTime + "ms";

                this.logDetails(message);
            })
        );
    }

    private logDetails(msg: string) {
    }
}
