import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { MessageType } from '@app.enum';
import { AuthService } from '@services/admin/auth.service';
import { Notify } from '@utility/utility.notify';
import { Encryption } from '@utility/utility.encryption';
import { HHPSSurveyService } from '@services/admin/hhps-survey.service';
import { TranslateService } from '@ngx-translate/core';
const _clone = (d) => JSON.parse(JSON.stringify(d));
 @Component({
  selector: 'app-hhps-survey-page',
  templateUrl: './hhps-survey-page.component.html',
  styleUrls: ['./hhps-survey-page.component.scss']
})
export class HHPSSurveyPageComponent implements OnInit {

  // initialization

  currentQuestionId: number = -1;
  currentLanguageId: number = -1;
  loading: boolean = false;
  loadingQuestions: boolean = false;
  surveyUi: number = 1; // 1: Initial, 2: Questions, 3: Completed
  canStartSurvey:boolean= true;
  link: string;
  data: any = {
    Survey: {},
    Schedule: {},
    Employee: {},
    Company: {},
    Languages: []
  };
  surveyResponses = [];
  allQuestionCovered: boolean = false;
  message:string;


  constructor(
    public settings: SettingsService,
    fb: FormBuilder,
    private router: Router,
    private _survey: HHPSSurveyService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
    this.allQuestionCovered = false;
    this.route.params.subscribe(params => {
      this.link = params.q;
      this.getSurveyData(this.link);
      //fnezloxb8fpxbtf49iq895b9rmtnbfg8gqr8eh7yn58tycip1t0
    });
  }

  // get language
  getSurveyData(link: string) {
    this.loading = true;
    this._survey.getSurveyDetailsByLink(link, false).subscribe(
      data => {
        if (data.MessageType == MessageType.Error) {
          this.router.navigate(['404']);
        } else {
          this.data = _clone(data.Result);
          this.currentLanguageId = this.data.Languages[0].LanguageId;
          this.loading = false;
          if (data.MessageType == MessageType.Warning) {
            this.canStartSurvey = false;
            this.message = data.Message;
          } else if (data.MessageType == MessageType.Success) {
            this.canStartSurvey = true;
            this.getSurveyDataWithQuestion(link);
          }
        }
      },
      err => {
      },
      () => {
      });
  }

  // get language
  getSurveyDataWithQuestion(link: string) {
    this.loadingQuestions = true;
    this._survey.getSurveyDetailsByLink(link, true).subscribe(
      data => {
        if (data.MessageType == MessageType.Success) {
          this.data = _clone(data.Result);
          this.loadingQuestions = false;
        }
      },
      err => {
      },
      () => {
      });
  }

  setResponses() {

    this.surveyResponses = [];
    this.allQuestionCovered = true;
    this.data.Survey.SurveySection.forEach(section => {
      section.Question.forEach(question => {

        if (!question.AnswerId && !!this.allQuestionCovered) {
          this.allQuestionCovered = false;
          this.currentQuestionId = question.QuestionId;
        }

        this.allQuestionCovered
        this.surveyResponses.push({
          QuestionId: question.QuestionId,
          AnswerId: question.AnswerId,
          EmployeeId: this.data.Employee.EmployeeId,
          SurveyInvitationId: this.data.Schedule.SurveyInvitationId,
          IsSkipped: false,
          AnswerText: '',
          IsActive: true
        });
      });
    });
  }

  saveResponse() {
    this.setResponses();
    if (this.allQuestionCovered) {

      this._survey.saveSurveyResponse(this.data.Schedule.SurveyScheduleId, this.surveyResponses).subscribe(
        data => {
          if (data.MessageType == MessageType.Success) {
            this.surveyUi = 3;
          } else {
            Notify.Error(data.Message);
          }

        },
        err => {
          Notify.ApiError_Normal(err, this.translate);
        },
        () => {
        });
    } else {
      this.scrollTo(this.currentQuestionId);
      Notify.Warning('All questions are required');
    }
  }

  scrollTo(questionId:number) {
    this.currentQuestionId = questionId;
    // inside ngAfterViewInit() to make sure the list items render or inside ngAfterViewChecked() if you are anticipating live data using @Inputs
    const itemToScrollTo = document.getElementById('question-' + questionId);
    // null check to ensure that the element actually exists
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  ngOnInit() {
    //document.body.classList.add("setu-bg");
  }

  ngOnDestroy() {
    //document.body.classList.remove("setu-bg");
  }

}
