import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// Incident Service
@Injectable()
export class IncidentService {

  constructor(private http: HttpClient) { }


  // Get IMTemplate
  // getIncident(page:any) {
  //     let uri: string = "";
  //     if (!!page) {
  //         uri = page.GetUri();
  //     }
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident' + uri);
  // }

  // Get Employees
  getIncident(page: Pagination,currentuser, filter) {

    var query = this.getIncidentParams(filter);
    let uri: string = page.GetUri() + query.replace('?', '&');
    console.log(_$config.api_endpoint + 'incident' + uri);
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident' + uri+'&currentuser='+currentuser);
  }

  deleteIncident(id: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'incident/' + id);
  }
  //
  //
  getEmployee(page?: Pagination, search?:any){
    if(page){
    let uri: string = page.GetUri();
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + '&Search=' + (!!search ?search :''))
    }
    else{
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee')
    }
  }
  getIncidentParams(filter: any) {
    let query = '';
    const param = [];
    if (!!filter.incidentOccureFromandToDate && filter.incidentOccureFromandToDate.length == 2) {
      param.push("FromDate=" + moment(filter.incidentOccureFromandToDate[0]).format('YYYY-MMM-DD 00:00:00'));
      param.push("ToDate=" + moment(filter.incidentOccureFromandToDate[1]).format('YYYY-MMM-DD 23:59:59'));

    }
    if (!!filter.incidentOccureFromandToDate.startDate) {
      param.push('FromDate=' + moment(filter.incidentOccureFromandToDate.startDate).format('YYYY-MMM-DD 00:00:00'));
      param.push('toDate=' + moment(filter.incidentOccureFromandToDate.endDate).format('YYYY-MMM-DD 23:59:59'));

    }
    if (!!filter.SectorIds) {
      param.push('toDate=' + filter.SectorIds);
    }
    //edit
    // if (!!filter.FacilityMasterId) {
    //   param.push("FacilityMasterIds=" + filter.FacilityMasterId)
    // }
    if (!!filter.FacilityMasterId && filter.FacilityMasterId.length > 0) {
      for (let index = 0; index < filter.FacilityMasterId.length; index++) {
        param.push("FacilityMasterIds=" + filter.FacilityMasterId[index]);
      }
    }


    if (!!filter.ReferenceNo) {
      param.push("ReferenceNo=" + filter.ReferenceNo);
    }
    //
    // ReportedBy: 1,2,3
    //
    if (!!filter.ReportedBy) {
      // if(filter.ReportedBy.length > 1){
      // param.push("ReportedBy=" +"["+ filter.ReportedBy +"]" );
      // }
      // if(filter.ReportedBy.length == 1){
      filter.ReportedBy.forEach(element => {
        param.push("ReportedBy=" + element);

      });
      // }
    }
    if (!!filter.ShiftId) {
      if (filter.ShiftId.length > 1) {
        param.push("ShiftId=" + "[" + filter.ShiftId + "]");
      }
      if (filter.ShiftId.length == 1) {
        param.push("ShiftId=" + filter.ShiftId);
      }
    }
    if (!!filter.IncidentStatusId) {
      if (filter.IncidentStatusId.length > 1) {
        param.push("IncidentStatusId=" + "[" + filter.IncidentStatusId + "]");
      }
      if (filter.IncidentStatusId.length == 1) {
        param.push("IncidentStatusId=" + filter.IncidentStatusId);
      }
    }
    if (!!filter.IncidentCategoryId) {
      // if(filter.IncidentCategoryId.length > 1){
      // param.push("IncidentCategoryId=" +"["+ filter.IncidentCategoryId +"]" );
      // }
      // if(filter.IncidentCategoryId.length == 1){
      filter.IncidentCategoryId.forEach(element => {
        param.push("IncidentCategoryId=" + element);
      });

      // }
    }
    if (!!filter.Description) {
      param.push("IncidentDescription=" + filter.Description);
    }
    //edit end
    if (param.length > 0) {
      query = '&' + param.join('&');
    }

    return query;
  }
  //get category
  getCategory() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident-category');
  }
  //get incident status
  getIncidentStatus() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'status');
  }

  getincidentDetailsbyId(id: any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident/' + id + '/recommendation/print');
  }
  
  getDailyIncidentReportData(filter) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'im/reports/get-daily-incident-report', { params: filter });
  }


  // Get IM Incident
  // getIncidentType(page: Pagination) {
  //     let uri: string = page.GetUri();
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident' + uri);
  // }

  // Edit IM Incident
  // editIMIncidentType(incidentTypeId: number) {
  //     return this.http.get<iApiResponse>(_$config.api_endpoint + 'incident' + incidentTypeId);
  // }

  // Add IM Incident
  // addIMIncidentType(incidentType: any) {
  //     return this.http.post<iApiResponse>(_$config.api_endpoint + 'incident', incidentType);
  // }

  // Update Incident
  // updateIncident(incident: any) {
  //     return this.http.put<iApiResponse>(_$config.api_endpoint + 'incident' + incident.incidentId, incident);
  // }

  // Delete Incident
  // deleteIncidentType(incidentTypeId: number) {
  //     return this.http.delete<iApiResponse>(_$config.api_endpoint + 'incident' + incidentTypeId);
  // }
    getViewInvestigation(id: any){
      return this.http.get<iApiResponse>(_$config.api_endpoint + 'get-incident-investigation-detailbyid' + id );
   }
}


