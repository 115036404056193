import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

// BBS Observation Type Service
@Injectable()
export class BBSObservationTypeService {

  constructor(private http: HttpClient) { }

  // Get BBSTemplate
  getBBSObservationType(page: Pagination = null) {
    let uri: string = "";
    if (!!page) {
      uri = page.GetUri();
    }
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type' + uri);

  }

  // Edit BBS Observation Type
  editBBSObservationType(observationTypeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type/' + observationTypeId);
  }

  // getObservationTypeAuditTrail
  getObservationTypeAuditTrail(observationTypeId: number) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type/' + observationTypeId + '/audit-trail');
  }

  // Add BBS Observation Type
  addBBSObservationType(observationType: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type', observationType);
  }

  // Update Observation type
  updateObservationType(observationType: any) {
    return this.http.put<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type/' + observationType.ObservationTypeId, observationType);
  }

  // Delete Observation Type
  deleteObservationType(observationTypeId: number) {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'bbs/observation-type/' + observationTypeId);
  }

}