import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';

// Shift Service
@Injectable()
export class mySetuMenuService {

  constructor(private http: HttpClient) { }

  // Get Menu
  getMenu(menuName: string, search: string = '') {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'menu/' + menuName + (!!search ? '?search=' + search : ''));
  }

  // Get All Menu Links
  getAllMenuLinks() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'menu/all-link');
  }

  // Get RBAC Rules by path
  getRBAC(code: string) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'menu/rbac-check?code=' + code);
  }
}