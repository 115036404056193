import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FacilityService } from '@services/admin/facility.service';
import { MessageType } from '@app.enum';
import { Notify } from '@utility/utility.notify';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';
const _clone = (d) => JSON.parse(JSON.stringify(d));

 @Component({
    moduleId: module.id,
    selector: '[multiple-facility-select]',
    templateUrl: 'multiple-facility-select.component.html',
    styleUrls: ['multiple-facility-select.component.scss']
})
export class MultipleFacilitySelectComponent {
    // Variable declaration

    private _data: Array<number> = [];

    private _defaultData: Array<number> = [];

    @Input()
    set defaultData(value) {
        this._data = value;
        this._defaultData = value;
        this.SetDefaultSelectedData();
    }
    get defaultData() {
        return this._defaultData;
    }

    @Input()
    set data(value) {
        this._data = value;
        this.isFormLoaded = false;
    };
    get data() {
        return this._data;
    }

    @Output() dataChange = new EventEmitter<Array<number>>();

    // bootsrap grid css class
    @Input()
    cssClass: string = 'col-md-12';

    @Input()
    appendLabel: string = "";

    @Input()
    prependLabel: string = "";

    @Input()
    level: number = -1;

    @Input()
    locationId: number = 0;

    // List
    structures :any= [];

    // activity state
    loading: boolean = false;
    isFormLoaded: boolean = false;

    constructor(public fb: FormBuilder,
        public _facility: FacilityService,
        private el: ElementRef,
        private translate: TranslateService,
        private dbservice: IndexedDbService,
        ) {

    }

    //wait for the component to render completely
    ngOnInit() {
        var nativeElement: HTMLElement = this.el.nativeElement,
            parentElement: HTMLElement = nativeElement.parentElement;
        // move all children out of the element
        while (nativeElement.firstChild) {
            parentElement.insertBefore(nativeElement.firstChild, nativeElement);
        }
        // remove the empty element(the host)
        parentElement.removeChild(nativeElement);
        this.getStructureAndData();
    }

    // Get structures
    getStructureAndData() {
        this.loading = true;
        let obj =  this.dbservice.getItem('facilitystructure').then(
            dbdata=>{
                console.log(dbdata);
                if(!dbdata){
                    this._facility.getStructureAndData("normal", this.locationId).subscribe(
                        data => {
                            this.loading = false;
                            if (data.MessageType == MessageType.Success) {
                                this.dbservice.setItem('facilitystructure',data.Result.Structure);
                                this.structures = _clone(data.Result.Structure);
                                if (!!this.structures && this.structures.length > 0) {
                                    this.SetFormControls();
                                }
                            }
                        },
                        err => {
                            this.loading = false;
                            Notify.ApiError(err, this.translate);
                        },
                        () => {
                            this.loading = false;
                    });
                }
                else{
                    this.structures = dbdata;
                    if (!!this.structures && this.structures.length > 0) {
                        this.SetFormControls();
                    }
                    this.loading = false;
                }
            }
            ).catch(err=>{
                console.log(err);
                this.loading = false;
            })
        console.log(obj);
        // if(this.dbservice.getItem('facilitystructure'))

    }

    SetDefaultSelectedData() {
        if (!!this.defaultData && this.defaultData.length > 0) {
            for (let index = 0; index < this.structures.length; index++) {
                // Set default selected values

                let selectedData = this.structures[index]["Masters"].filter(x => this.defaultData.includes(x.FacilityMasterId));
                if (!!selectedData && selectedData.length > 0) {
                    let selectedMasterIds = selectedData.map(x => { return x.FacilityMasterId; });
                    this.structures[index]["SelectedMasterIds"] = selectedMasterIds;
                }
            }
        }
    }

    // Set Form Controls
    SetFormControls() {

        for (let index = 0; index < this.structures.length; index++) {

            // Set default masters in child levels
            if (!this.structures[index]["SelectedMasters"]) {
                this.structures[index]["SelectedMasters"] = this.structures[index]["Masters"];
            }
        }

        this.SetDefaultSelectedData();
    }

    // On Select Master data
    OnSelectMaster(items, i) {

        // Set masters for child
        for (let index = i + 1; index < this.structures.length; index++) {

            this.structures[index].SelectedMasterIds = [];

            this.structures[index].SelectedMasters = this.structures[index].Masters.filter(x =>
                (index == i + 1 && !!items && items.length > 0)
                    ? (items.filter(y => y.FacilityMasterId == x.ParentId).length > 0)
                    : (this.structures[index - 1].SelectedMasters.filter(y => y.FacilityMasterId == x.ParentId).length > 0)
            );
        }

        // Return selected values
        if (!!items && items.length > 0) {
            this.dataChange.emit(items.map(x => { return x.FacilityMasterId; }));
        } else {
            if (i > 0) {
                this.dataChange.emit(this.structures[i - 1].SelectedMasterIds);
            } else {
                this.dataChange.emit([]);
            }
        }

    }

    selectAll_Master(i){
        this.structures[i].SelectedCategoryIds = this.structures[i].SelectedMasters.map(x => { return x.FacilityMasterId });
        this.OnSelectMaster(this.structures[i].SelectedMasters, i);
    }
}
