import { Component, OnInit, OnDestroy } from '@angular/core';
declare var $: any;

import { SettingsService } from '@core/settings/settings.service';
import { ThemesService } from '@core/themes/themes.service';
import { TranslatorService } from '@core/translator/translator.service';
import { Application } from '@utility/utility.application';
import { UserService } from '@services/admin/user.service';
import { SharedService } from '@services/shared.service';

 @Component({
    selector: 'app-offsidebar',
    templateUrl: './offsidebar.component.html',
    styleUrls: ['./offsidebar.component.scss']
})
export class OffsidebarComponent implements OnInit, OnDestroy {
    languages = [
        { code: 'en', text: 'English' },
        { code: 'hi', text: 'Hindi (हिंदी)' },
        { code: 'gu', text: 'Gujarati (ગુજરાતી)' }
    ];
    currentTheme: any;
    selectedLanguage: string;
    clickEvent = 'click.offsidebar';
    $doc: any = null;

    constructor(
        public settings: SettingsService,
        public themes: ThemesService,
        public translator: TranslatorService,
        public _user: UserService,
        public _shared: SharedService
    ) {
        this.currentTheme = themes.getDefaultTheme();
        //this.selectedLanguage = this.getLangs()[0].code;
        this.selectedLanguage = this.translator.getSelectedLanguages();
    }

    ngOnInit() {
        this.anyClickClose();
        //this.setUserLastTheme();
        this.getLayout();
    }

    // setUserLastTheme() {
    //     var currentTheme = Application.Get('currentTheme');
    //     if(!!currentTheme) {
    //         this.currentTheme = currentTheme;
    //         this.themes.setTheme(this.currentTheme);
    //     }
    // }

    setTheme() {
        //Application.Set('currentTheme',this.currentTheme);
        this.themes.setTheme(this.currentTheme);
        this.saveLayout();
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value) {
        this.translator.useLanguage(value);
        this._shared.resetLanguage();
    }

    anyClickClose() {
        this.$doc = $(document).on(this.clickEvent, (e) => {
            if (!$(e.target).parents('.offsidebar').length) {
                this.settings.layout.offsidebarOpen = false;
            }
        });
    }

    getLayout() {
        this._user.getLayout().subscribe(
            data => {
                this.setUserLayout(data.Result);
            },
            err => {
            },
            () => {
            });
    }

    setUserLayout(data) {
        if(!!data) {
            if(!!data.layout_theme) {
                this.currentTheme = data.layout_theme;
            } else{
                this.currentTheme = "A";
            }
            this.themes.setTheme(this.currentTheme);

            if(data.layout_isFixed != null){
                this.settings.layout.isFixed = data.layout_isFixed;
            } else {
                this.settings.layout.isFixed = false;
            }

            if(data.layout_isBoxed != null){
                this.settings.layout.isBoxed = data.layout_isBoxed;
            } else {
                this.settings.layout.isBoxed = false;
            }

            this.settings.layout.horizontal = !!data.layout_horizontal;
            if(!this.settings.layout.horizontal) {
                if(data.layout_isCollapsed != null){
                    this.settings.layout.isCollapsed = data.layout_isCollapsed;
                } else{
                    this.settings.layout.isCollapsed = false;
                }

                if(data.layout_isCollapsedText != null){
                    this.settings.layout.isCollapsedText = data.layout_isCollapsedText;
                } else {
                    this.settings.layout.isCollapsedText = false;
                }

                if(data.layout_asideHover != null){
                    this.settings.layout.asideHover = data.layout_asideHover;
                } else {
                    this.settings.layout.asideHover = false;
                }

                if(data.layout_asideScrollbar != null){
                    this.settings.layout.asideScrollbar = data.layout_asideScrollbar;
                } else {
                    this.settings.layout.asideScrollbar = true;
                }
            } else {
                this.settings.layout.isCollapsed = false;
                this.settings.layout.isCollapsedText = false;
                this.settings.layout.asideHover = false;
                this.settings.layout.asideScrollbar = true;
            }
        }
    }

    saveLayout() {
        let userLayoutData = {
            layout_isFixed : this.settings.layout.isFixed,
            layout_isCollapsed : this.settings.layout.isCollapsed,
            layout_isBoxed : this.settings.layout.isBoxed,
            layout_isRTL : this.settings.layout.isRTL,
            layout_horizontal : this.settings.layout.horizontal,
            layout_isFloat : this.settings.layout.isFloat,
            layout_asideHover : this.settings.layout.asideHover,
            layout_theme : this.currentTheme,
            layout_asideScrollbar : this.settings.layout.asideScrollbar,
            layout_isCollapsedText : this.settings.layout.isCollapsedText,
            layout_useFullLayout : this.settings.layout.useFullLayout,
            layout_hiddenFooter : this.settings.layout.hiddenFooter,
            layout_offsidebarOpen : this.settings.layout.offsidebarOpen,
            layout_asideToggled : this.settings.layout.asideToggled,
            layout_viewAnimation : this.settings.layout.viewAnimation
        };

        this._user.saveLayout(userLayoutData).subscribe(
        data => {
        },
        err => {
        },
        () => {
        });
    }

    ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.clickEvent);
    }
}
