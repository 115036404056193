import { Component, OnInit, Injector } from '@angular/core';
import { SettingsService } from '@core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Application } from '@utility/utility.application';
import { AppSession } from '@interface/interface.app-session';
import { MessageType } from '@app.enum';
import { AuthService } from '@services/admin/auth.service';
import { Notify } from '@utility/utility.notify';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDbService } from 'src/app/shared/services/indexed-db.service';

@Component({
    selector: 'app-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {

    valForm: FormGroup;
    router: Router;
    session: AppSession;
    isLoading: boolean;

    constructor(
        public settings: SettingsService,
        fb: FormBuilder,
        public injector: Injector,
        private _auth: AuthService,
        private translate: TranslateService,
        private dbservice: IndexedDbService) {

        this.valForm = fb.group({
            'Password': [null, Validators.required]
        });

    }

    ngOnInit() {
        this.router = this.injector.get(Router);
        this.session = Application.GetSession();
        sessionStorage.setItem('idle-' + _$config.tabid, "-1");

        if (!this.session) {
            this.router.navigate(['login']);
        }
    }

    logout() {
        if (!!this.session) {
            this._auth.logout().subscribe(
                data => {
                    if (data.MessageType == MessageType.Success) {
                        sessionStorage.clear();
                        Application.ClearSession();
                        this.dbservice.clearStore();
                        this.router.navigate(['login']);
                    }
                },
                err => {
                },
                () => {
                });
        } else {
            sessionStorage.clear();
            Application.ClearSession();
            this.dbservice.clearStore();
            this.router.navigate(['login']);
        }
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            this.session = Application.GetSession();
            this.isLoading = true;
            this._auth.validateUser(this.session.UserName, value.Password).subscribe(
                data => {
                    this.isLoading = false;
                    if (data.MessageType == MessageType.Success) {
                        sessionStorage.setItem('idle-' + _$config.tabid, "0");
                        Application.Remove("app-locked");
                        var hash = sessionStorage.getItem('unlock-to-' + _$config.tabid);
                        if (!!hash) {
                            var navigation = hash.split('/');
                            sessionStorage.removeItem("unlock-to-" + _$config.tabid);
                            this.router.navigate(navigation);
                        } else {
                            this.router.navigate(['home']);
                        }
                    } else {
                        Notify.Warning(data.Message);
                    }
                },
                err => {
                    this.isLoading = false;
                    Notify.ApiError_Normal(err, this.translate);
                },
                () => {
                    this.isLoading = false;
                });
        }
    }

}
