import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';

// Module Service
@Injectable()
export class PackageService {

  constructor(private http: HttpClient) { }

  // get package
  getPackage() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package');
  }

  // get package cart
  getPackageCart(Ids) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/cart' + '?Ids=' + Ids);
  }

  // get client subscription
  getClientSubscription(packageId) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/' + packageId);
  }

  // get package feature
  getPackageFeature(Code:any,ModuleId:any) {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/' + ModuleId + '/feature/' + '?code=' + Code);
  }

  // get package subscription
  getClientPackage() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/client');
  }

  // get client subscription detail
  getClientSubscriptionDetail() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/detail');
  }

  // get client subscription details
  getClientSubscriptionDetails() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription-details');
  }

  // get client subscription expire-date
  getClientSubscriptionExpireDate() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/expire-date');
  }

  // get Current Subscribed Users
  getCurrentSubscribedUsers() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/current-users');
  }

  // get Scheduled Subscription Updates
  getScheduledSubscriptionUpdates() {
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'package/subscription/scheduled-update');
  }

  // Add Subscription
  addSubscription(subscription: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'package/subscription', subscription);
  }

  // Create Razorpay Subscription
  createRazorpayPlanAndSubscription(subscription: any) {
    return this.http.post<iApiResponse>(_$config.api_endpoint + 'razorpay/plan-subscription', subscription);
  }

  // Cancel Razorpay Subscription Update
  cancelSubscriptionUpdated() {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'razorpay/subscription-updated-schedule');
  }

  // Cancel Razorpay Subscription
  cancelSubscription() {
    return this.http.delete<iApiResponse>(_$config.api_endpoint + 'razorpay/subscription');
  }
}