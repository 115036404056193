import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Pagination } from '@interface/interface.pagination';

@Injectable({
  providedIn: 'root'
})
export class IMInvestigationService {
  constructor(private http: HttpClient) { }

  //Get Immediate Action
  getIMImmediateActionMaster(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'immediate-action-master')
  }

  // Get Investigation Deatil
  getInvestiagtionDetail(id: number) {
    return this.http.get<any>(`https://my-json-server.typicode.com/kaf-shekh/invest-1/data`);
    // return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/investigation`);

  }
  //Get Investigation Details
  getInvestigationDetail(id){
    return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/investigation`)
  }
  //

  // Get Fact Gathering detail
  getFactGathering(id) {
    // return this.http.get<any>(`https://my-json-server.typicode.com/kaf-shekh/im-2/data`);
    return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/facts-gathering`)
  }
  // Get Recommenation detail
  getRecommendation(id) {
    // return this.http.get<any>(`https://my-json-server.typicode.com/kaf-shekh/recom/data`);
    return this.http.get<iApiResponse>(_$config.api_endpoint + `incident/${id}/recommendation`)
  }

  // temporary function
  getinvestigation(page: Pagination, filter) {
    var query = this.getEmpParams(filter);
    let uri: string = page.GetUri() + query;
    this.getData();
    // this.getincident();

  }

  getData() {
    this.http.get<iApiResponse>(`http://52.175.73.49:9023/api/v1/incident?pagination.currentPage=1
      &pagination.pageSize=4&filter.fromDate=2021%2F09%2F04&filter.toDate=2021%2F09%2F06`).subscribe(data => { console.log(data) });
  }
  // getincident() {
  //   this.http.get<iApiResponse>(`http://localhost:50164/api/v1/incident?pagination.currentPage=1
  //     &pagination.pageSize=4&filter.fromDate=2021%2F09%2F04&filter.toDate=2021%2F09%2F06`).subscribe(data => { console.log(data) });
  // }

  getEmpParams(filter: any) {
    let query = '';
    const param = [];
    if (!!filter.fromDate) {
      param.push('FromDate=' + moment(filter.fromDate).format('YYYY-MMM-DD 00:00:00'));
    }
    if (!!filter.toDate) {
      param.push('toDate=' + moment(filter.toDate).format('YYYY-MMM-DD 23:59:59'));
    }
    if (param.length > 0) {
      query = '&' + param.join('&');
    }
    return query;
  }

//set investigation detail
  setInvestigationDetail(data,id){
    return this.http.post<iApiResponse>(_$config.api_endpoint + `incident/${id}/investigation`,data)
  }

//set Facts Gathering Detail
  setFactsGatheringDetail(data,id){
    return this.http.post<iApiResponse>(_$config.api_endpoint + `incident/${id}/facts-gathering`,data)
  }
//set Recommendation Detail
setRecommendationDetail(data,id){
  return this.http.put<iApiResponse>(_$config.api_endpoint + `incident/${id}/recommendation`,data)
}

//set Review Detail
setReview(data,id){
  return this.http.put<iApiResponse>(_$config.api_endpoint + `incident/${id}/recommendation/review`,data)
}


  getFindings(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'unsafe-condition')
  }
  getemployee(page?: Pagination, search?:any){
    if(page){
      let uri: string = page.GetUri();
      return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee' + uri + '&Search=' + (!!search ? search :'') )
      }
      else{
      return this.http.get<iApiResponse>(_$config.api_endpoint + 'employee')
      }
  }
  async getEmpDetailsById(id:any){
    return await this.http.get<iApiResponse>(_$config.api_endpoint + 'employee/' + id ).toPromise();
  }
  getOperationalCause(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'operational-cause')
  }
  getCAPA(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'corrective-action-factor')
  }
  getSeverity(){
    return this.http.get<iApiResponse>(_$config.api_endpoint + 'severity')
  }
}


