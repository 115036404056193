import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iApiResponse } from '@interface/interface.api-response';
import { Encryption } from '@utility/utility.encryption';
import { WindowsServiceStatus } from '@app.enum';

// Private Service
@Injectable()
export class PrivateService {

  windowsServiceEndpoint: string = _$config.api_endpoint + "private-api/windows-service/";

  constructor(private http: HttpClient) { }

  // Get authorization header
  private getAuthHeader() {
    var headers: any = {};
    var keyLevel1 = Encryption.Encrypt("jyF6LxmzdfZUaebfRrq6NRNg03v3d3HR");

    headers.Authorization = 'Bearer ' + keyLevel1;

    return {
      headers: new HttpHeaders(headers)
    }
  }

  // Validate User
  validateUser(logingData: any) {
    var data = {
      Password : Encryption.Encrypt(logingData.Password)
    }
    data.Password = Encryption.Encrypt(data.Password);
    return this.http.put<iApiResponse>(this.windowsServiceEndpoint + 'validate-user/', data, this.getAuthHeader());
  }

  // Get Windows Service Status
  getWindowsServiceStatus() {
    return this.http.get<iApiResponse>(this.windowsServiceEndpoint + "status", this.getAuthHeader());
  }

  // Chanage Windows Service Status
  changeWindowsServiceStatus(serviceStatus: WindowsServiceStatus) {
    return this.http.put<iApiResponse>(this.windowsServiceEndpoint + serviceStatus, null, this.getAuthHeader());
  }

  // Get Scheduler Item
  getSchedulerItem() {
    return this.http.get<iApiResponse>(this.windowsServiceEndpoint + "scheduler-item", this.getAuthHeader());
  }

  // Create Scheduler Item
  createSchedulerItem(schedulerItem: any) {
    return this.http.put<iApiResponse>(this.windowsServiceEndpoint + "scheduler-item", schedulerItem, this.getAuthHeader());
  }

  // Update Scheduler Items
  updateSchedulerItem(triggers: Array<any>) {
    return this.http.put<iApiResponse>(this.windowsServiceEndpoint + "scheduler-items", triggers, this.getAuthHeader());
  }
}
