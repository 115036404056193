import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SSPSSurveyPageComponent } from './ssps-survey-page/ssps-survey-page.component';
import { HHPSSurveyPageComponent } from './hhps-survey-page/hhps-survey-page.component';
import { RegisterComponent } from './register/register.component';
import { ClientLogoListComponent } from './register/client-logo-list/client-logo-list.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { Error403Component } from './error403/error403.component';
import { ClientService } from '@services/admin/client.service';
import { InvoiceComponent } from './invoice/invoice.component';
import { PaymentConfigService } from '@services/admin/payment-config.service';
import { TaskSchedulerComponent } from './task-scheduler/task-scheduler.component';
import { CountryService } from '@services/admin/country.service';
import { EnquiryService } from '@services/admin/enquiry.service';
/* Use this routes definition in case you want to make them lazy-loaded */
/*const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];*/

@NgModule({
    imports: [
        SharedModule,
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        SSPSSurveyPageComponent,
        HHPSSurveyPageComponent,
        RegisterComponent,
        ClientLogoListComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
        Error403Component,
        TaskSchedulerComponent,
        InvoiceComponent
    ],
    exports: [
        RouterModule,
        LoginComponent,
        SSPSSurveyPageComponent ,
        RegisterComponent,
        ClientLogoListComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
        Error403Component,
        TaskSchedulerComponent
    ],
    providers: [
        ClientService,
        CountryService,
        PaymentConfigService,
        EnquiryService
    ]
})
export class PagesModule { }
